import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

Sentry.init({
  dsn: "https://73577d15790f40818d8a77124614d188@o850789.ingest.sentry.io/5821160",

  release: `${process.env.npm_package_version}`,
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
