import { Card } from "antd";
import React from "react";

interface Props {
  title: string | JSX.Element;
  titleStyle: React.CSSProperties;
  data: string | number | JSX.Element | JSX.Element[];
  loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const DataColorInfo: React.FC<Props> = ({
  title,
  titleStyle,
  data,
  loading = false,
}) => {
  return (
    <Card title={title} loading={loading} headStyle={titleStyle}>
      {data}
    </Card>
  );
};

export default DataColorInfo;
