import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";

export interface IFlowAnalysis {
  members: number;
  registrations: number;
  androidCounts: number;
  iosCounts: number;
  lobbyDAU: number;
  lobbyWAU: number;
  lobbyMAU: number;
  clubDAU: number;
  clubWAU: number;
  clubMAU: number;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IFlowAnalysis[];
}

export const sortableColumns = [];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
