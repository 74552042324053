import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { BannedGameTimeEnum } from "../../platform-management/player-management/actions/create-banned-status/type";
import { IMember, MemberStatusEnum } from "./type";

const Edit = (props: IEditProps<IMember>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`MemberList.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "number",
                  name: "credit",
                  label: "credit",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "status",
                  label: "status",
                  size: { xs: 12 },
                  treeData: getTreeData(mapEnumToList(MemberStatusEnum), false),
                },
                {
                  type: "multiSelect",
                  name: "bannedGame",
                  label: "bannedGame",
                  size: { xs: 6 },
                  treeData: getTreeData(["Game1", "Game2"]),
                },
                {
                  type: "singleSelect",
                  name: "expiredTime",
                  label: "expiredTime",
                  size: { xs: 6 },
                  treeData: getTreeData(
                    mapEnumToList(BannedGameTimeEnum),
                    false
                  ),
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Edit;
