import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export interface IJoinRequest {
  UID: string;
  createTime: string;
  message: string;
}

export const sortableColumns = ["UID", "createTime", "message"];

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
}

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
});
