/* eslint-disable @typescript-eslint/naming-convention */
import { Method as M } from "axios";

const Method = {
  POST: "POST" as M,
  GET: "GET" as M,
  PUT: "PUT" as M,
  PATCH: "PATCH" as M,
  DELETE: "DELETE" as M,
};
export const api = {
  test: { url: (id: string) => `/${id}`, method: Method.GET },

  //login
  login: { url: () => "/login", method: Method.POST },
  logout: { url: () => "/logout", method: Method.POST },
  changePassword: { url: () => "/password", method: Method.PATCH },

  // 平台管理
  getRole: { url: () => "/role", method: Method.GET },
  createRole: { url: () => "/role", method: Method.POST },
  editRole: { url: () => "/role", method: Method.PATCH },

  getPlayer: { url: () => "/player", method: Method.GET },

  getGame: { url: () => "/game", method: Method.GET },

  getOptions: { url: () => "/options", method: Method.GET },

  getTransactionRounds: {
    url: () => "record/transactionrounds",
    method: Method.GET,
  },
  getTransaction: {
    url: () => `record/transactions/`,
    method: Method.GET,
  },
  getCompetitiveGameReport: {
    url: () => "report/competitive",
    method: Method.GET,
  },
  getCompetitiveGameReportSummary: {
    url: () => "report/competitive/summary",
    method: Method.GET,
  },
  getElectronicGameReport: {
    url: () => "report/electronic",
    method: Method.GET,
  },
  getElectronicGameReportSummary: {
    url: () => "report/electronic/summary",
    method: Method.GET,
  },
  getLobbyPlayerReport: {
    url: () => "report/player",
    method: Method.GET,
  },

  /* Analysis */
  getLevelAnalysis: {
    url: () => "analysis/level",
    method: Method.GET,
  },
  getStoreDetailsAnalysis: {
    url: () => "analysis/storedetails",
    method: Method.GET,
  },

  // CG
  //gameRecord
  CGRameRecord: { url: () => "/api/v1/cg/history", method: Method.GET },
  CGRameRecordDetail: {
    url: (txId: string, roundId: string) =>
      `/api/v1/cg/history/${txId}/${roundId}`,
    method: Method.GET,
  },

  // reconciliation
  CGReconciliationOptions: {
    url: () => "/api/v1/cg/reconciliation-options",
    method: Method.GET,
  },
};
