import {
  IMail,
  IReadStatus,
  MailAccountType,
} from "../../pages/platform-management/mail/type";

export const fakeMail: IMail[] = [
  {
    playerID: "",
    accountType: MailAccountType.all,
    title: "string",
    content: "string",
    diamond: 10,
    gold: 0,
    mailAction: "string",
    status: "未寄出",
    readAccounts: 8,
    unreadAccounts: 10,
    startTime: "2021-10-25 23:59:59",
    endTime: "2021-10-25 23:59:59",
  },
  {
    playerID: "string",
    accountType: MailAccountType.personal,
    title: "string",
    content: "string",
    diamond: 10,
    gold: 0,
    mailAction: "string",
    status: "已寄出",
    readAccounts: 40,
    unreadAccounts: 60,
    startTime: "2021-10-25 23:59:59",
    endTime: "2021-10-25 23:59:59",
  },
];

export const fakeReadStatus: IReadStatus[] = [
  {
    playerID: "player1",
    readStatus: "已讀",
  },
  {
    playerID: "player2",
    readStatus: "未讀",
  },
  {
    playerID: "player3",
    readStatus: "已讀",
  },
  {
    playerID: "player4",
    readStatus: "已讀",
  },
  {
    playerID: "player5",
    readStatus: "已讀",
  },
];
