import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateFormItem } from "../../../../utils/helper";
import { formInitialValues, IFormProps } from "./type";

const EditCreateForm = ({
  tempData,
  dialogType,
  setDialogType,
  onCreate,
  onEdit,
}: IFormProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Event.${name}`);
  };

  return (
    <Formik
      initialValues={tempData}
      onSubmit={(values, { setSubmitting }) => {
        console.log("edit create form on submit", values);
        if (dialogType == "create") {
          onCreate(values);
        } else if (dialogType === "edit") {
          onEdit(values);
        }

        setDialogType(null);
        setSubmitting(false);
      }}
      // TODO form validate
    >
      {(form) => (
        <Form style={{ width: "100%" }}>
          {generateFormItem(
            form,
            [
              {
                type: "text",
                name: "title",
                label: "title",
                size: { xs: 12 },
              },
              {
                type: "text",
                name: "content",
                label: "content",
                size: { xs: 12 },
              },
              {
                type: "text",
                name: "reward",
                label: "reward",
                size: { xs: 12 },
              },
              {
                type: "singleTime",
                name: "startTime",
                label: "startTime",
                size: { xs: 12 },
              },
              {
                type: "singleTime",
                name: "endTime",
                label: "endTime",
                size: { xs: 12 },
              },
            ],
            formInitialValues,
            T
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EditCreateForm;
