import { Grid, useMediaQuery } from "@material-ui/core";
import { TreeSelect } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, Line } from "recharts";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import {
  chartColors,
  getTreeData,
  mapEnumToList,
} from "../../../../../utils/helper";
import {
  fakeGameBarChartData,
  fakeMonthlyRevenue,
} from "../../../../fake-data/business-statistics";
import {
  ClubCompetitiveGameDataEnum,
  ClubElectronicGameDataEnum,
  DateRangeEnum,
} from "../../business-report/type";
import { CustomBarChart, CustomLineChart } from "../../common-component";
import DateRangeSelector from "../../common-component/date-range-selector";

interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const GameData: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [selectedGameDataDateRange, setSelectedGameDataDateRange] = useState(
    DateRangeEnum.今日
  );
  const [gameDataTime, setGameDataTime] = useState<any>([
    moment().startOf("days"),
    moment().endOf("days"),
  ]);
  const [gameDataLoading, setGameDataLoading] = useState(false);
  const [selectedClubElectronicGameData, setSelectedClubElectronicGameData] =
    useState<ClubElectronicGameDataEnum[]>([ClubElectronicGameDataEnum.rtp]);
  const [selectedClubCompetitiveGameData, setSelectedClubCompetitiveGameData] =
    useState<ClubCompetitiveGameDataEnum[]>([
      ClubCompetitiveGameDataEnum.開房量,
    ]);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  const clubElectronicDataBar = useMemo(() => {
    return selectedClubElectronicGameData.map((data, index) => {
      return (
        <Bar
          name={data}
          barSize={20}
          key={`clubElectronic${data}${index}`}
          dataKey={data}
          fill={
            chartColors[
              mapEnumToList(ClubElectronicGameDataEnum).findIndex(
                (d) => d === data
              )
            ]
          }
        />
      );
    });
  }, [selectedClubElectronicGameData]);

  const clubCompetitiveDataBar = useMemo(() => {
    return selectedClubCompetitiveGameData.map((data, index) => {
      return (
        <Bar
          name={data}
          barSize={20}
          key={`clubCompetitive${data}${index}`}
          dataKey={data}
          fill={
            chartColors[
              mapEnumToList(ClubCompetitiveGameDataEnum).findIndex(
                (d) => d === data
              )
            ]
          }
        />
      );
    });
  }, [selectedClubCompetitiveGameData]);

  // fake loading
  useEffect(() => {
    setGameDataLoading(true);
    setTimeout(() => {
      setGameDataLoading(false);
    }, 3000);
  }, [gameDataTime]);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <div className={`${classes.card} ${classes.greenOpacity}`}>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center">
          <div
            className={`${classes.whiteTitle} ${
              isSingleMode && classes.singleModeFullWidth
            }`}
          >{`${T("gameData")}`}</div>
          <DateRangeSelector
            selectedDateRange={selectedGameDataDateRange}
            setSelectedDateRange={setSelectedGameDataDateRange}
            dateRange={gameDataTime}
            setDateRange={setGameDataTime}
            datePickerOnChange={(e: any) => {
              setSelectedGameDataDateRange(DateRangeEnum.無);
              setGameDataTime([e[0], moment(e[1]).endOf("days")]);
            }}
          />
        </Grid>
        {
          // 月營收
          selectedGameDataDateRange === DateRangeEnum.當月 && (
            <Grid item xs={12}>
              <CustomLineChart
                title={`${moment().format("M")}${T("monthlyRevenue")}`}
                loading={gameDataLoading}
                chartData={fakeMonthlyRevenue}
                chartLine={[
                  <Line
                    key={"clubRevenue"}
                    name={"clubRevenue"}
                    type="monotone"
                    dataKey="clubRevenue"
                    stroke={chartColors[2]}
                  />,
                ]}
              />
            </Grid>
          )
        }
        {
          // 俱樂部電子遊戲數據
        }
        <Grid item xs={isSingleMode ? 12 : 6}>
          <CustomBarChart
            title={`${T("clubElectronicData")} ${gameDataTime[0].format(
              "YYYY/MM/DD HH:mm:ss（Z）"
            )} ~ ${gameDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
            subTitle={
              <TreeSelect
                style={{ minWidth: 200 }}
                value={selectedClubElectronicGameData}
                multiple={true}
                treeData={getTreeData(
                  mapEnumToList(ClubElectronicGameDataEnum),
                  false,
                  T
                )}
                onChange={(v) => {
                  setSelectedClubElectronicGameData(v);
                }}
                className={`${isSingleMode && classes.singleModeTreeSelect}`}
              />
            }
            loading={gameDataLoading}
            chartData={fakeGameBarChartData}
            chartBar={clubElectronicDataBar}
          />
        </Grid>
        {
          // 俱樂部競技遊戲數據
        }
        <Grid item xs={isSingleMode ? 12 : 6}>
          <CustomBarChart
            title={`${T("clubCompetitiveData")} ${gameDataTime[0].format(
              "YYYY/MM/DD HH:mm:ss（Z）"
            )} ~ ${gameDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
            subTitle={
              <TreeSelect
                style={{ minWidth: 200 }}
                value={selectedClubCompetitiveGameData}
                multiple={true}
                treeData={getTreeData(
                  mapEnumToList(ClubCompetitiveGameDataEnum),
                  false,
                  T
                )}
                onChange={(v) => {
                  setSelectedClubCompetitiveGameData(v);
                }}
                className={`${isSingleMode && classes.singleModeTreeSelect}`}
              />
            }
            loading={gameDataLoading}
            chartData={fakeGameBarChartData}
            chartBar={clubCompetitiveDataBar}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GameData;
