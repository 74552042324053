import {
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { dataTypeCheck } from "../../../../utils/helper";
import {
  fakeOtherRiskConfigDescription,
  fakeRiskConfig,
  fakeRiskConfigDescription,
} from "../../../fake-data/risk-control/risk-config";
import { IAction, TemplateTable } from "../../../layout";
import Create from "./create";
import Edit from "./edit";
import EditOtherConfig from "./edit-other-config";
import {
  defaultFilter,
  IFilter,
  IGameRiskConfig,
  IRiskConfigDescription,
  IRiskOtherConfigDescription,
  sortableColumns,
} from "./type";

const useStyles = makeStyles(() => ({
  actionButton: {
    padding: 5,
  },
}));

const RiskConfig = () => {
  const auth = useContext(IsAuthorization);
  const classes = useStyles();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [nextPageAvailable] = useState(false);
  const [detail, setDetail] = useState<IGameRiskConfig>();
  const [otherConfigDetail, setOtherConfigDetail] =
    useState<IRiskOtherConfigDescription>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditOtherConfigOpen, setIsEditOtherConfigOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const [loading] = useState<boolean>(false);
  const T = (name: string) => {
    return t(`RiskConfig.${name}`);
  };

  const action: IAction<IGameRiskConfig> = {
    create: true,
    del: true,
    edit: true,
    onEdit: (data: IGameRiskConfig) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      // setIsCreateOpen(true);
    },
    conditions: {
      edit: (row: IGameRiskConfig) => {
        return !(row.gameID === "Default");
      },
      del: (row: IGameRiskConfig) => {
        return !(row.gameID === "Default");
      },
    },
  };

  const descriptionHeader: ColumnsType<IRiskConfigDescription> = [
    {
      dataIndex: "config",
      align: "left",
      title: T("config"),
    },
    {
      dataIndex: "default",
      align: "right",
      title: T("default"),
    },
    {
      dataIndex: "description",
      align: "left",
      title: T("description"),
    },
  ];

  const otherConfigHeader: ColumnsType<IRiskOtherConfigDescription> = [
    {
      dataIndex: "config",
      align: "left",
      title: T("config"),
    },
    {
      dataIndex: "current",
      align: "right",
      title: T("current"),
    },
    {
      dataIndex: "default",
      align: "right",
      title: T("default"),
    },
    {
      dataIndex: "description",
      align: "left",
      title: T("description"),
    },
    {
      title: t("common.action"),
      align: "left",
      render: (_, data: IRiskOtherConfigDescription) => {
        return (
          <Tooltip title={T("edit")}>
            <IconButton
              aria-label="edit"
              className={classes.actionButton}
              onClick={(e) => {
                e.stopPropagation();
                setOtherConfigDetail(data);
                setIsEditOtherConfigOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const header: IDetailKey<IGameRiskConfig>[] = [
    { key: "gameID", name: T("gameID") },
    { key: "type", name: T("type") },
    { key: "repeatFreeSlotCount", name: T("repeatFreeSlotCount"), isInt: true },
    { key: "bigWin", name: T("bigWin") },
    { key: "bigWinRate", name: T("bigWinRate") },
    { key: "jackpot", name: T("jackpot") },
    {
      key: "rtp",
      name: T("rtp"),
      custom: (_, record) => `${dataTypeCheck(record.rtp)}%`,
    },
    { key: "netProfit", name: T("netProfit") },
  ];

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return auth.login ? (
    <>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="xs"
      />
      <Create open={isCreateOpen} setOpen={setIsCreateOpen} widthSize="xs" />
      <Table
        style={{
          margin: isSingleMode ? 0 : 30,
          boxShadow: "0px 0px 5px #a6a6a6",
        }}
        dataSource={fakeRiskConfigDescription}
        // loading={loading}
        key="detail-table"
        pagination={false}
        columns={descriptionHeader}
        size="small"
        rowKey={(record) => record.id}
      />
      <div
        style={{ fontSize: 22, marginTop: 30, borderBottom: "solid 1px black" }}
      >
        大廳電子遊戲風控數值
      </div>
      <TemplateTable<IGameRiskConfig, any>
        data={fakeRiskConfig}
        header={header}
        filter={filter}
        action={action}
        setFilter={setFilter}
        reloading={loading}
        toolbar={true}
        sort={sortableColumns}
        isNextPageHaveData={nextPageAvailable}
        pagination={false}
        // visibleFieldSelect={false}
      />
      <div
        style={{ fontSize: 22, marginTop: 30, borderBottom: "solid 1px black" }}
      >
        大廳競技遊戲風控數值
      </div>
      <TemplateTable<IGameRiskConfig, any>
        data={fakeRiskConfig}
        header={header}
        action={action}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        toolbar={true}
        sort={sortableColumns}
        isNextPageHaveData={nextPageAvailable}
        pagination={false}
        // visibleFieldSelect={false}
      />
      <div
        style={{ fontSize: 22, marginTop: 30, borderBottom: "solid 1px black" }}
      >
        其他風控數值
      </div>
      <EditOtherConfig
        open={isEditOtherConfigOpen}
        setOpen={setIsEditOtherConfigOpen}
        data={otherConfigDetail}
        widthSize="xs"
      />
      <Table
        style={{
          margin: isSingleMode ? 0 : 30,
          boxShadow: "0px 0px 5px #a6a6a6",
        }}
        dataSource={fakeOtherRiskConfigDescription}
        // loading={loading}
        key="other-config-detail-table"
        pagination={false}
        columns={otherConfigHeader}
        size="small"
        rowKey={(record) => record.id}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default RiskConfig;
