import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export enum WarningMessageStatusEnum {
  已解決 = "finished",
  未解決 = "unfinished",
}

export interface IWarningMessage {
  id: string;
  riskConfig: string;
  message: string;
  time: string;
  status: WarningMessageStatusEnum;
  mark: string;
}

export interface IFilter extends IBasicFilter {
  riskConfig: string[];
  status: WarningMessageStatusEnum[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IWarningMessage[];
}

export const sortableColumns = ["time"];

export const defaultFilter: () => IFilter = () => ({
  status: [],
  riskConfig: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
