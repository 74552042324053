import React from "react";
import { Form, Formik } from "formik";
import { defaultFilter, ISearchProps } from "./type";
import { SearchAccordion } from "../../../common";
import { generateSearchFormItem, getTreeData } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const Search = ({ filter, data, setFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        enableReinitialize={true}
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setFilter({ ...values, pages: 0 });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "common.Time",
                },
                {
                  type: "multiSelect",
                  name: "riskConfig",
                  label: "WarningMessage.riskConfig",
                  treeData: getTreeData([
                    "重覆中免費遊戲次數提醒",
                    "開大獎金額提醒",
                    "中比倍次數提醒",
                    "開獎金額提醒",
                    "RTP數值提醒",
                    "總營利提醒",
                  ]),
                },
                {
                  type: "text",
                  name: "message",
                  label: "WarningMessage.message",
                },
                {
                  type: "multiSelect",
                  name: "status",
                  label: "WarningMessage.status",
                  treeData: getTreeData(["已解決", "未解決"]),
                },
              ],
              defaultFilter(),
              t,
              data,
              "Warning Message"
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
