import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { fakeUser } from "../../../fake-data/backstage-management/user";
import { IAction, TemplateTable } from "../../../layout";
import Create from "./create";
import Edit from "./edit";
import Search from "./search";
import { defaultFilter, IFilter, IUser, sortableColumns } from "./type";

const UserManagement = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IUser[]>(fakeUser);
  const [downloadData] = useState<any>(fakeUser);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [detail, setDetail] = useState<IUser>();
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`UserManagement.${name}`);
  };

  const header: IDetailKey<IUser>[] = [
    { key: "username", name: T("username") },
    { key: "nickname", name: T("nickname") },
    { key: "roleID", name: T("roleID") },
    { key: "clubID", name: T("clubID") },
    { key: "status", name: T("status") },
    { key: "createdAt", name: T("createdAt") },
    { key: "updatedAt", name: T("updatedAt") },
  ];

  const action: IAction<IUser> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IUser) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
  };

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="sm"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="sm"
      ></Create>
      <TemplateTable<IUser, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default UserManagement;
