import { Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsAuthorization } from "../../../../../../../app";
import { api } from "../../../../../../../environment";
import { instance } from "../../../../../../../environment/axios";
import {
  IDetailKey,
  IPagedResponse,
} from "../../../../../../../utils/common-type";
import {
  dataTypeCheck,
  unAuthorizationHandle,
} from "../../../../../../../utils/helper";
import { TemplateTable } from "../../../../../../layout";
import {
  ILobbyElectronicGameReport,
  IElectronicGameReportSummary,
} from "../../../../game-report/lobby-electronic-game-report/type";
import {} from "../../type";
import {
  buildFilter,
  IFilter,
  defaultFilter,
} from "../competitive-game-report/type";
import { initialSummary, sortableColumns } from "./type";

interface Props {
  fakeID?: number;
  time: moment.Moment[];
  zone: string;
}

const ElectronicReport = ({ fakeID, time, zone }: Props) => {
  const auth = useContext(IsAuthorization);
  const [filter, setFilter] = useState<IFilter>(defaultFilter);
  const [data, setData] = useState<ILobbyElectronicGameReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);
  const [summary, setSummary] = useState(initialSummary);
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`GameReport.${name}`) : "";
  };

  const electronicHeader: IDetailKey<
    ILobbyElectronicGameReport & { totalRevenue: number }
  >[] = [
    {
      key: "gameID",
      name: T("gameID"),
    },
    {
      key: "totalBet",
      name: T("totalBet"),
    },
    {
      key: "totalWin",
      name: T("totalWin"),
    },
    {
      key: "totalRevenue",
      name: T("totalRevenue"),
      custom: (_, record) =>
        `${dataTypeCheck(record.totalBet - record.totalWin)}`,
    },
    {
      key: "rtp",
      name: T("rtp"),
      custom: (_, record) => `${dataTypeCheck(record.rtp)}%`,
    },
    {
      key: "betCount",
      name: T("betCount"),
      isInt: true,
    },
  ];

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ILobbyElectronicGameReport[]>>({
        method: api.getElectronicGameReport.method,
        url: api.getElectronicGameReport.url(),
        params: buildFilter({ ...filter, fakeID, time, zone }),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => ({
            ...d,
            totalBet: d.totalBet / 10000,
            totalWin: d.totalWin / 10000,
          }))
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth, filter, fakeID, time, zone]);

  const searchSummary = useCallback(() => {
    setSummary(initialSummary);
    instance
      .request<IElectronicGameReportSummary>({
        method: api.getElectronicGameReportSummary.method,
        url: api.getElectronicGameReportSummary.url(),
        params: buildFilter({ fakeID, time, zone }),
      })
      .then((res) => {
        const data: IElectronicGameReportSummary = {
          ...res.data,
          totalRevenue: res.data.totalRevenue / 10000,
          avgRevenue: res.data.avgRevenue / 10000,
        };
        setSummary((s) =>
          s.map((s) => ({
            ...s,
            value:
              s.key === "totalRtp"
                ? `${dataTypeCheck(data[s.key])}%`
                : data[s.key],
          }))
        );
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {});
  }, [fakeID, time, zone, auth]);

  /* 只需搜尋第一次 */
  useEffect(() => {
    searchSummary();
  }, [searchSummary]);

  /* 換頁查詢 */
  useEffect(() => {
    onSearch();
  }, [onSearch]);

  return (
    <>
      {summary && (
        <Grid item container justify="space-around" alignItems="center" xs={12}>
          {summary.map((s) => {
            return (
              <Grid item style={{ color: "#3f51b5" }} key={Math.random()}>
                {`${T(s.key)}: ${s.isInt ? s.value : dataTypeCheck(s.value)}`}
              </Grid>
            );
          })}
        </Grid>
      )}
      <TemplateTable<ILobbyElectronicGameReport, any>
        data={data}
        header={electronicHeader}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        topPagination={false}
        toolbar={false}
        visibleFieldSelect={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};
export default ElectronicReport;
