import React from "react";
import { IToggleButtonValues } from "../../utils/common-type";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const ToggleButtons = ({
  exclusive = true,
  onChange,
  buttons = ["today", "yesterday", "thisWeek", "lastWeek", "crtMonth"],
  ariaLabel = "text alignment",
}: IToggleButtonValues) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };
  return (
    <ToggleButtonGroup
      size="small"
      exclusive={exclusive}
      onChange={onChange}
      aria-label={ariaLabel}
      style={{ overflowX: "auto" }}
    >
      {buttons.map((button) => {
        return (
          <ToggleButton
            style={{
              color: "#3f51b5",
              height: 35,
              alignSelf: "center",
            }}
            value={button}
            aria-label="centered"
            key={`toggleButton${button}`}
          >
            {T(button)}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
