import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Button } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey, IEditProps } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { ConfirmCard } from "../../../common";
import { fakeOfflinePlayer } from "../../../fake-data/club-service/offline-player";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  IOfflinePlayer,
  sortableColumns,
} from "./type";

const OfflinePlayers = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IOfflinePlayer[]>(fakeOfflinePlayer);
  const [downloadData] = useState<any>(fakeOfflinePlayer);
  const [loading] = useState<boolean>(false);
  const [detail, setDetail] = useState<IOfflinePlayer>();
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [nextPageAvailable] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [actionWidth, setActionWidth] =
    useState<IEditProps<any>["widthSize"]>("lg");
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`OfflinePlayers.${name}`);
  };

  const header: IDetailKey<IOfflinePlayer>[] = [
    {
      key: "playerID",
      name: T("playerID"),
      custom: (playerID: string, source: IOfflinePlayer) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => {
              setDetail(source);
              setIsActionOpen(true);
              setActionName("memberInfo");
              setActionWidth("xs");
            }}
            style={{ margin: 0, padding: 0, border: 0 }}
          >
            <span style={{ textDecoration: "underline" }}>{playerID}</span>
          </Button>
        );
      },
    },
    { key: "nickname", name: T("nickname") },
    { key: "remainPoints", name: T("remainPoints") },
    { key: "offlineTime", name: T("offlineTime") },
  ];

  const action: IAction<IOfflinePlayer> = {
    create: false,
    del: false,
    edit: true,
    custom: [
      {
        name: "成員積分",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (member: IOfflinePlayer) => {
          setDetail(member);
          setIsActionOpen(true);
          setActionName("pointsDetail");
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
      {
        actionIcon: <HighlightOffIcon />,
        name: "移除俱樂部",
        onEvent: (data: IOfflinePlayer) => {
          setIsRemoveOpen(true);
          setDetail(data);
          setActionWidth("lg");
        },
        position: "IN_ROW",
      },
    ],
  };

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <ConfirmCard
        open={isRemoveOpen}
        setOpen={setIsRemoveOpen}
        content="即將將此玩家踢除俱樂部"
        data={detail}
      />
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize={actionWidth || "lg"}
        action={actionName}
      />
      <TemplateTable<IOfflinePlayer, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default OfflinePlayers;
