import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../utils/helper";

export enum GameStatusEnum {
  enable = "啟用",
  disable = "禁用",
}

export enum GameTagEnum {
  new = "New",
  hot = "Hot",
  recommend = "推薦",
}

export interface IGame {
  gameID: string;
  name: { [key: string]: string };
  introduction: Object;
  rule: string;
  icon: string;
  url: string;
  tags: GameTagEnum[];
  type: number;
  serverType: number;
  defaultSetting: string;
  extra: Object;

  // TODO: 缺少
  lobbyStatus: GameStatusEnum;
  clubStatus: GameStatusEnum;
  priority: number;
}

export const formInitialValues: Partial<IGame> = {
  gameID: "Game A",
  name: {
    zh_cht: "Name A",
  },
  introduction: "Introduction A",
  rule: "Rule A",
  icon: "Icon A",
  url: "Url A",
  tags: [GameTagEnum.hot, GameTagEnum.new],
};

export const sortableColumns = [];

export interface IFilter extends IBasicFilter {
  gameID: string;
  name: string;
  tag: string[];
  // lobbyStatus: GameStatusEnum[];
  // clubStatus: GameStatusEnum[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IGame[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    gameID: filter.gameID,
    name: filter.name,
    tag: filter.tag.join(),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  gameID: "",
  name: "",
  tag: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
