import moment from "moment";
import { IBasicFilter } from "../../../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../../../utils/helper";

export const Games = ["Game 1", "Game 2", "Game 3"];

export const Rules = ["Rule 1", "Rule 2", "Rule 3"];

export interface IActiveGames {
  gameID: string;
  ruleID: string;
  rake: number;
}

export const formInitialValues = {
  gameID: "Game 1",
  ruleID: "Rule 1",
  rake: 0,
};

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IActiveGames[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
