import {
  IPlayerBannedStatusRecord,
  IPlayerBannedTable,
  PlayerBannedActionEnum,
  PlayerBannedStatusEnum,
} from "../../pages/platform-management/player-management/type";

// export const fakePlayer: IPlayer[] = [
//   {
//     id: "string",
//     // password: "string",
//     nickname: "string",
//     level: 1,
//     clubID: ["Club1"],
//     status: "正常",
//     tag: [],
//     gem: 0,
//     coin: 0,
//     gender: 0,
//     introduction: "string",
//     createdAt: "string",
//     lastLoginAt: "string",
//   },
// ];

export const fakeBannedStatus: IPlayerBannedTable[] = [
  {
    banned: PlayerBannedStatusEnum.app,
    time: "2021-12-10 00:00:00",
    expiredTime: "2022-12-10 00:00:00",
  },
  {
    banned: PlayerBannedStatusEnum.chat,
    time: "2021-12-10 00:00:00",
    expiredTime: "2022-12-10 00:00:00",
  },
];

export const fakeBannedStatusRecord: IPlayerBannedStatusRecord[] = [
  {
    banned: PlayerBannedStatusEnum.app,
    action: PlayerBannedActionEnum.banned,
    time: "2021-12-10 00:00:00",
  },
  {
    banned: PlayerBannedStatusEnum.chat,
    action: PlayerBannedActionEnum.banned,
    time: "2021-12-10 00:00:00",
  },
  {
    banned: PlayerBannedStatusEnum.app,
    action: PlayerBannedActionEnum.unblock,
    time: "2021-12-10 00:00:00",
  },
  {
    banned: PlayerBannedStatusEnum.chat,
    action: PlayerBannedActionEnum.unblock,
    time: "2021-12-10 00:00:00",
  },
];
