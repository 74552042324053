import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { fakeShopReport } from "../../../fake-data/report-management/shop-report";
import { IAction, TemplateTable } from "../../../layout";
import Detail from "./detail";
import Search from "./search";
import { defaultFilter, IFilter, IShopReport, sortableColumns } from "./type";

const ShopReport = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IShopReport[]>(fakeShopReport);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [detail, setDetail] = useState<IShopReport>();
  const [detailTitle, setDetailTitle] = useState<string>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`ShopReport.${name}`);

  const header: IDetailKey<IShopReport>[] = [
    { key: "item", name: T("item") },
    { key: "source", name: T("source") },
    { key: "salesCount", name: T("salesCount") },
    { key: "salesAmount", name: T("salesAmount") },
    { key: "totalSalesAmount", name: T("totalSalesAmount") },
  ];
  const action: IAction<IShopReport> = {
    create: false,
    del: false,
    edit: false,
    read: true,
    onRead: (data: IShopReport) => {
      setDetail(data);
      setDetailTitle(
        `${data.item}購買明細${filter.time[0]} ~ ${filter.time[1]}`
      );
      setIsDetailOpen(true);
    },
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Detail
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        data={detail}
        widthSize="md"
        title={detailTitle}
      ></Detail>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<IShopReport, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default ShopReport;
