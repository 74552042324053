import {
  ISuggestion,
  SuggestionStatusEnum,
} from "../../pages/customer-service/suggestion/type";

export const fakeSuggestion: ISuggestion[] = [
  {
    suggestionID: "string",
    type: "string",
    playerID: "string",
    status: SuggestionStatusEnum["in progress"],
    priority: 1,
    createdAt: "2021-11-29 16:45:52",
    assignedUser: "user1",
    content: "string",
    replay: "",
    mark: "string",
  },
  {
    suggestionID: "string",
    type: "string",
    playerID: "string",
    status: SuggestionStatusEnum["in progress"],
    priority: 2,
    createdAt: "2021-11-29 16:45:52",
    assignedUser: "user1",
    content: "string",
    replay: "",
    mark: "string",
  },
  {
    suggestionID: "string",
    type: "string",
    playerID: "string",
    status: SuggestionStatusEnum["in progress"],
    priority: 3,
    createdAt: "2021-11-29 16:45:52",
    assignedUser: "user1",
    content: "string",
    replay: "",
    mark: "string",
  },
  {
    suggestionID: "string",
    type: "string",
    playerID: "string",
    status: SuggestionStatusEnum["in progress"],
    priority: 4,
    createdAt: "2021-11-29 16:45:52",
    assignedUser: "user1",
    content: "string",
    replay: "",
    mark: "string",
  },
  {
    suggestionID: "string",
    type: "string",
    playerID: "string",
    status: SuggestionStatusEnum["in progress"],
    priority: 5,
    createdAt: "2021-11-29 16:45:52",
    assignedUser: "user1",
    content: "string",
    replay: "",
    mark: "string",
  },
];
