import {
  IConsumeRecord,
  IProductItem,
} from "../../pages/customer-service/consume-record/type";

export const fakeShopping: IConsumeRecord[] = [
  {
    time: "2021-10-25 23:59:59",
    id: "DJ123541698",
    playerID: "string",
    product: "新春鑽石禮包",
    type: "Android",
    cost: 6000,
    unit: "NTD",
  },
  {
    time: "2021-10-25 23:59:59",
    id: "GE465416541",
    playerID: "string",
    product: "商品2",
    type: "IOS",
    cost: 500,
    unit: "NTD",
  },
];

export const fakeProductItem: IProductItem[] = [
  {
    itemId: "GE4984641",
    itemName: "鑽石",
    amount: 8888,
  },
  {
    itemId: "WW1315635",
    itemName: "豪贈點",
    amount: 100,
  },
];
