import React from "react";
import { Form, Formik } from "formik";
import { defaultFilter, ISearchProps } from "./type";
import { SearchAccordion } from "../../../../common";
import {
  generateSearchFormItem,
  getTreeData,
} from "../../../../../utils/helper";
import { useTranslation } from "react-i18next";

const Search = ({ filter, setFilter, fixedFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        enableReinitialize={true}
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setFilter({ ...values, pages: 0 });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "common.Time",
                },
                {
                  type: "text",
                  name: "transactionID",
                  label: "FriendsRoomGameRecord.transactionID",
                  disable: Boolean(
                    form.values.fakeID !== "" || form.values.gameIDs?.length
                  ),
                },
                {
                  type: "multiSelect",
                  name: "gameID",
                  label: "FriendsRoomGameRecord.gameID",
                  treeData: getTreeData(["GAME1", "GAME2"]),
                  disable: form.values.transactionID !== "",
                },
                {
                  type: "text",
                  name: "playerID",
                  label: "FriendsRoomGameRecord.playerID",
                  disable: form.values.transactionID !== "",
                },
              ].filter((v) => {
                // 過濾掉固定的搜尋條件
                return !(
                  fixedFilter &&
                  Object.keys(fixedFilter).find((key) => key === v.name)
                );
              }) as any,
              defaultFilter(),
              t
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
