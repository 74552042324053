import { IActiveGames } from "../../pages/business-statistics/club-business-report/platform-info/club-detail/active-games/type";

export const fakeGameActive: IActiveGames[] = [
  {
    gameID: "Game 1",
    ruleID: "Rule 1",
    rake: 0,
  },
  {
    gameID: "Game 3",
    ruleID: "Rule 1",
    rake: 0.4,
  },
];
