import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { fakeSystemConfigLog } from "../../../fake-data/backstage-management/system-log";
import { IAction, TemplateTable } from "../../../layout";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  ISystemConfigLog,
  sortableColumns,
} from "./type";

const SystemConfigLog = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<ISystemConfigLog[]>(fakeSystemConfigLog);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`SystemConfigLog.${name}`);

  const header: IDetailKey<ISystemConfigLog>[] = [
    { key: "permission", name: T("permission") },
    { key: "time", name: T("time") },
    { key: "ip", name: T("ip") },
    { key: "username", name: T("username") },
  ];
  const action: IAction<ISystemConfigLog> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<ISystemConfigLog, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default SystemConfigLog;
