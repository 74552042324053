import moment from "moment";
import { IBasicFilter } from "../../../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../../../utils/helper";

export enum MarqueeModeEnum {
  once = "單次",
  repeat = "循環",
}

export interface IMarquee {
  content: string;
  frequency: MarqueeModeEnum;
  frequencyDetail: string;
  createTime: string;
}

export const formInitialValues = {
  content: "",
  frequency: MarqueeModeEnum.once,
  frequencyDetail: moment(),
};

export const sortableColumns = [
  "content",
  "frequency",
  "frequencyDetail",
  "createTime",
];

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IMarquee[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
