import React from "react";
import { useTranslation } from "react-i18next";
import {
  FriendsRoomGameRecord,
  LobbyCompetitiveGameRecord,
  LobbyElectronicGameRecord,
} from "../../..";
import { CustomDialog } from "../../../../common";
import BannedStatusRecord from "./banned-status-record";
import AccessRecord from "./club-access-record";
import CreateBannedStatus from "./create-banned-status";
import StoredValue from "./stored-value";
import StoredValueRecord from "./stored-value-record";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`PlayerManagement.${props.action}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "LobbyElectronicGameRecord":
        return (
          <LobbyElectronicGameRecord fixedFilter={{ fakeID: props.data?.id }} />
        );
      case "LobbyCompetitiveGameRecord":
        return (
          <LobbyCompetitiveGameRecord
            fixedFilter={{ fakeID: props.data?.id }}
          />
        );
      case "FriendsRoomGameRecord":
        return (
          <FriendsRoomGameRecord fixedFilter={{ fakeID: props.data?.id }} />
        );
      case "AccessRecord":
        return <AccessRecord />;
      case "StoredValue":
        return <StoredValue />;
      case "StoredValueRecord":
        return <StoredValueRecord />;
      case "BannedStatusRecord":
        return <BannedStatusRecord />;
      case "CreateBannedStatus":
        return <CreateBannedStatus />;
      default:
        return "Action Not Found";
    }
  };

  return (
    <CustomDialog
      title={`${T(props.action)} ${
        props.data?.id ? `ID: ${props.data?.id}` : ""
      }`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
