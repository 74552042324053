import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import { IDetailKey } from "../../../../../utils/common-type";
import { fakeFlowAnalysis } from "../../../../fake-data/report-management/flow-analysis";
import { IAction, TemplateTable } from "../../../../layout";
import Search from "./search";
import { defaultFilter, IFilter, IFlowAnalysis, sortableColumns } from "./type";

const FlowAnalysis = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IFlowAnalysis[]>(fakeFlowAnalysis);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`FlowAnalysis.${name}`);

  const header: IDetailKey<IFlowAnalysis>[] = [
    { key: "members", name: T("members"), isInt: true },
    { key: "registrations", name: T("registrations"), isInt: true },
    { key: "androidCounts", name: T("androidCounts"), isInt: true },
    { key: "iosCounts", name: T("iosCounts"), isInt: true },
    { key: "lobbyDAU", name: T("lobbyDAU") },
    { key: "lobbyWAU", name: T("lobbyWAU") },
    { key: "lobbyMAU", name: T("lobbyMAU") },
    { key: "clubDAU", name: T("clubDAU") },
    { key: "clubWAU", name: T("clubWAU") },
    { key: "clubMAU", name: T("clubMAU") },
  ];
  const action: IAction<IFlowAnalysis> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<IFlowAnalysis, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default FlowAnalysis;
