import { Grid, useMediaQuery } from "@material-ui/core";
import { Card } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import DataInfo from "../../../business-statistics/common-component/data-info";
import WarningMessage from "./warning-message";
interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlatformInfo: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [loading, setLoading] = useState(false);
  const [lobbyElectronicAvgRTP, setLobbyElectronicAvgRTP] = useState(0);
  const [lobbyElectronicRevenue, setLobbyElectronicRevenue] = useState(0);
  const [lobbyCompetitiveRake, setLobbyCompetitiveRake] = useState(0);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  // fake loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setLobbyElectronicAvgRTP(93.75);
      setLobbyElectronicRevenue(5684);
      setLobbyCompetitiveRake(5521);
    }, 3000);
  }, []);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <>
      <Grid container item xs={12} justify="space-between">
        <div
          style={{
            width: isSingleMode ? "100%" : "32%",
            marginBottom: isSingleMode ? 10 : 0,
          }}
        >
          <Card loading={loading} className={classes.orange}>
            <div className={classes.riskInfoValue}>
              <CountUp
                end={lobbyElectronicAvgRTP}
                decimals={2}
                decimal="."
                useEasing={true}
              />
              %
            </div>
            <div className={classes.riskInfoTitle}>
              {T("lobbyElectronicAvgRTP")}
            </div>
          </Card>
        </div>
        <div
          style={{
            width: isSingleMode ? "100%" : "32%",
            marginBottom: isSingleMode ? 10 : 0,
          }}
        >
          <Card loading={loading} className={classes.purple}>
            <div className={classes.riskInfoValue}>
              <CountUp
                end={lobbyElectronicRevenue}
                decimals={2}
                decimal="."
                useEasing={true}
              />
            </div>
            <div className={classes.riskInfoTitle}>
              {T("lobbyElectronicRevenue")}
            </div>
          </Card>
        </div>

        <div
          style={{
            width: isSingleMode ? "100%" : "32%",
            marginBottom: isSingleMode ? 10 : 0,
          }}
        >
          <Card loading={loading} className={classes.blue}>
            <div className={classes.riskInfoValue}>
              <CountUp
                end={lobbyCompetitiveRake}
                decimals={2}
                decimal="."
                useEasing={true}
              />
            </div>
            <div className={classes.riskInfoTitle}>
              {T("lobbyCompetitiveRake")}
            </div>
          </Card>
        </div>
      </Grid>

      {
        // 警示訊息與線上人數
      }
      <Grid
        container
        item
        xs={12}
        style={{ marginTop: 20 }}
        justify="space-between"
      >
        <WarningMessage />
        <div
          style={{
            width: isSingleMode ? "100%" : "32%",
            marginTop: isSingleMode ? 20 : 0,
            position: "relative",
          }}
        >
          <DataInfo
            number={5456}
            loading={loading}
            title={T("lobbyElectronicOnlinePlayers")}
            style={{
              float: "left",
              width: "47.5%",
              position: "absolute",
              top: 0,
              left: 0,
              height: 205,
              margin: 0,
            }}
          />
          <DataInfo
            number={5456}
            loading={loading}
            title={T("lobbyCompetitiveOnlinePlayers")}
            style={{
              float: "right",
              width: "47.5%",
              position: "absolute",
              top: 0,
              right: 0,
              height: 205,
              margin: 0,
            }}
          />
          <DataInfo
            number={5456}
            loading={loading}
            title={T("clubElectronicOnlinePlayers")}
            style={{
              float: "left",
              width: "47.5%",
              position: "absolute",
              bottom: 0,
              left: 0,
              height: 205,
              margin: 0,
            }}
          />
          <DataInfo
            number={5456}
            loading={loading}
            title={T("clubCompetitiveOnlinePlayers")}
            style={{
              float: "left",
              width: "47.5%",
              position: "absolute",
              bottom: 0,
              right: 0,
              height: 205,
              margin: 0,
            }}
          />
          {/* <div
            className={`${classes.card} ${classes.center}`}
            style={{
              float: "left",
              width: "47.5%",
              height: 205,
              marginBottom: 30,
              position: "relative",
            }}
          >
            <LoadingSpin loading={loading} />
            <div style={{ fontSize: 32 }}>{5456}</div>
            <div style={{ fontSize: 14 }}>
              {T("lobbyCompetitiveOnlinePlayers")}
            </div>
          </div>
          <div
            className={`${classes.card} ${classes.center}`}
            style={{
              float: "left",
              width: "47.5%",
              height: 205,
              position: "relative",
            }}
          >
            <LoadingSpin loading={loading} />
            <div style={{ fontSize: 32 }}>{5456}</div>
            <div style={{ fontSize: 14 }}>
              {T("clubElectronicOnlinePlayers")}
            </div>
          </div>
          <div
            className={`${classes.card} ${classes.center}`}
            style={{
              float: "right",
              width: "47.5%",
              height: 205,
              position: "relative",
            }}
          >
            <LoadingSpin loading={loading} />
            <div style={{ fontSize: 32 }}>{5456}</div>
            <div style={{ fontSize: 14 }}>
              {T("clubCompetitiveOnlinePlayers")}
            </div>
          </div> */}
        </div>
      </Grid>
    </>
  );
};

export default PlatformInfo;
