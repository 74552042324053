import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import { IDetailKey, IPagedResponse } from "../../../../../utils/common-type";
import { unAuthorizationHandle } from "../../../../../utils/helper";
import { LinkText } from "../../../../common";
import { IAction, TemplateTable } from "../../../../layout";
import { ITransactionRound } from "../lobby-competitive-game-record/type";
import Detail from "./detail";
import Search from "./search";
import { buildFilter, defaultFilter, IFilter, sortableColumns } from "./type";

interface Props {
  // 其他頁面會用到這個功能會需傳固定的參數，像是 playerID
  fixedFilter?: Partial<IFilter>;
}

const FriendsRoomGameRecord = ({ fixedFilter }: Props) => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<ITransactionRound[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<ITransactionRound>();
  const [detailTitle, setDetailTitle] = useState<string>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const initialFlag = useRef(true);
  const { t } = useTranslation();
  const T = (name: string) => t(`FriendsRoomGameRecord.${name}`);

  const header: IDetailKey<ITransactionRound & { roomFee: number }>[] = [
    { key: "createdAt", name: T("time") },
    { key: "transactionID", name: T("transactionID") },
    { key: "gameID", name: T("gameID") },
    {
      key: "ruleID",
      name: T("ruleID"),
      custom: (ruleId: string, source: ITransactionRound) => {
        return <LinkText onClick={() => {}}>{source.ruleID}</LinkText>;
      },
    },
    { key: "roomID", name: T("roomID") },
    {
      key: "roundCount",
      name: T("roundCount"),
      custom: (_, record) => {
        return record.gameDetail.list.length;
      },
      isInt: true,
    },
    {
      key: "roomFee",
      name: T("roomFee"),
      custom: (_, record) => record.gameDetail.roomFee,
    },
    { key: "fakeID", name: T("fakeID"), isInt: true },
    { key: "winLose", name: T("winLose") },
  ];
  const action: IAction<ITransactionRound> = {
    create: false,
    del: false,
    edit: false,
    read: true,
    onRead: (data: ITransactionRound) => {
      setIsDetailOpen(true);
      setDetailTitle(`${T("transactionID")}：${data.transactionID}`);
      setDetail(data);
    },
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ITransactionRound[]>>({
        method: api.getTransactionRounds.method,
        url: api.getTransactionRounds.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => {
            return {
              ...d,
              time: moment(d.createdAt).format(),
              bet: d.bet / 10000,
              win: d.win / 10000,
              winLose: (d.win + d.gameDetail.rake - d.bet) / 10000,
              revenue: (d.win - d.bet) / 10000,
              gameDetail: { ...d.gameDetail, rake: d.gameDetail.rake / 10000 },
            };
          })
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }
    onSearch();
  }, [onSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search filter={filter} fixedFilter={fixedFilter} setFilter={setFilter} />
      <Detail
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        widthSize="md"
        title={detailTitle}
        data={detail}
      ></Detail>
      <TemplateTable<ITransactionRound, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default FriendsRoomGameRecord;
