import { Breadcrumbs, Divider, Link } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const Bread = ({ route }: { route: string }) => {
  const spliceRouter = route.split("/");
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Page.${name}`);
  };
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" separator=" / ">
        {spliceRouter.map((path: string, index) => {
          let pathName = "";
          for (let i = 0; i <= index; i++) {
            pathName += spliceRouter[i];
            if (i < index) {
              pathName += "/";
            }
          }
          return (
            <Link color="inherit" key={path} underline="none">
              {T(`${pathName}`)}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Divider />
    </div>
  );
};

export default Bread;
