import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateSearchFormItem } from "../../../../utils/helper";
import { SearchAccordion } from "../../../common";
import { defaultFilter, ISearchProps } from "./type";

const Search = ({ filter, data, setFilter, popup }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          enableReinitialize={true}
          initialValues={filter}
          onSubmit={(values, { setSubmitting }) => {
            setFilter({ ...values, pages: 0 });
            setSubmitting(false);
          }}
        >
          {(form) => {
            return (
              <Form style={{ width: "100%" }}>
                {generateSearchFormItem(
                  form,
                  [
                    {
                      type: "time",
                      name: "time",
                      label: "common.Time",
                    },
                    {
                      type: "text",
                      name: "UID",
                      label: "PointsDetail.UID",
                    },
                    {
                      type: "text",
                      name: "agent",
                      label: "PointsDetail.agent",
                    },
                  ].filter((v) => {
                    return !popup || v.name !== "UID";
                  }) as any,
                  defaultFilter(),
                  t,
                  data,
                  "Points Detail"
                )}
              </Form>
            );
          }}
        </Formik>
      </MuiPickersUtilsProvider>
    </SearchAccordion>
  );
};

export default Search;
