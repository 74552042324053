import React from "react";
import { Form, Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { defaultFilter, ISearchProps } from "./type";
import { SearchAccordion } from "../../../common";
import { generateSearchFormItem, getTreeData } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const Search = ({ filter, setFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          initialValues={filter}
          onSubmit={(values, { setSubmitting }) => {
            setFilter({ ...values, pages: 0 });
            setSubmitting(false);
          }}
        >
          {(form) => (
            <Form style={{ width: "100%" }}>
              {generateSearchFormItem(
                form,
                [
                  {
                    type: "time",
                    name: "time",
                    label: "common.Time",
                  },
                  {
                    type: "text",
                    name: "username",
                    label: "UserManagement.username",
                  },
                  {
                    type: "text",
                    name: "nickname",
                    label: "UserManagement.nickname",
                  },
                  {
                    type: "multiSelect",
                    name: "roleID",
                    label: "UserManagement.roleID",
                    treeData: getTreeData(["Owner", "PM", "GM", "Club Owner"]),
                  },
                  {
                    type: "multiSelect",
                    name: "clubID",
                    label: "UserManagement.clubID",
                    treeData: getTreeData(["Club1", "Club2"]),
                  },
                  {
                    type: "multiSelect",
                    name: "status",
                    label: "UserManagement.status",
                    treeData: getTreeData(["activated", "locked"]),
                  },
                ],
                defaultFilter(),
                t
              )}
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </SearchAccordion>
  );
};

export default Search;
