import {
  IStoredValueRecord,
  StoredValueSourceEnum,
  StoredValueTypeEnum,
  StoredValueUnitEnum,
} from "../../pages/customer-service/stored-value-record/type";

export const fakeStoredValueRecord: IStoredValueRecord[] = [
  {
    id: "string",
    time: "2021-12-07 10:48:00",
    type: StoredValueTypeEnum.player,
    playerID: "Player 1",
    clubID: "",
    spendMoney: 100,
    storedValue: 100,
    unit: StoredValueUnitEnum.gold,
    goldBalance: 1100,
    diamondBalance: 0,
    source: StoredValueSourceEnum.ios,
    operator: "",
  },
  {
    id: "string",
    time: "2021-12-07 10:48:00",
    type: StoredValueTypeEnum.club,
    playerID: "Player 2",
    clubID: "Club 1",
    spendMoney: 100,
    storedValue: 100,
    unit: StoredValueUnitEnum.point,
    goldBalance: 1100,
    diamondBalance: 0,
    source: StoredValueSourceEnum.custom,
    operator: "User 1",
  },
];
