import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export interface IOfflinePlayer {
  playerID: string;
  nickname: string;
  remainPoints: number;
  offlineTime: string;
}

export const formInitialValues = {
  playerID: "playerID",
  nickname: "nickname",
  offlineTime: "2020-10-10T10:10:00+08:00",
};

export const sortableColumns = ["playerID", "nickname", "offlineTime"];

export interface IFilter extends IBasicFilter {
  nickname: string;
  offlineTime: moment.Moment[];
  zone: string;
  playerID: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IOfflinePlayer[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    playerID: filter.playerID,
    nickname: filter.nickname,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  offlineTime: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  playerID: "",
  nickname: "",
});
