import { Grid, InputLabel } from "@material-ui/core";
import { TreeSelect as AntdTreeSelect } from "antd";
import { Field } from "formik";
import React from "react";
import { ITreeSelectProps } from "../../utils/common-type";
export const TreeSelect = ({
  onChange,
  disable = false,
  label,
  name,
  placeholder,
  require = false,
  value,
  treeData = [],
  multiple = false,
  size = { xs: 12, sm: 3 },
}: ITreeSelectProps) => {
  return (
    <Grid
      item
      key={`title${name}`}
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      lg={size.lg}
      xl={size.xl}
    >
      <Grid container>
        <Grid item key={name[0]} xs={12}>
          <InputLabel
            required={require}
            shrink={true}
          // style={{ color: error ? "red" : "initial" }}
          >
            {label ? label : name}
          </InputLabel>
          <Field
            component={AntdTreeSelect}
            disabled={disable}
            label={label ? label : name}
            name={name}
            required={require}
            treeData={treeData}
            multiple={multiple}
            treeCheckable={multiple}
            listHeight={350}
            maxTagCount={"responsive"}
            treeNodeFilterProp="title"
            showCheckedStrategy="SHOW_CHILD"
            treeDefaultExpandAll
            // dropdownStyle={{
            //   zIndex: 1000000,
            // }}
            value={value}
            {...(onChange && { onChange })}
            {...(placeholder && { placeholder: placeholder })}
            style={{
              width: "100%",
            }}
          ></Field>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TreeSelect;
