import { Grid, InputLabel, makeStyles } from "@material-ui/core";
import { DatePicker } from "antd";
import { Field } from "formik";
import React from "react";
import { ITimePicker } from "../../utils/common-type";
const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    paddingTop: "5px",
    placeItems: "center",
  },
  gridContainer: {
    padding: theme.spacing(1.5),
  },
}));

export const TimePicker = ({
  onChange,
  disable = false,
  label,
  name,
  format = "YYYY-MM-DD HH:mm:ss",
  value,
  placeholder,
  require = false,
  size = { xs: 12, sm: 8, lg: 6 },
  range = false,
}: ITimePicker) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.gridContainer}
      item
      key={`title${name[0]}`}
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      lg={size.lg}
      xl={size.xl}
    >
      <Grid container>
        <Grid item key={name[0]} xs={12}>
          <InputLabel
            required={require}
            shrink={true}
          // style={{ color: error ? "red" : "initial" }}
          >
            {label ? label : name}
          </InputLabel>
          <Field
            component={range ? DatePicker.RangePicker : DatePicker}
            disabled={disable}
            label={label ? label : name}
            name={name}
            required={require}
            showTime
            value={value}
            allowClear={false}
            style={{
              width: "100%",
            }}
            popupStyle={{
              zIndex: 1000000,
            }}
            format={format}
            {...(onChange && {
              onChange,
            })}
            // {...(type === "time" && {
            //   format: format ? format : "YYYY-MM-DD HH:mm:ss",
            // })}
            {...(placeholder && { placeholder: placeholder[0] })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimePicker;
