import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { cloneDeep } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { IsAuthorization } from "../../app";
import { IRouterSetting } from "../../routers";
import AppVersion from "./app-version";

interface IPropType {
  drawerWidth: number;
  open: boolean;
  singleMode: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: (props: IPropType) => props.drawerWidth,
  },
  button: {
    ...theme.mixins.toolbar,
    "&:hover": {
      backgroundColor: "#2b2e45",
    },
    borderRadius: 0,
    border: 0,
    color: "white",
    width: (props: IPropType) => props.drawerWidth,
  },
  parentList: {
    "&:hover": {
      backgroundColor: "#2b2e45",
    },
  },
  childrenList: {
    paddingLeft: theme.spacing(4),
    "&:hover": {
      backgroundColor: "#2b2e45",
    },
  },
  icon: {
    minWidth: theme.spacing(4.5),
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const LeftSideDrawer: React.FC<IPropType> = (props: IPropType) => {
  const classes = useStyles(props as any);
  const { routerWithPermission } = useContext(IsAuthorization);
  const [clickedCollsapse, setClickedCollsapse] = useState<string[]>([]);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Page.${name}`);
  };

  const location = useLocation();
  const [hierarchy, setHierarchy] = useState<{ [child: string]: string }>({});
  useEffect(() => {
    const path = location.pathname;
    if (path && path.length) {
      const parents = path.split("/").filter((name) => name.length);
      parents.pop();
      const fullParents = cloneDeep(parents);
      for (const i of parents) {
        let curr = hierarchy[i];
        while (hierarchy[curr]) {
          fullParents.push(hierarchy[i]);
          curr = hierarchy[i];
        }
      }
      setClickedCollsapse([...fullParents]);
    }
  }, [location, hierarchy]);

  const getPageChildren = (
    routerWithPermission: IRouterSetting[],
    nodes: IRouterSetting,
    paddingLeft: number
  ) => {
    //設定主目錄下的子分頁
    if (nodes.children) {
      return nodes.children.map((node: IRouterSetting) => {
        if (node.children && node.children.length !== 0) {
          if (!hierarchy[node.name]) {
            setHierarchy((h) => ({ ...h, [node.name]: nodes.name }));
          }

          return getListItem(routerWithPermission, [node], paddingLeft);
        }

        if (node.visible)
          return (
            <NavLink
              activeClassName="drawer-item"
              key={node.name}
              to={`/${node.path}`}
              className={classes.link}
              onClick={() => {
                if (props.singleMode) {
                  props.setOpen(false);
                }
              }}
            >
              <ListItem
                button
                className={classes.childrenList}
                style={{ paddingLeft }}
              >
                {node.icon ? (
                  <ListItemIcon
                    classes={{
                      root: classes.icon,
                    }}
                  >
                    {node.icon}
                  </ListItemIcon>
                ) : (
                  <ListItemIcon
                    classes={{
                      root: classes.icon,
                    }}
                  />
                )}

                <ListItemText primary={T(node.path)} />
              </ListItem>
            </NavLink>
          );
      });
    }
  };

  const getListItem = (
    routerWithPermission: IRouterSetting[],
    nodes: IRouterSetting[],
    paddingLeft: number
  ) => {
    return nodes.map((node: IRouterSetting) => {
      return (
        node.visible && (
          <Fragment key={node.name}>
            <ListItem
              button
              className={classes.parentList}
              style={{ paddingLeft }}
              onClick={() => {
                if (clickedCollsapse.indexOf(node.name) === -1) {
                  setClickedCollsapse([...clickedCollsapse, node.name]);
                } else {
                  let buffer = [...clickedCollsapse];
                  buffer.splice(clickedCollsapse.indexOf(node.name), 1);
                  setClickedCollsapse(buffer);
                }
              }}
            >
              <ListItemIcon
                classes={{
                  root: classes.icon,
                }}
              >
                {node.icon}
              </ListItemIcon>
              <ListItemText primary={`${T(node.path)}`} />
              {clickedCollsapse.indexOf(node.name) !== -1 ? (
                <ExpandLess style={{ color: "white" }} />
              ) : (
                <ExpandMore style={{ color: "white" }} />
              )}
            </ListItem>
            <Collapse
              in={clickedCollsapse.indexOf(node.name) !== -1}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {getPageChildren(routerWithPermission, node, paddingLeft + 10)}
              </List>
            </Collapse>
          </Fragment>
        )
      );
    });
  };

  return (
    <Drawer
      anchor="left"
      className={classes.drawer}
      open={props.open}
      variant="persistent"
    >
      <Button
        className={classes.button}
        onClick={() => {
          props.setOpen(false);
        }}
      >
        <ChevronLeftIcon style={{ color: "white" }} />
      </Button>
      <Divider />
      <List style={{ width: props.drawerWidth }}>
        {getListItem(routerWithPermission, routerWithPermission, 15)}
      </List>
      <AppVersion />
    </Drawer>
  );
};

export default LeftSideDrawer;
