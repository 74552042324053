import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { generateFormItem } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { formInitialValues, IRiskOtherConfigDescription } from "./type";

const EditOtherConfig = (props: IEditProps<IRiskOtherConfigDescription>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`RiskConfig.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "number",
                  name: "current",
                  label: "dailyWin",
                  size: { xs: 12 },
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default EditOtherConfig;
