import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export interface ILinkList {
  onClick: any;
  name: string;
}

interface IProps {
  style?: React.CSSProperties;
  source: any;
  onClick?: any;
  isHaveList?: boolean;
  linkList?: ILinkList[];
}

const DetailButton = React.memo(
  ({ onClick, style, source, isHaveList = false, linkList = [] }: IProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
      console.log("init");
    }, []);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={isHaveList ? handleMenu : onClick} style={style}>
          <DescriptionIcon />
        </IconButton>
        {isHaveList && (
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {linkList.map((link, index) => {
              return (
                <MenuItem
                  key={`link${index}`}
                  onClick={() => {
                    link.onClick(source);
                    handleClose();
                  }}
                >
                  {t(`Page.${link.name}`)}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    );
  }
);

export default DetailButton;
