import { IShopItemRecord } from "../../pages/report-management/shop-report/detail/type";
import {
  IShopReport,
  ShopItemSourceEnum,
} from "../../pages/report-management/shop-report/type";

export const fakeShopReport: IShopReport[] = [
  {
    item: "item 1",
    salesAmount: 100,
    salesCount: 100,
    totalSalesAmount: 500,
    source: ShopItemSourceEnum.android,
  },
  {
    item: "item 2",
    salesAmount: 100,
    salesCount: 100,
    totalSalesAmount: 500,
    source: ShopItemSourceEnum.ios,
  },
  {
    item: "item 3",
    salesAmount: 100,
    salesCount: 100,
    totalSalesAmount: 500,
    source: ShopItemSourceEnum.custom,
  },
];

export const fakeShopItemRecord: IShopItemRecord[] = [
  {
    playerID: "string",
    nickname: "string",
    amount: "string",
    price: 0,
    time: "string",
  },
];
