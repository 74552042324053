import { IOfflinePlayer } from "../../pages/club-service/offline-players/type";

export const fakeOfflinePlayer: IOfflinePlayer[] = [
  {
    playerID: "playerID",
    nickname: "nickname",
    remainPoints: 100,
    offlineTime: "2021-10-25 23:59:59",
  },
];
