import moment from "moment";

export enum WeekDayEnum {
  sunday = "sunday",
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
}

export type IOffHour = {
  day: WeekDayEnum;
  offHour: moment.Moment[] | null;
};

export const defaultValue: IOffHour = {
  offHour: null,
  day: WeekDayEnum.sunday,
};
