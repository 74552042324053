import { Grid, useMediaQuery } from "@material-ui/core";
import { Table } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { useBusinessStatisticStyle } from "../../../../assets/styles/business-statistic";
import { dataTypeCheck, formatAmount } from "../../../../utils/helper";
import { CustomDialog, LinkText } from "../../../common";
import Search from "./search";
import {
  clubRelatedFields,
  defaultFilter,
  IFilter,
  IRevenueReport,
  lobbyGameFields,
  platformSelfFields,
} from "./type";

const RevenueRport = () => {
  const auth = useContext(IsAuthorization);
  const classes = useBusinessStatisticStyle();
  const [data] = useState<IRevenueReport>({
    platform: {
      totalRevenue: -100,
      thirdPartyServiceFee: 100,
      storedValuePlayerCount: 10,
      storedValueCount: 9,
      storedValueRatio: 0.9,
      arpu: 100,
      arppu: 100,
      firstStoredValueRevenue: 100,
      firstStoredValuePlayerCount: 100,
      firstStoredValueArppu: 100,
      totalPlayerCount: 10,
    },
    lobby: {
      lobbyDAU: 10,
      lobbyRevenue: 100,
      customerServiceRevenue: 100,
      thirdPartyRevenue: 100,
      storedValuePlayerCount: 10,
      storedValueCount: 9,
      storedValueRatio: 0.9,
      electronicNetProfit: 100,
      competitiveRake: 100,
      friendRoomDiamondCost: 100,
    },
    club: {
      clubDAU: 10,
      clubRevenue: 100,
      storedValueClubCount: 10,
      storedValueCount: 9,
      storedValueRatio: 0.9,
      electronicDiamodCost: 100,
      roomCreatedDiamodCost: 100,
      roomCreatedCount: 100,
      totalClubCount: 10,
      totalClubPlayerCount: 10,
      newClubCount: 5,
    },
  });
  const intFileds = [
    "storedValuePlayerCount",
    "storedValueCount",
    "firstStoredValuePlayerCount",
    "totalPlayerCount",
    "storedValueClubCount",
    "roomCreatedCount",
    "totalClubCount",
    "totalClubPlayerCount",
    "newClubCount",
  ];
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`RevenueReport.${name}`);
  const [clubLevelDialogOpen, setClubLevelDialogOpen] = useState(false);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <CustomDialog
        title={T("totalClubCount")}
        open={clubLevelDialogOpen}
        setOpen={() => {
          setClubLevelDialogOpen(false);
        }}
        widthSize="md"
      >
        <Table
          columns={[
            { title: T("clubLevel"), dataIndex: "clubLevel", key: "clubLevel" },
            {
              title: T("clubCount"),
              dataIndex: "clubCount",
              key: "clubCount",
            },
          ]}
          dataSource={[
            { clubLevel: "1", clubCount: 100 },
            { clubLevel: "2", clubCount: 100 },
            { clubLevel: "3", clubCount: 100 },
            { clubLevel: "4", clubCount: 100 },
            { clubLevel: "5", clubCount: 100 },
          ]}
          rowKey={(record) => record.clubLevel}
        />
      </CustomDialog>
      <div>
        <Grid container spacing={2}>
          <Grid container item justify="space-between" spacing={2}>
            <Grid item xs={isSingleMode ? 12 : 8}>
              <div className={`${classes.cardTitle} ${classes.darkBlue}`}>
                {T("platformStoredValue")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.platform).map(([key, value], index) => {
                    if (!platformSelfFields.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={4}>
                          <span className={classes.dataInfo}>{T(key)}: </span>
                          <span
                            className={[
                              classes.dataInfo,
                              Number(value) < 0 ? classes.redText : "",
                            ].join(" ")}
                          >
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={isSingleMode ? 12 : 4}>
              <div className={`${classes.cardTitle} ${classes.lightPurple}`}>
                {T("platform")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.platform).map(([key, value], index) => {
                    if (platformSelfFields.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={4}>
                          <span className={classes.dataInfo}>{T(key)}: </span>
                          <span
                            className={[
                              classes.dataInfo,
                              Number(value) < 0 ? classes.redText : "",
                            ].join(" ")}
                          >
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" spacing={2}>
            <Grid item xs={isSingleMode ? 12 : 8}>
              <div className={`${classes.cardTitle} ${classes.blue}`}>
                {T("lobbyStoredValue")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.lobby).map(([key, value], index) => {
                    if (!lobbyGameFields.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={6}>
                          <span className={classes.dataInfo}>
                            {T(key)}:{" "}
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={isSingleMode ? 12 : 4}>
              <div className={`${classes.cardTitle} ${classes.orange}`}>
                {T("lobbyGame")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.lobby).map(([key, value], index) => {
                    if (lobbyGameFields.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={12}>
                          <span className={classes.dataInfo}>
                            {T(key)}:{" "}
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" spacing={2}>
            <Grid item xs={isSingleMode ? 12 : 4}>
              <div className={`${classes.cardTitle} ${classes.green}`}>
                {T("clubStoredValue")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.club).map(([key, value], index) => {
                    if (clubRelatedFields.storedValue.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={12}>
                          <span className={classes.dataInfo}>
                            {T(key)}:{" "}
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={isSingleMode ? 12 : 4}>
              <div className={`${classes.cardTitle} ${classes.red}`}>
                {T("club")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.club).map(([key, value], index) => {
                    if (clubRelatedFields.game.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={12}>
                          <span className={classes.dataInfo}>
                            {T(key)}:{" "}
                            {intFileds.includes(key)
                              ? value
                              : dataTypeCheck(value)}
                          </span>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={isSingleMode ? 12 : 4}>
              <div className={`${classes.cardTitle} ${classes.purple}`}>
                {T("clubGame")}
              </div>
              <div className={classes.card}>
                <Grid item container>
                  {Object.entries(data.club).map(([key, value], index) => {
                    if (clubRelatedFields.club.includes(key)) {
                      return (
                        <Grid key={key + index} item xs={12}>
                          {key === "totalClubCount" ? (
                            <LinkText
                              onClick={() => {
                                setClubLevelDialogOpen((open) => !open);
                              }}
                            >
                              {T(key)}:{" "}
                              {
                                value % 1 != 0
                                  ? formatAmount(value, 2)
                                  : value /* 判斷是否為小數，若為小數則顯示至第二位 */
                              }
                            </LinkText>
                          ) : (
                            <span className={classes.dataInfo}>
                              {T(key)}:{" "}
                              {
                                value % 1 != 0
                                  ? formatAmount(value, 2)
                                  : value /* 判斷是否為小數，若為小數則顯示至第二位 */
                              }
                            </span>
                          )}
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RevenueRport;
