import { random } from "lodash";
import { dataTypeCheck } from "../../../utils/helper";

export const deviceData01 = [
  {
    name: "ios",
    value: 400,
  },
  {
    name: "android",
    value: 300,
  },
  {
    name: "web",
    value: 300,
  },
];

export const fakeGameBarChartData = [
  {
    name: "Game A",
    rtp: 98,
    winRate: 98,
    revenue: 1005,
    winGold: 522,
    betTimes: 1001,
    jackpot: 300,
    playerCount: 1002,
    rooms: 470,
    diamonds: 800,
    winLose: 700,
    rake: 1466,
  },
  {
    name: "Game B",
    rtp: 85,
    winRate: 92,
    revenue: 820,
    winGold: 600,
    betTimes: 800,
    jackpot: 500,
    playerCount: 900,
    rooms: 300,
    diamonds: 820,
    winLose: 780,
    rake: 700,
  },
  {
    name: "Game C",
    rtp: 94,
    winRate: 92,
    revenue: 980,
    winGold: 400,
    betTimes: 600,
    jackpot: 600,
    playerCount: 950,
    rooms: 400,
    diamonds: 621,
    winLose: 801,
    rake: 392,
  },
  {
    name: "Game D",
    rtp: 81,
    winRate: 89,
    revenue: 700,
    winGold: 600,
    betTimes: 650,
    jackpot: 150,
    playerCount: 670,
    rooms: 380,
    diamonds: 280,
    winLose: 750,
    rake: 800,
  },
  {
    name: "Game E",
    rtp: 77,
    winRate: 73,
    revenue: 900,
    winGold: 400,
    betTimes: 680,
    jackpot: 750,
    playerCount: 950,
    rooms: 310,
    diamonds: 660,
    winLose: 900,
    rake: 500,
  },
  {
    name: "Game F",
    rtp: 99,
    winRate: 99,
    revenue: 800,
    winGold: 400,
    betTimes: 670,
    jackpot: 740,
    playerCount: 701,
    rooms: 377,
    diamonds: 419,
    winLose: 537,
    rake: 157,
  },
  {
    name: "Game G",
    rtp: 85,
    winRate: 95,
    revenue: 422,
    winGold: 680,
    betTimes: 690,
    jackpot: 444,
    playerCount: 640,
    rooms: 725,
    diamonds: 421,
    winLose: 600,
    rake: 400,
  },
];

export const fakeLobbyElectronicRank = [
  {
    rank: 1,
    playerID: "player 1",
    score: 100,
  },
  {
    rank: 2,
    playerID: "player 1",
    score: 90,
  },
  {
    rank: 3,
    playerID: "player 1",
    score: 80,
  },
];

export const fakeLobbyCompetitiveRank = [
  {
    rank: 1,
    playerID: "player 1",
    score: 100,
  },
  {
    rank: 2,
    playerID: "player 1",
    score: 90,
  },
  {
    rank: 3,
    playerID: "player 1",
    score: 80,
  },
];

export const fakeLobbyGiftRank = [
  {
    rank: 1,
    playerID: "player 1",
    giftGolds: 100,
  },
  {
    rank: 2,
    playerID: "player 1",
    giftGolds: 90,
  },
  {
    rank: 3,
    playerID: "player 1",
    giftGolds: 80,
  },
];

export const fakeFullLobbyGiftRank: any = [];
export const fakeFullElectronicRank: any = [];
export const fakeFullCompetitiveRank: any = [];

for (let i = 1; i <= 50; i++) {
  fakeFullLobbyGiftRank.push({
    rank: i,
    playerID: `player ${i}`,
    giftGolds: dataTypeCheck(random(1, 100)),
  });
  fakeFullElectronicRank.push({
    rank: i,
    playerID: `player ${i}`,
    score: dataTypeCheck(random(1, 100)),
  });
  fakeFullCompetitiveRank.push({
    rank: i,
    playerID: `player ${i}`,
    score: dataTypeCheck(random(1, 100)),
  });
}

export const fakeMonthlyRevenue: any = [];
for (let i = 1; i <= 31; i++) {
  fakeMonthlyRevenue.push({
    name: i,
    totalRevenue: random(1000, 2000),
    lobbyRevenue: random(500, 1000),
    clubRevenue: random(500, 1000),
  });
}
