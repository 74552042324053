import React from "react";
import { useCallback } from "react";
import { useState } from "react";

interface IMessage {
  text: string;
  type: "success" | "error" | "info" | "warning";
}

export const AlertServiceContext = React.createContext<{
  message: IMessage | null;
  addMessage: (m: IMessage) => void;
  removeMessage: () => void;
}>({
  message: null,
  addMessage: () => { },
  removeMessage: () => { },
});

export const useAlert = () => React.useContext(AlertServiceContext);

export const AlertServiceProvider = ({ children }: { children: any }) => {
  const [message, setMessage] = useState<IMessage | null>(null);
  const removeMessage = () => setMessage(null);
  const addMessage = ({ text, type }: IMessage) => setMessage({ text, type });

  const contextValue = {
    message,
    addMessage: useCallback(
      ({ text, type }: IMessage) => addMessage({ text, type }),
      []
    ),
    removeMessage: useCallback(() => removeMessage(), []),
  };

  return (
    <>
      <AlertServiceContext.Provider value={contextValue}>
        {children}
      </AlertServiceContext.Provider>
    </>
  );
};
