import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { api } from "../../../../environment";
import { instance } from "../../../../environment/axios";
import {
  IDetailKey,
  IEditProps,
  IPagedResponse,
} from "../../../../utils/common-type";
import { pageSizeList, unAuthorizationHandle } from "../../../../utils/helper";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Edit from "./edit";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  IPlayer,
  sortableColumns,
} from "./type";

const PlayerManagement = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<IPlayer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [actionWidth, setActionWidth] =
    useState<IEditProps<any>["widthSize"]>("md");
  const [detail, setDetail] = useState<IPlayer>();
  const { t } = useTranslation();
  const initialFlag = useRef(true);
  const T = (name: string) => {
    return t(`PlayerManagement.${name}`);
  };

  const header: IDetailKey<IPlayer>[] = [
    { key: "id", name: T("id") },
    { key: "nickname", name: T("nickname") },
    { key: "level", name: T("level"), isInt: true },
    { key: "experience", name: T("experience") },
    { key: "clubID", name: T("clubIDs") },
    { key: "status", name: T("status") },
    { key: "tag", name: T("tag") },
    { key: "gem", name: T("gem") },
    { key: "point", name: T("point") },
    { key: "createdAt", name: T("createdAt") },
    { key: "lastLoginAt", name: T("updatedAt") },
  ];

  const action: IAction<IPlayer> = {
    create: false,
    del: false,
    edit: true,
    onEdit: (data: IPlayer) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onDel: () => {},
    custom: [
      {
        name: "大廳電子遊戲",
        actionIcon: <SportsEsportsIcon />,
        onEvent: (player: IPlayer) => {
          setIsActionOpen(true);
          setActionName("LobbyElectronicGameRecord");
          setDetail(player);
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
      {
        name: "大廳競技遊戲",
        actionIcon: <SportsEsportsIcon />,
        onEvent: (player: IPlayer) => {
          setIsActionOpen(true);
          setActionName("LobbyCompetitiveGameRecord");
          setDetail(player);
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
      {
        name: "好友房競技遊戲",
        actionIcon: <SportsEsportsIcon />,
        onEvent: (player: IPlayer) => {
          setIsActionOpen(true);
          setActionName("FriendsRoomGameRecord");
          setDetail(player);
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
      {
        name: "進出紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (player: IPlayer) => {
          setIsActionOpen(true);
          setActionName("AccessRecord");
          setDetail(player);
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
      {
        name: "儲值/回收",
        actionIcon: <MonetizationOnIcon />,
        onEvent: () => {
          setIsActionOpen(true);
          setActionName("StoredValue");
          setActionWidth("xs");
        },
        position: "TOP",
      },
    ],
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<IPlayer[]>>({
        method: api.getPlayer.method,
        url: api.getPlayer.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => {
            return {
              ...d,
              createdAt: moment(d.createdAt).format(),
              lastLoginAt: moment(d.lastLoginAt).format(),
            };
          })
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onSearch();
  }, [onSearch]);

  return auth.login ? (
    <>
      <Search filter={filter} setFilter={setFilter} selectionList={{}} />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="md"
      ></Edit>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize={actionWidth}
        action={actionName}
      />
      <TemplateTable<IPlayer, IFilter>
        action={action}
        data={data}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default PlayerManagement;
