import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey, IEditProps } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { fakeClub } from "../../../fake-data/club-service/club";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./action";
import Create from "./create";
import Edit from "./edit";
import Search from "./search";
import {
  defaultFilter,
  formInitialValues,
  IClub,
  IFilter,
  sortableColumns,
} from "./type";

const ClubManagement = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IClub[]>(fakeClub);
  const [downloadData] = useState<any>(fakeClub);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IClub>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const { t } = useTranslation();
  const [actionWidth, setActionWidth] =
    useState<IEditProps<any>["widthSize"]>("md");
  const T = (name: string) => {
    return t(`ClubManagement.${name}`);
  };

  const header: IDetailKey<IClub>[] = [
    { key: "id", name: T("ID") },
    { key: "name", name: T("name") },
    { key: "ownerUID", name: T("ownerUID") },
    { key: "ownerPlayerUID", name: T("ownerPlayerUID") },
    { key: "ownerName", name: T("ownerName") },
    { key: "totalPoints", name: T("totalPoints") },
    { key: "diamonds", name: T("diamonds") },
    { key: "accDiamonds", name: T("accDiamonds") },
    { key: "level", name: T("level"), isInt: true },
    {
      key: "members",
      name: T("members"),
      custom: (_, source) => `${source.members}/${source.memberLimit}`,
    },
  ];

  const action: IAction<IClub> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IClub) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setDetail(formInitialValues);
      setIsCreateOpen(true);
    },
    onDel: () => {},
    custom: [
      {
        name: "儲值/回收",
        actionIcon: <MonetizationOnIcon />,
        onEvent: (club: IClub) => {
          setIsActionOpen(true);
          setActionName("storedValue");
          setDetail(club);
          setActionWidth("xs");
        },
        position: "TOP",
      },
      {
        name: "儲值紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (club: IClub) => {
          setIsActionOpen(true);
          setActionName("storedValueRecord");
          setDetail(club);
          setActionWidth("xl");
        },
        position: "IN_ROW",
      },
    ],
  };

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{ club: ["ClubA", "ClubB", "ClubC"] }}
      />
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize={actionWidth}
        action={actionName}
      />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="xs"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        data={detail}
        widthSize="sm"
      ></Create>
      <TemplateTable<IClub, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default ClubManagement;
