import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export interface IBanner {
  title: string;
  url: string;
  imageURL: string;
  startTime: string;
  endTime: string;
  createTime: string;
  updateTime: string;
  priority: number;
}

export const formInitialValues = {
  title: "string",
  url: "string",
  imageURL: "string",
  startTime: moment(),
  endTime: moment(),
  createTime: moment(),
  updateTime: moment(),
  priority: 1,
};

export const sortableColumns = [
  "title",
  "startTime",
  "endTime",
  "createTime",
  "priority",
];

export interface IFilter extends IBasicFilter {
  title: string;
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IBanner[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    title: filter.title,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  title: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
