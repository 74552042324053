import EqualizerIcon from "@material-ui/icons/Equalizer";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import React from "react";
import {
  AccessRecord,
  BackStageLog,
  Bulletin,
  BusinessReport,
  ClubBusinessReport,
  ClubCompetitiveGameRecord,
  ClubCompetitiveGameReport,
  ClubElectronicGameRecord,
  ClubElectronicGameReport,
  ClubManagement,
  // ClubPlayerReport,
  ClubServiceLog,
  CustomerServiceLog,
  Dashboard,
  Event,
  FlowAnalysis,
  FriendsRoomGameRecord,
  GameManagement,
  GiftRecord,
  JoinRequest,
  LevelAnalysis,
  LobbyCompetitiveGameRecord,
  LobbyCompetitiveGameReport,
  LobbyElectronicGameRecord,
  LobbyElectronicGameReport,
  LobbyPlayerReport,
  Mail,
  Marquee,
  MemberList,
  OfflinePlayers,
  OnlineAnalysis,
  PlayerManagement,
  PointsDetail,
  PointsRecord,
  RevenueReport,
  RiskConfig,
  RoleManagement,
  ConsumeRecord,
  ShopReport,
  StoredValueRecord,
  Suggestion,
  SystemConfigLog,
  UserManagement,
  WarningMessage,
} from "./component/pages";
export interface IRouterSetting {
  children?: IRouterSetting[];
  component?: any;
  icon?: any;
  name: string;
  path: string;
  parentName?: string;
  open?: boolean;
  visible?: boolean;
}

export let router: IRouterSetting[] = [
  {
    icon: <PersonIcon />,
    name: "Statistics",
    path: "Statistics",
    open: false,
    visible: true,
    children: [
      {
        component: <BusinessReport />,
        name: "BusinessReport",
        path: "Statistics/BusinessReport",
        visible: true,
      },
      {
        component: <ClubBusinessReport />,
        name: "ClubBusinessReport",
        path: "Statistics/ClubBusinessReport",
        visible: true,
      },
    ],
  },
  {
    icon: <PersonIcon />,
    name: "Report",
    path: "Report",
    open: false,
    visible: true,
    children: [
      {
        name: "Game",
        path: "Report/Game",
        visible: true,
        children: [
          {
            component: <LobbyElectronicGameReport />,
            name: "LobbyElectronic",
            path: "Report/Game/LobbyElectronic",
            visible: true,
          },
          {
            component: <LobbyCompetitiveGameReport />,
            name: "LobbyCompetitive",
            path: "Report/Game/LobbyCompetitive",
            visible: true,
          },
          {
            component: <ClubElectronicGameReport />,
            name: "ClubElectronic",
            path: "Report/Game/ClubElectronic",
            visible: true,
          },
          {
            component: <ClubCompetitiveGameReport />,
            name: "ClubCompetitive",
            path: "Report/Game/ClubCompetitive",
            visible: true,
          },
        ],
      },
      {
        component: <RevenueReport />,
        name: "Revenue",
        path: "Report/Revenue",
        visible: true,
      },
      {
        component: <ShopReport />,
        name: "Shop",
        path: "Report/Shop",
        visible: true,
      },
      {
        name: "Player",
        path: "Report/Player",
        visible: true,
        children: [
          {
            component: <LobbyPlayerReport />,
            name: "Lobby",
            path: "Report/Player/Lobby",
            visible: true,
          },
          // {
          //   component: <ClubPlayerReport />,
          //   name: "Club",
          //   path: "Report/Player/Club",
          //   visible: true,
          // },
          {
            name: "FlowAnalysis",
            path: "Report/Player/FlowAnalysis",
            visible: true,
            component: <FlowAnalysis />,
          },
          {
            name: "LevelAnalysis",
            path: "Report/Player/LevelAnalysis",
            visible: true,
            component: <LevelAnalysis />,
          },
          {
            name: "OnlineAnalysis",
            path: "Report/Player/OnlineAnalysis",
            visible: true,
            component: <OnlineAnalysis />,
          },
        ],
      },
      {
        name: "Record",
        path: "Report/Record",
        visible: true,
        children: [
          {
            component: <LobbyElectronicGameRecord />,
            name: "LobbyElectronic",
            path: "Report/Record/LobbyElectronic",
            visible: true,
          },
          {
            component: <LobbyCompetitiveGameRecord />,
            name: "LobbyCompetitive",
            path: "Report/Record/LobbyCompetitive",
            visible: true,
          },
          {
            component: <FriendsRoomGameRecord />,
            name: "PrivateRoom",
            path: "Report/Record/PrivateRoom",
            visible: true,
          },
          {
            component: <ClubElectronicGameRecord />,
            name: "ClubElectronic",
            path: "Report/Record/ClubElectronic",
            visible: true,
          },
          {
            component: <ClubCompetitiveGameRecord />,
            name: "ClubCompetitive",
            path: "Report/Record/ClubCompetitive",
            visible: true,
          },
        ],
      },
    ],
  },
  {
    icon: <PersonIcon />,
    name: "Customer",
    path: "Customer",
    open: false,
    visible: true,
    children: [
      {
        component: <Suggestion />,
        name: "Case",
        path: "Customer/Case",
        visible: true,
      },
      {
        component: <StoredValueRecord />,
        name: "DepositRecord",
        path: "Customer/DepositRecord",
        visible: true,
      },
      // {
      //   component: <ClubOwnerStoredValueRecord />,
      //   name: "ClubOwnerDepositRecord",
      //   visible: true,
      // },
      {
        component: <GiftRecord />,
        name: "GiftRecord",
        path: "Customer/GiftRecord",
        visible: true,
      },
      {
        component: <ConsumeRecord />,
        name: "ConsumeRecord",
        path: "Customer/ConsumeRecord",
        visible: true,
      },
    ],
  },
  {
    icon: <SportsEsportsIcon />,
    name: "Platform",
    path: "Platform",
    open: false,
    visible: true,
    children: [
      {
        component: <GameManagement />,
        name: "GameManagement",
        path: "Platform/GameManagement",
        visible: true,
      },
      {
        component: <PlayerManagement />,
        name: "PlayerManagement",
        path: "Platform/PlayerManagement",
        visible: true,
      },
      {
        component: <Mail />,
        name: "Mail",
        path: "Platform/Mail",
        visible: true,
      },
      {
        component: <Bulletin />,
        name: "Bulletin",
        path: "Platform/Bulletin",
        visible: true,
      },
      {
        component: <Marquee />,
        name: "Marquee",
        path: "Platform/Marquee",
        visible: true,
      },
      // {
      //   component: <Banner />,
      //   name: "Banner",
      //   visible: true,
      // },
      {
        component: <Event />,
        name: "Event",
        path: "Platform/Event",
        visible: true,
      },
      // {
      //   component: <Shop />,
      //   name: "Shop",
      //   visible: true,
      // },
      // {
      //   component: <SystemConfig />,
      //   name: "SystemConfig",
      //   visible: true,
      // },
    ],
  },
  {
    icon: <EqualizerIcon />,
    name: "Risk",
    path: "Risk",
    open: false,
    visible: true,
    children: [
      {
        component: <Dashboard />,
        name: "Dashboard",
        path: "Risk/Dashboard",
        visible: true,
      },
      {
        component: <RiskConfig />,
        name: "RiskConfig",
        path: "Risk/RiskConfig",
        visible: true,
      },
      {
        component: <WarningMessage />,
        name: "WarningMessage",
        path: "Risk/WarningMessage",
        visible: true,
      },
    ],
  },
  {
    icon: <EqualizerIcon />,
    name: "Backstage",
    path: "Backstage",
    open: false,
    visible: true,
    children: [
      {
        component: <UserManagement />,
        name: "UserManagement",
        path: "Backstage/UserManagement",
        visible: true,
      },
      {
        component: <RoleManagement />,
        name: "RoleManagement",
        path: "Backstage/RoleManagement",
        visible: true,
      },
      {
        component: <CustomerServiceLog />,
        name: "CustomerServiceLog",
        path: "Backstage/CustomerServiceLog",
        visible: true,
      },
      {
        component: <ClubServiceLog />,
        name: "ClubServiceLog",
        path: "Backstage/ClubServiceLog",
        visible: true,
      },
      {
        component: <SystemConfigLog />,
        name: "SystemConfigLog",
        path: "Backstage/SystemConfigLog",
        visible: true,
      },
      {
        component: <BackStageLog />,
        name: "BackstageLog",
        path: "Backstage/BackstageLog",
        visible: true,
      },
    ],
  },
  {
    icon: <GroupIcon />,
    name: "Club",
    path: "Club",
    open: false,
    visible: true,
    children: [
      {
        component: <ClubManagement />,
        name: "Management",
        path: "Club/Management",
        visible: true,
      },
      {
        component: <MemberList />,
        name: "MemberList",
        path: "Club/MemberList",
        visible: true,
      },
      {
        component: <AccessRecord />,
        name: "AccessRecord",
        path: "Club/AccessRecord",
        visible: true,
      },
      {
        component: <JoinRequest />,
        name: "JoinRequest",
        path: "Club/JoinRequest",
        visible: true,
      },
      {
        component: <PointsDetail />,
        name: "PointsDetail",
        path: "Club/PointsDetail",
        visible: true,
      },
      {
        component: <PointsRecord />,
        name: "PointsRecord",
        path: "Club/PointsRecord",
        visible: true,
      },
      // {
      //   component: <AgentPermission />,
      //   name: "AgentPermission",
      //   visible: true,
      // },
      // {
      //   component: <OffHour />,
      //   name: "ClubServiceHours",
      //   visible: false,
      // },
      {
        component: <OfflinePlayers />,
        name: "OfflinePlayers",
        path: "Club/OfflinePlayers",
        visible: true,
      },
    ],
  },
];
