import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { default as Alert, default as MuiAlert } from "@material-ui/lab/Alert";
import Axios from "axios";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsAuthorization } from "../../../app";
import { api } from "../../../environment/api";
import { instance } from "../../../environment/axios";
import { router } from "../../../routers";
import { getHomePath, getPagePathList } from "../../../utils/helper";
import { ILoginResponse } from "./type";
const CryptoJS = require("crypto-js");

interface ILogin {
  playerID: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1),
  },
  contain: {
    paddingTop: "25vh",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    maxWidth: "none",
    backgroundColor: "#4C8EA7",
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/login-background.svg)`,
  },
  message: {
    backgroundColor: "#f44336",
  },
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 5,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  langSelector: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
const Login = ({
  login,
  history,
}: {
  login: React.Dispatch<React.SetStateAction<boolean>>;
  history: any;
}) => {
  const classes = useStyles();
  const { pagePermission, setPagePermission, routerWithPermission } =
    useContext(IsAuthorization);
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Login.${name}`);
  };
  const [model] = useState<{ playerID: string; password: string }>({
    playerID: "",
    password: "",
  });

  // if login true, router to default page
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.history.replace(
        getHomePath(routerWithPermission, pagePermission)
      );
    }
  });

  useEffect(() => {
    console.log(model);
  });
  console.log(history, "history");
  return (
    <Container className={classes.contain} component="main">
      <div className={classes.loginWrapper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="div" variant="h5">
          {T("HaoHaoFunManagementSystem")}
        </Typography>
        <Formik
          enableReinitialize
          initialValues={model}
          validate={(values) => {
            const errors: Partial<ILogin> = {};
            if (!values.password) {
              errors.password = T("Required");
            }
            if (!values.playerID) {
              errors.playerID = T("Required");
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (values.playerID === "test") {
              //測試帳號可以直接登入
              localStorage.setItem("token", "test");

              // TODO: BD 暫時代替所有權限 到時候會分各個全縣
              const allPermissions = getPagePathList(router);
              console.log(allPermissions, 123123123);
              var ciphertext = CryptoJS.AES.encrypt(
                allPermissions.join(","),
                process.env.REACT_APP_SECRET_KEY || "my-secret-key@123"
              ).toString();
              localStorage.setItem("permission", ciphertext);

              login(true);
              setPagePermission(allPermissions);
              history.history.replace(getHomePath(router, allPermissions));
              return;
            }
            Axios({
              method: api.login.method,
              url: api.login.url(),
              data: {
                Account: values.playerID,
                Password: values.password,
              },
            })
              .then((res) => {
                login(true);
                setMessage("");
                const response: ILoginResponse = res.data;
                console.log(response);
                localStorage.setItem("token", response.token);

                // TODO: 有語系後再更改回來
                // if (!localStorage.getItem("lang")) {
                //   localStorage.setItem("lang", res.data.Language);
                //   i18n.changeLanguage(res.data.Language);
                // }

                instance.defaults.headers = {
                  Authorization:
                    "Bearer " + localStorage.getItem("token") || "",
                };

                if (Array.isArray(response.permissions)) {
                  var ciphertext = CryptoJS.AES.encrypt(
                    response.permissions.join(","),
                    process.env.REACT_APP_SECRET_KEY || "my-secret-key@123"
                  ).toString();
                  setPagePermission(response.permissions);
                  localStorage.setItem("permission", ciphertext);
                  history.history.replace(
                    getHomePath(routerWithPermission, response.permissions)
                  );
                }
              })
              .catch((err) => {
                console.log(err.response);
                let message: string;
                if (
                  err &&
                  err.response &&
                  err.response.data &&
                  err.response.data["ErrorCode"]
                ) {
                  // const errorCode = err.response.data["ErrorCode"];
                  // message = RequestErrorCode[errorCode as RequestErrorCode];
                  message = T("LoginFailed");
                } else if (err.response && err.response.status === 401) {
                  message = T("LoginFailed");
                } else {
                  message = T("DefaultErrorMessage");
                }
                setMessage(message);
                setSubmitting(true);
                resetForm();
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form style={{ marginTop: "20px" }}>
              {message && (
                <Alert
                  severity="error"
                  onClose={() => {
                    setMessage("");
                  }}
                >
                  {message}
                </Alert>
              )}
              <Field
                component={TextField}
                fullWidth
                label={T("Account")}
                name="playerID"
                title={T("RequiredHint")}
                required
              />
              <Field
                component={TextField}
                fullWidth
                label={T("Password")}
                name="password"
                title={T("RequiredHint")}
                required
                type="password"
              />
              {/* <Grid
                container
                style={{ marginTop: "20px" }}
                justify="space-between"
              >
                <Grid item>
                  <Link
                    className={classes.langSelector}
                    onClick={() => {
                      localStorage.setItem("lang", "en");
                      i18n.changeLanguage("en");
                    }}
                  >
                    English
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    className={classes.langSelector}
                    onClick={() => {
                      localStorage.setItem("lang", "zh-cht");
                      i18n.changeLanguage("zh-cht");
                    }}
                  >
                    繁體中文
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    className={classes.langSelector}
                    onClick={() => {
                      localStorage.setItem("lang", "zh-chs");
                      i18n.changeLanguage("zh-chs");
                    }}
                  >
                    简体中文
                  </Link>
                </Grid>
              </Grid> */}
              <Button
                className={classes.submit}
                color="primary"
                disabled={isSubmitting}
                fullWidth
                type="submit"
                variant="contained"
              >
                {isSubmitting ? (
                  <CircularProgress color="secondary" />
                ) : (
                  T("Sign In")
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Snackbar
        //錯誤提示
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
      >
        <MuiAlert elevation={6} severity="error" variant="filled">
          {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default Login;
