import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { IDeleteData } from "../../utils/common-type";
const useStyles = makeStyles((theme) => ({
  contain: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
    placeItems: "center",
  },
  icon: {
    fontSize: "5rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
}));

const Delete = <T extends Object>({
  data,
  open,
  // reRender,
  setOpen,
}: IDeleteData<T>) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        Delete
        <IconButton
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.contain}>
          <ErrorIcon color="secondary" className={classes.icon} />
          <Typography variant="h5" component="div">
            You will delete {data.length} data.
          </Typography>
          <Typography variant="h5" component="div">
            {" "}
            Are you sure?
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // Axios({
            //   method: deleteApi.method,
            //   url: deleteApi.url(),
            //   data: data.map((key: any) => key._id),
            // }).then((res) => {
            //   // if (reRender) reRender(Math.random());
            // });
            setOpen(false);
          }}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="default"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Delete;
