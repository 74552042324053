import { IBackstageLog } from "../../pages/backstage-management/backstage-log/type";
import { IClubServiceLog } from "../../pages/backstage-management/club-service-log/type";
import { ICustomerServiceLog } from "../../pages/backstage-management/customer-service-log/type";
import { ISystemConfigLog } from "../../pages/backstage-management/system-config-log/type";

export const fakeCustomServiceLog: ICustomerServiceLog[] = [
  {
    permission: "string",
    time: "2021-11-29 16:45:52",
    ip: "string",
    username: "string",
  },
];

export const fakeClubServiceLog: IClubServiceLog[] = [
  {
    permission: "string",
    time: "2021-11-29 16:45:52",
    ip: "string",
    username: "string",
  },
];

export const fakeSystemConfigLog: ISystemConfigLog[] = [
  {
    permission: "string",
    time: "2021-11-29 16:45:52",
    ip: "string",
    username: "string",
  },
];

export const fakeBackstageLog: IBackstageLog[] = [
  {
    permission: "string",
    time: "2021-11-29 16:45:52",
    ip: "string",
    username: "string",
  },
];
