import { Dialog, DialogTitle, DialogContent, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { IDetailDialogProps } from "../../utils/common-type";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
}));

const DetailDialog = ({
  children,
  customTitle,
  disableTypography = false,
  open,
  setOpen,
  titleStyle,
  title = "Detail",
  fullWidth = true,
  widthSize = "lg",
}: IDetailDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={setOpen} fullWidth={fullWidth} maxWidth={widthSize}>
      <DialogTitle
        disableTypography={disableTypography}
        style={titleStyle && titleStyle}
      >
        {customTitle ? customTitle : title}
        <IconButton className={classes.closeButton} onClick={setOpen}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ overflowX: "hidden" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DetailDialog;
