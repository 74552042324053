import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";

export interface ILobbyPlayerReport {
  fakeID: number;
  nickname: string;
  totalStoreAmount: number;
  electronicTotalBet: number;
  electronicTotalWin: number;
  competitiveTotalWinLose: number;
  competitiveTotalRoomFee: number;
  competitiveTotalRake: number;
}

export interface ICompetitiveReport {
  gameName: string;
  diamonds: number;
  winLose: number;
  rake: number;
  score: number;
  rooms: number;
}

export interface IFilter extends IBasicFilter {
  fakeID?: number;
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ILobbyPlayerReport[];
}

export const sortableColumns = [
  "fakeID",
  "totalStoreAmount",
  "electronicTotalBet",
  "electronicTotalWin",
  "competitiveTotalRoomFee",
  "competitiveTotalRake",
];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    fakeID: filter.fakeID,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  fakeID: undefined,
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
