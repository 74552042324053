import { Grid } from "@material-ui/core";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import { TimePicker, OneInput, FormButton } from "../../../common";
import { editorStateFromHTML } from "../mail/type";
import { IFormProps } from "./type";

const EditCreateForm = ({
  tempData,
  dialogType,
  setDialogType,
  onCreate,
  onEdit,
}: IFormProps) => {
  const [content, setContent] = useState(EditorState.createEmpty());
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`bulletin.${name}`);
  };

  useEffect(() => {
    if (dialogType && dialogType === "edit") {
      setContent(
        EditorState.createWithContent(editorStateFromHTML(tempData.content))
      );
    }
  }, [tempData.content, dialogType]);

  return (
    <Formik
      initialValues={tempData}
      onSubmit={(values, { setSubmitting }) => {
        console.log("edit create form on submit", values);
        if (dialogType == "create") {
          onCreate(values);
        } else if (dialogType === "edit") {
          onEdit(values);
        }

        setDialogType(null);
        setSubmitting(false);
      }}
      // TODO form validate
    >
      {({ submitForm, isSubmitting, resetForm, values, setFieldValue }) => (
        <Form style={{ width: "100%" }}>
          <Grid container spacing={2} justify="center">
            <OneInput
              type="text"
              name="title"
              label={T("title")}
              require
              size={{ xs: 12 }}
            />
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
              <Editor
                editorState={content}
                wrapperClassName="demo-wrapper"
                toolbarClassName="demo-toolbar"
                editorClassName="demo-editor"
                placeholder={T("content")}
                onEditorStateChange={(x: EditorState) => {
                  setContent(x);
                  setFieldValue(
                    "content",
                    draftToHtml(convertToRaw(x.getCurrentContent()))
                  );
                }}
              />
            </div>
            <TimePicker
              name="startTime"
              type="time"
              require
              label={T("startTime")}
              value={values.startTime}
              size={{ xs: 12 }}
              onChange={(e) => {
                setFieldValue("startTime", e);
              }}
            />
            <TimePicker
              name="endTime"
              type="time"
              require
              label={T("endTime")}
              value={values.endTime}
              size={{ xs: 12 }}
              onChange={(e) => {
                setFieldValue("endTime", e);
              }}
            />
          </Grid>
          <Grid container justify="center">
            <Grid
              item
              container
              xs={12}
              justify="flex-end"
              style={{
                paddingTop: 10,
              }}
            >
              <FormButton
                disable={isSubmitting}
                cancelEvent={() => {
                  resetForm();
                  // TODO check reset form value
                }}
                okEvent={submitForm}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default EditCreateForm;
