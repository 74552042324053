import moment from "moment";
import { filterEmptyFiled, getCurrentTimeZone } from "../../../../utils/helper";

export interface IPlatformReport {
  totalRevenue: number; // 大廳與俱樂部的營收加總，單位為台幣
  thirdPartyServiceFee: number; // 第三方服務費，單位為台幣
  storedValuePlayerCount: number; // 儲值玩家數，不計算重複玩家
  storedValueCount: number; // 儲值次數
  storedValueRatio: number; // 〔儲值玩家數 / 截止時間的玩家總數〕
  arpu: number; // 〔總營收 / 時間範圍內登入App的玩家數〕
  arppu: number; // 〔總營收 / 儲值玩家數〕
  firstStoredValueRevenue: number; // 第一次儲值的總額度，單位為台幣
  firstStoredValuePlayerCount: number; // 第一次儲值的玩家數
  firstStoredValueArppu: number; // 〔首儲營收 / 首儲玩家數〕
  totalPlayerCount: number; // 截止時間的玩家數
}

export interface ILobbyReport {
  lobbyDAU: number; // 每日登入App的平均玩家數
  lobbyRevenue: number; // 客服營收與第三方營收加總，單位為台幣
  customerServiceRevenue: number; // 玩家透過客服（從「玩家管理」頁面），用現金購買鑽石、金幣，單位為台幣
  thirdPartyRevenue: number; // 玩家透過IOS、Android，用現金購買鑽石、金幣，單位為台幣
  storedValuePlayerCount: number; // 儲值的玩家數，不計算重複玩家
  storedValueCount: number; // 玩家儲值次數
  storedValueRatio: number; // 〔儲值玩家數 / 截止時間的玩家總數〕
  electronicNetProfit: number; // Bet - Win總和，單位為金幣
  competitiveRake: number; // 〔 輸贏分 * 抽水比例〕，單位為金幣
  friendRoomDiamondCost: number; // 開好友房的花費，單位為鑽石
}

export const platformSelfFields = ["totalPlayerCount"];

export const lobbyGameFields = [
  "electronicNetProfit",
  "competitiveRake",
  "friendRoomDiamondCost",
];

export interface IClubReport {
  clubDAU: number; // 每日登入俱樂部的平均玩家數
  clubRevenue: number; // 會長透過客服（從「俱樂部管理」頁面），用現金購買鑽石、金幣，單位為台幣
  storedValueClubCount: number; // 儲值的俱樂部數，不計算重複俱樂部，不計算儲值積分的記錄
  storedValueCount: number; // 俱樂部儲值次數，不計算儲值積分的記錄
  storedValueRatio: number; // 〔儲值俱樂部數 / 截止時間的俱樂部總數〕
  electronicDiamodCost: number; // 租借電子遊戲機台的花費，單位為鑽石
  roomCreatedDiamodCost: number; // 競技遊戲開房的花費，單位為鑽石
  roomCreatedCount: number; // 競技遊戲開房數
  totalClubCount: number; // 截止時間的俱樂部總數
  totalClubPlayerCount: number; // 截止時間的俱樂部玩家數
  newClubCount: number; // 〔截止時間的俱樂部總數 - 起始時間的俱樂部總數〕
}

export const clubRelatedFields = {
  storedValue: [
    "clubDAU",
    "clubRevenue",
    "storedValueClubCount",
    "storedValueCount",
    "storedValueRatio",
  ],
  game: ["electronicDiamodCost", "roomCreatedDiamodCost", "roomCreatedCount"],
  club: ["totalClubCount", "totalClubPlayerCount", "newClubCount"],
};

export interface IRevenueReport {
  platform: IPlatformReport;
  lobby: ILobbyReport;
  club: IClubReport;
}

/*
TODO remove below after feature done
export interface ILobbyReport {
  storedValuePlayers: number;
  storedValueRatio: number;
  storedValueCounts: number;
  thirdPartyServiceFee: number;
  lobbyTotalStoredValue: number;
  arpu: number;
  arppu: number;
  firstStoredValuePlayers: number;
  totalFirstStoredValue: number;
  firstStoredValueArppu: number;
  lobbyElectronicRevenue: number;
  lobbyCompetitiveRevenue: number;
  lobbyFriendsRoomDiamondSpend: number;
}

export interface IIntegratedReport {
  lobbyDAU: number;
  storedValuePlayers: number;
  totalStoredValue: number;
  clubElectronicRevenue: number;
  totalRake: number;
  thirdPartyServiceFee: number;
  total: number;
}

export interface IClubReport {
  totalClub: number;
  clubPlayer: number;
  newClub: number;
  clubOwnerStoredValue: number;
  clubElectronicRake: number;
  rooms: number;
  diamondSpend: number;
  electronicRevenue: number;
}
*/

export interface IFilter {
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IRevenueReport;
}

export const sortableColumns = [];

export const buildFilter = (filter: IFilter) => {
  return filterEmptyFiled(filter);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
});
