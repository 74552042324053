import { makeStyles } from "@material-ui/core";
import React from "react";

export const useBusinessStatisticStyle = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    flexGrow: 1,
  },
  gridRow: {
    marginTop: 10,
    marginBottom: 10,
    flexGrow: 1,
    // marginBottom: 30,
  },
  gridItem: {
    margin: theme.spacing(1.5),
  },
  card: {
    padding: theme.spacing(1),
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    // flexGrow: 1,
    flexFlow: "column",
    minHeight: 100,
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 40,
    paddingLeft: 10,
    paddingRight: 10,
    color: "white",
    fontSize: 16,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    fontSize: 25,
    marginBottom: 10,
  },
  whiteTitle: {
    display: "inline-block",
    fontSize: 25,
    color: "white",
  },
  dataInfo: {
    fontSize: 16,
    marginTop: 5,
  },
  clubInfo: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  clubSelect: {
    marginLeft: theme.spacing(1.5),
  },
  riskInfoValue: {
    color: "white",
    textAlign: "right",
    fontSize: 40,
    marginTop: "5%",
    marginRight: "10%",
    textShadow: "0px 10px 10px #00000029",
    whiteSpace: "pre-wrap",
  },
  riskInfoTitle: {
    color: "white",
    fontSize: 18,
    marginBottom: "5%",
    marginLeft: "10%",
    textShadow: "0px 10px 10px #00000029",
    whiteSpace: "pre-wrap",
  },
  // colors
  lightPurple: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #D868E0 0%, #AC5BF5 100%) 0% 0% no-repeat padding-box",
  },
  blue: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #71CDFF 0%, #00A4EC 100%) 0% 0% no-repeat padding-box",
  },
  orange: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #FBB065 0%, #EB8900 100%) 0% 0% no-repeat padding-box",
  },
  green: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #72DE71 0%, #1CC41A 100%) 0% 0% no-repeat padding-box",
  },
  red: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #F38270 0%, #D22B30DB 100%) 0% 0% no-repeat padding-box",
  },
  purple: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #8590EF 0%, #5F3AF5 100%) 0% 0% no-repeat padding-box",
  },
  brown: {
    color: "white",
    background:
      "transparent linear-gradient(233deg, #D5865B 0%, #885337 100%) 0% 0% no-repeat padding-box",
  },
  darkBlue: {
    color: "white",
    background:
      "transparent linear-gradient(63deg, #043A78 0%, #0A7BDB 100%) 0% 0% no-repeat padding-box",
  },
  greenOpacity: {
    width: "100%",
    position: "relative",
    backgroundColor: "#86A1AB",
    padding: 15,
  },
  spinContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1000,
  },
  spinBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999,
    background: "white",
    opacity: 0.5,
  },
  loadingMask: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 999,
    background: "white",
    opacity: 0.5,
  },
  spin: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 10000,
  },
  lockedPlayersDiv: {
    borderRadius: 5,
    backgroundColor: "#D50D35",
    color: "white",
    padding: 5,
    marginLeft: 10,
  },
  redText: {
    color: "red",
  },
  singleModeGridRow: {
    width: "100%",
    display: "initial",
    maxWidth: "unset",
    flexBasis: "unset",
  },
  singleModeTreeSelect: {
    minWidth: "unset !important",
    width: "100%",
    wordBreak: "break-all",
  },
  singleModeResetWidth: {
    width: "unset !important",
  },
  singleModeFullWidth: {
    width: "100%",
  },
}));

export const colorStyles: { [className: string]: React.CSSProperties } = {
  lightPurple: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #D868E0 0%, #AC5BF5 100%) 0% 0% no-repeat padding-box",
  },
  blue: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #71CDFF 0%, #00A4EC 100%) 0% 0% no-repeat padding-box",
  },
  orange: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #FBB065 0%, #EB8900 100%) 0% 0% no-repeat padding-box",
  },
  green: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #72DE71 0%, #1CC41A 100%) 0% 0% no-repeat padding-box",
  },
  red: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #F38270 0%, #D22B30DB 100%) 0% 0% no-repeat padding-box",
  },
  purple: {
    color: "white",
    background:
      "transparent linear-gradient(262deg, #8590EF 0%, #5F3AF5 100%) 0% 0% no-repeat padding-box",
  },
  brown: {
    color: "white",
    background:
      "transparent linear-gradient(233deg, #D5865B 0%, #885337 100%) 0% 0% no-repeat padding-box",
  },
  darkBlue: {
    color: "white",
    background:
      "transparent linear-gradient(63deg, #043A78 0%, #0A7BDB 100%) 0% 0% no-repeat padding-box",
  },
};
