import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import {
  IDetailKey,
  IGameInfo,
  IPagedResponse,
  ISearchOptions,
} from "../../../../../utils/common-type";
import {
  dataTypeCheck,
  mapGameLang,
  unAuthorizationHandle,
} from "../../../../../utils/helper";
import { TemplateTable } from "../../../../layout";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  ILobbyElectronicGameReport,
  IElectronicGameReportSummary,
  initialSummary,
  sortableColumns,
} from "./type";

interface ITransactionHeader extends ILobbyElectronicGameReport {
  totalRevenue: number;
}

const LobbyElectronicGameReport = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<ILobbyElectronicGameReport[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [gameInfos, setGameInfos] = useState<IGameInfo[]>([]);
  const downloadData = useMemo(() => data, [data]);
  const [summary, setSummary] = useState(initialSummary);
  const initialFlag = useRef(true);
  const { t, i18n } = useTranslation();
  const T = (name: string) => t(`GameReport.${name}`);

  const header: IDetailKey<ITransactionHeader>[] = [
    {
      key: "gameID",
      name: T("gameID"),
      custom: (gameID) => mapGameLang(gameID, gameInfos, i18n.language),
    },
    { key: "totalBet", name: T("totalBet") },
    { key: "totalWin", name: T("totalWin") },
    {
      key: "totalRevenue",
      name: T("totalRevenue"),
      custom: (_, record) => {
        return dataTypeCheck(record.totalWin - record.totalBet);
      },
    },
    {
      key: "rtp",
      name: T("rtp"),
      custom: (_, record) => `${dataTypeCheck(record.rtp)}%`,
    },
    { key: "hitRate", name: T("hitRate") },
    { key: "betCount", name: T("betCount"), isInt: true },
    { key: "playerCount", name: T("playerCount"), isInt: true },
    {
      key: "jackpot",
      name: T("jackpot"),
      custom: (_, record) =>
        `${record.jackpot[0].count}/${record.jackpot[0].count}/${record.jackpot[0].count}/${record.jackpot[0].count}`,
    },
  ];

  useEffect(() => {
    instance
      .request<ISearchOptions>({
        method: api.getOptions.method,
        url: api.getOptions.url(),
      })
      .then((res) => {
        setGameInfos(res.data.gameInfos || []);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin]);

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ILobbyElectronicGameReport[]>>({
        method: api.getElectronicGameReport.method,
        url: api.getElectronicGameReport.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => ({
            ...d,
            totalBet: d.totalBet / 10000,
            totalWin: d.totalWin / 10000,
          }))
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  const searchSummary = (filter: IFilter) => {
    setSummary(initialSummary);
    instance
      .request<
        IElectronicGameReportSummary & {
          totalRevenue: number;
          avgRevenue: number;
        }
      >({
        method: api.getElectronicGameReportSummary.method,
        url: api.getElectronicGameReportSummary.url(),
        params: {
          ...buildFilter(filter),
          page: undefined,
          pageSize: undefined,
          order: undefined,
        },
      })
      .then((res) => {
        const data: IElectronicGameReportSummary = {
          ...res.data,
          totalRevenue: res.data.totalRevenue / 10000,
          avgRevenue: res.data.avgRevenue / 10000,
        };
        setSummary(
          summary.map((s) => ({
            ...s,
            value:
              s.key === "totalRtp"
                ? `${dataTypeCheck(data[s.key])}%`
                : data[s.key],
          }))
        );
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }
    onSearch();
  }, [onSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        data={downloadData}
        filter={filter}
        setFilter={setFilter}
        searchSummary={searchSummary}
        gameInfos={gameInfos}
      />
      <TemplateTable<ILobbyElectronicGameReport, IFilter>
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        summary={summary.map((s) => {
          return { key: T(s.key), value: s.value, isInt: s.isInt };
        })}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default LobbyElectronicGameReport;
