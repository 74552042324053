import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../environment";
import { instance } from "../../environment/axios";
import { DetailDialog, FormButton, OneInput } from "../common";
interface IChangePasswordProps {
  open: boolean;
  setOpen: any;
}

const ChangePassword = ({ open, setOpen }: IChangePasswordProps) => {
  // const auth = useContext(IsAuthorization);
  const [loading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };

  return (
    <DetailDialog
      open={open && !loading}
      setOpen={() => {
        setOpen(false);
      }}
      fullWidth={false}
      title={T("Change Password")}
    >
      <Formik
        initialValues={{
          OldPassword: "",
          NewPassword: "",
          ConfirmNewPassword: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.OldPassword) {
            errors.OldPassword = T("Required");
          }

          if (!values.NewPassword) {
            errors.NewPassword = T("Required");
          }

          if (!values.ConfirmNewPassword) {
            errors.ConfirmNewPassword = T("Required");
          }

          if (values.ConfirmNewPassword !== values.NewPassword) {
            errors.ConfirmNewPassword = T("PasswordNotMatch");
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          instance
            .request<any>({
              method: api.changePassword.method,
              url: api.changePassword.url(),
              data: {
                ...values,
              },
            })
            .then((res) => {
              localStorage.setItem("token", res.data.token);
              instance.defaults.headers = {
                Authorization: "Bearer " + res.data.token,
              };
              setOpen(false);
            })
            .catch((err: any) => {
              if (err && err.response && err.response.status === 401) {
                setMessage(T("Change password failed"));
              } else if (err && err.response && err.response.status === 400) {
                setMessage(T("Change password failed"));
              }
              // throw err;
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ submitForm, isSubmitting, resetForm, setFieldValue }) => {
          return (
            <Form style={{ width: "50vw", padding: 10 }}>
              <Grid container spacing={3} justify="center">
                {message && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setMessage("");
                    }}
                  >
                    {message}
                  </Alert>
                )}
              </Grid>
              <Grid container spacing={3} justify="center">
                <OneInput
                  type="password"
                  name="OldPassword"
                  label={T("Old Password")}
                  size={{ xs: 8 }}
                  onChange={(e) => {
                    setFieldValue("OldPassword", e.target.value);
                  }}
                />
                <OneInput
                  type="password"
                  name="NewPassword"
                  label={T("New Password")}
                  size={{ xs: 8 }}
                  onChange={(e) => {
                    setFieldValue("NewPassword", e.target.value);
                  }}
                />
                <OneInput
                  type="password"
                  name="ConfirmNewPassword"
                  label={T("Confirm New Password")}
                  size={{ xs: 8 }}
                  onChange={(e) => {
                    setFieldValue("ConfirmNewPassword", e.target.value);
                  }}
                />
              </Grid>
              <Grid container justify="flex-end">
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <FormButton
                    disable={isSubmitting}
                    okEvent={submitForm}
                    cancelEvent={() => {
                      resetForm();
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </DetailDialog>
  );
};

export default ChangePassword;
