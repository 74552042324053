import axios from "axios";
import { lowerFirst, upperFirst } from "lodash";
import { RequestErrorCodeEnum } from "../utils/common-type";

export const pascalCaseKeys = (object: any) => {
  return Object.entries(object).reduce((carry: any, [key, value]) => {
    carry[upperFirst(key)] = value;
    return carry;
  }, {});
};

export const camelCaseKeys = (object: any): any => {
  if (Array.isArray(object)) {
    return object.map((v) => camelCaseKeys(v));
  } else if (object && object !== null && object.constructor === Object) {
    return Object.keys(object).reduce((result, key) => {
      if (key === "Channels") {
        return {
          ...result,
          [lowerFirst(key)]: object[key],
        };
      } else {
        return {
          ...result,
          [key === "ID" ? "ID" : lowerFirst(key)]: camelCaseKeys(object[key]),
        };
      }
    }, {});
  }
  return object;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60 * 1000 * 60,
  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : "",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.params) {
      // 如果需要轉大寫再用下面這行
      // config.params = pascalCaseKeys(config.params);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = camelCaseKeys(response.data);
    }
    return response;
  },
  (error) => {
    console.log(error, error.response);
    let message = "";
    if (error && error.response && error.response.data["ErrorCode"]) {
      message =
        RequestErrorCodeEnum[
          error.response.data["ErrorCode"] as RequestErrorCodeEnum
        ];
    }
    // useAlert().addMessage({ text: message, type: "error" });
    return Promise.reject({
      ...error,
      message: { text: message, type: "error" },
    });
  }
);

export { instance };

// TODO remove below after changing all axios call from Axios to axios instance defined above
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = {
  Authorization: localStorage.getItem("token")
    ? "Bearer " + localStorage.getItem("token")
    : undefined,
};
