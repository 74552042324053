import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { generateFormItem, getTreeData } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { IWarningMessage } from "./type";

const Edit = (props: IEditProps<IWarningMessage>) => {
  const { t } = useTranslation();
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={
          props.data || {
            message: "玩家A於Game贏得了1000000分",
            riskConfig: "開獎金額提醒",
            time: "2020-10-10T08:08:08+08:00",
            status: "未解決",
            mark: "註記",
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "riskConfig",
                  label: "WarningMessage.riskConfig",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "message",
                  label: "WarningMessage.message",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "singleTime",
                  name: "time",
                  label: "common.time",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "status",
                  label: "WarningMessage.status",
                  treeData: getTreeData(["已解決", "未解決"], false),
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "mark",
                  label: "WarningMessage.mark",
                  size: { xs: 12 },
                },
              ],
              props.data,
              t
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Edit;
