import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../../../../app";
import { IDetailKey } from "../../../../../../../utils/common-type";
import { pageSizeList } from "../../../../../../../utils/helper";
import { fakeMarquee } from "../../../../../../fake-data/platform-management/marquee";
import { IAction, TemplateTable } from "../../../../../../layout";
import Create from "./create";
import Edit from "./edit";
import { defaultFilter, IFilter, IMarquee, sortableColumns } from "./type";
const ClubMarquee = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IMarquee[]>(fakeMarquee);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IMarquee>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Marquee.${name}`);
  };

  const header: IDetailKey<IMarquee>[] = [
    { key: "content", name: T("content") },
    { key: "frequency", name: T("frequency") },
    { key: "frequencyDetail", name: T("frequencyDetail") },
    { key: "createTime", name: T("createTime") },
  ];

  const action: IAction<IMarquee> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IMarquee) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
  };

  return auth.login ? (
    <>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="sm"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="sm"
      ></Create>
      <TemplateTable<IMarquee, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default ClubMarquee;
