import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { IAction, TemplateTable } from "../../../layout";
import Search from "./search";
import { defaultFilter, IFilter, IGiftRecord, sortableColumns } from "./type";

const GiftRecord = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IGiftRecord[]>([
    {
      id: "string",
      playerID: "string",
      giftedAccount: "string",
      gift: 100,
      handingCharge: 20,
      accountBalance: 80,
      giftedAccountBalance: 120,
      time: "2021-10-21T08:00:00+08:00",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`giftRecord.${name}`);

  const header: IDetailKey<IGiftRecord>[] = [
    { key: "id", name: T("ID") },
    { key: "playerID", name: T("playerID") },
    { key: "giftedAccount", name: T("giftedAccount") },
    { key: "gift", name: T("gift") },
    { key: "handingCharge", name: T("handingCharge") },
    { key: "accountBalance", name: T("accountBalance") },
    { key: "giftedAccountBalance", name: T("giftedAccountBalance") },
    { key: "time", name: t("common.Time") },
  ];
  const action: IAction<IGiftRecord> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        downloadData={downloadData}
        filter={filter}
        setFilter={setFilter}
      />
      <TemplateTable<IGiftRecord, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default GiftRecord;
