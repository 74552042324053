import { ISummary } from "../../../../../../layout";
import { IElectronicGameReportSummary } from "../../../../game-report/lobby-electronic-game-report/type";
export const sortableColumns = ["gameID", "totalBet", "totalWin", "betCount"];

export const initialSummary: ISummary<IElectronicGameReportSummary>[] = [
  {
    key: "totalRevenue",
    value: "-",
  },
  {
    key: "totalRtp",
    value: "-",
  },
  {
    key: "totalBetCount",
    value: "-",
    isInt: true,
  },
];

export const sortableElectronicColumns = [
  "gameID",
  "totalBet",
  "totalWin",
  "betCount",
];
