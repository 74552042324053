import { makeStyles } from "@material-ui/core";
import React, { CSSProperties } from "react";
const useStyles = makeStyles((theme) => ({
  card: {
    float: "left",
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    flexGrow: 1,
    flexFlow: "column",
    minHeight: 150,
    color: "white",
    background:
      "transparent linear-gradient(262deg, #8590EF 0%, #5F3AF5 100%) 0% 0% no-repeat padding-box",
  },
  cardTitle: {
    color: "white",
    fontSize: 25,
    marginBottom: 10,
  },
}));

const CustomCard: React.FunctionComponent<{
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  title?: string;
}> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.card} style={props.style}>
      <div className={classes.cardTitle}>{props.title}</div>
      {props.children}
    </div>
  );
};

export default CustomCard;
