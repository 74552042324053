import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateFormItem } from "../../../../utils/helper";
import { IFormProps } from "./type";

const EditCreateForm = ({
  tempData,
  dialogType,
  permissions,
  onCreate,
  onEdit,
}: IFormProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`roleManagement.${name}`);
  };

  return (
    <Formik
      initialValues={tempData}
      onSubmit={(values, { setSubmitting }) => {
        console.log("edit create form on submit", values);
        if (dialogType == "create") {
          onCreate(values);
        } else if (dialogType === "edit") {
          onEdit(values);
        }

        setSubmitting(false);
      }}
    // TODO form validate
    >
      {(form) => (
        <Form style={{ width: "100%" }}>
          {generateFormItem(
            form,
            [

              {
                type: "text",
                name: "name",
                label: "roleName",
                disable: dialogType === "edit" ? true : false,
                size: { xs: 12 },
              },
              {
                type: "multiSelect",
                name: "permissions",
                label: "permissions",
                treeData: [{ title: t("common.all"), value: "all", key: "all", children: permissions }],
                size: { xs: 12 },
              },
            ],
            tempData,
            T
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EditCreateForm;
