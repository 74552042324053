import {
  GameStatusEnum,
  IGame,
} from "../../pages/platform-management/game-management/type";

export const fakeGame: IGame[] = [
  {
    gameID: "Game A",
    name: {
      zh_cht: "Name A",
    },
    url: "Url A",
    introduction: {
      zh_cht: "Introduction A",
    },
    tags: [],
    icon: "Icon A",
    type: 0,
    serverType: 0,
    defaultSetting: "{}",
    extra: "{}",
    rule: "Rule A",
    lobbyStatus: GameStatusEnum.enable,
    clubStatus: GameStatusEnum.disable,
    priority: 0,
  },
];
