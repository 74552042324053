import { Tag } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { LinkText } from "../../../common";
import { fakeSuggestion } from "../../../fake-data/custom-service/suggestion";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Create from "./create";
import Edit from "./edit";
import Search from "./search";
import {
  defaultFilter,
  getPriorityColor,
  IFilter,
  ISuggestion,
  sortableColumns,
} from "./type";

const Suggestion = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<ISuggestion[]>(fakeSuggestion);
  const [downloadData] = useState<any>(fakeSuggestion);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<ISuggestion>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Suggestion.${name}`);
  };

  const header: IDetailKey<ISuggestion>[] = [
    { key: "suggestionID", name: T("suggestionID") },
    { key: "type", name: T("type") },
    {
      key: "playerID",
      name: T("playerID"),
      custom: (playerID: string, source: ISuggestion) => {
        return (
          <LinkText
            onClick={() => {
              setIsActionOpen(true);
              setActionName("accountInfo");
              setDetail(source);
            }}
          >
            {playerID}
          </LinkText>
        );
      },
    },
    { key: "status", name: T("status") },
    {
      key: "priority",
      name: T("priority"),
      custom: (priority) => (
        <Tag color={getPriorityColor(`${priority}`)}>{priority}</Tag>
      ),
    },
    { key: "createdAt", name: T("createdAt") },
    { key: "assignedUser", name: T("assignedUser") },
  ];

  const action: IAction<ISuggestion> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: ISuggestion) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
  };

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await instance.request<CGReconciliationOptions>({
  //       url: api.CGReconciliationOptions.url(),
  //       method: api.CGReconciliationOptions.method,
  //     });
  //     if (!data) return;

  //     if (data.gameIDs && Object.keys(data.gameIDs).length) {
  //       setGameList(data.gameIDs);
  //     }

  //     if (data.gameInfos && Object.keys(data.gameInfos).length) {
  //       setGameInfos(data.gameInfos);
  //     }
  //   })();
  // }, []);

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="md"
      ></Create>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="md"
      ></Edit>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize="xs"
        action={actionName}
      />
      <TemplateTable<ISuggestion, IFilter>
        action={action}
        data={[...originData]}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default Suggestion;
