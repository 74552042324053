import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../../../app";
import { fakeMemberList } from "../../../../../fake-data/club-service/member.-list";

const MemberInfo = () => {
  const auth = useContext(IsAuthorization);
  const { t } = useTranslation();
  const [member] = useState(fakeMemberList[0]);
  const T = (name: string) => {
    return t(`MemberList.${name}`);
  };

  return auth.login ? (
    <>
      <ul>
        <li>
          {T("playerID")}: {member.playerID}
        </li>
        <li>
          {T("nickname")}: {member.nickname}
        </li>
        <li>
          {T("agent")}: {member.agent}
        </li>
        <li>
          {T("clubID")}: {member.clubID}
        </li>
        <li>
          {T("credit")}: {member.credit}
        </li>
        <li>
          {T("joinTime")}: {member.joinTime}
        </li>
        <li>
          {T("lastLoginTime")}: {member.lastLoginTime}
        </li>
        <li>
          {T("point")}: {member.point}
        </li>
        <li>
          {T("status")}: {member.status}
        </li>
      </ul>
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default MemberInfo;
