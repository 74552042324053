import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  generateSearchFormItem,
  getGameTreeData,
} from "../../../../../utils/helper";
import { SearchAccordion } from "../../../../common";
import { defaultFilter, ISearchProps } from "./type";

const Search = ({
  filter,
  data,
  setFilter,
  searchSummary,
  gameInfos,
}: ISearchProps) => {
  const { t, i18n } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        enableReinitialize={true}
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          setFilter({ ...values, pages: 0 });
          searchSummary(values);
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "common.Time",
                },
                {
                  type: "multiSelect",
                  name: "gameIDs",
                  label: "GameReport.gameID",
                  treeData: getGameTreeData(gameInfos, i18n.language),
                },
              ],
              defaultFilter(),
              t,
              data,
              "Lobby Competitive Game Report",
              true
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
