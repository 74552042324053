import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export interface IGiftRecord {
  id: string;
  playerID: string;
  giftedAccount: string;
  gift: number;
  handingCharge: number;
  accountBalance: number;
  giftedAccountBalance: number;
  time: string;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  id: string;
  playerID: string;
  giftedAccount: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  downloadData: IGiftRecord[];
}

export const sortableColumns = [
  "ID",
  "playerID",
  "giftedAccount",
  "gift",
  "handingCharge",
  "time",
];

export const defaultFilter: () => IFilter = () => ({
  id: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  playerID: "",
  giftedAccount: "",
});
