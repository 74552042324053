import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetailKey, IEditProps } from "../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
  pageSizeList,
} from "../../../../utils/helper";
import { Card } from "../../../common";
import { CustomDialog } from "../../../common";
import { fakeBannedStatus } from "../../../fake-data/platform-management/player";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import {
  IPlayer,
  IPlayerBannedTable,
  PlayerStatusEnum,
  PlayerTagEnum,
} from "./type";

const Edit = (props: IEditProps<IPlayer>) => {
  const [bannedStatusLoading] = useState(false);
  const [isBannedHaveNextPage] = useState(false);
  const { t } = useTranslation();
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [actionWidthSize, setActionWidthSize] =
    useState<IEditProps<any>["widthSize"]>("lg");
  const T = (name: string) => {
    return t(`PlayerManagement.${name}`);
  };

  const header: IDetailKey<IPlayerBannedTable>[] = [
    { key: "banned", name: T("banned") },
    { key: "time", name: T("time") },
    { key: "expiredTime", name: T("expiredTime") },
  ];

  const action: IAction<IPlayerBannedTable> = {
    create: true,
    del: false,
    edit: false,
    onCreate: () => {
      setActionWidthSize("xs");
      setIsActionOpen(true);
      setActionName("CreateBannedStatus");
    },
    custom: [
      {
        name: "封鎖紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: () => {
          setActionWidthSize("md");
          setIsActionOpen(true);
          setActionName("BannedStatusRecord");
        },
        position: "TOP",
      },
    ],
  };

  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "id",
                  label: "id",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "nickname",
                  label: "nickname",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "gender",
                  label: "gender",
                  disable: true,
                  size: { xs: 12 },
                  treeData: getTreeData(["男", "女"], false),
                },
                {
                  type: "number",
                  name: "level",
                  label: "level",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "status",
                  label: "status",
                  size: { xs: 12 },
                  treeData: getTreeData(mapEnumToList(PlayerStatusEnum), false),
                },
                {
                  type: "singleSelect",
                  name: "tag",
                  label: "tag",
                  size: { xs: 12 },
                  treeData: getTreeData(mapEnumToList(PlayerTagEnum), false),
                },
                {
                  type: "multiSelect",
                  name: "bannedGame",
                  label: "bannedGame",
                  size: { xs: 12 },
                  treeData: getTreeData(["Game1", "Game2"]),
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>

      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Card title="俱樂部A" style={{ marginRight: 10 }}>
          <ul>
            <li>俱樂部ID: Club A</li>
            <li>積分: 100000</li>
            <li>所屬代理: agent1</li>
            <li>貢獻積分: 100</li>
            <li>推廣積分: 100</li>
            <li>加入時間: 2021-12-2 15:43:00</li>
            <li>狀態: 正常</li>
          </ul>
        </Card>
        <Card title="俱樂部B" style={{ marginRight: 10 }}>
          <ul>
            <li>俱樂部ID: Club A</li>
            <li>積分: 100000</li>
            <li>所屬代理: agent1</li>
            <li>貢獻積分: 100</li>
            <li>推廣積分: 100</li>
            <li>加入時間: 2021-12-2 15:43:00</li>
            <li>狀態: 正常</li>
          </ul>
        </Card>
        <Card title="俱樂部C" style={{ marginRight: 10 }}>
          <ul>
            <li>俱樂部ID: Club A</li>
            <li>積分: 100000</li>
            <li>所屬代理: agent1</li>
            <li>貢獻積分: 100</li>
            <li>推廣積分: 100</li>
            <li>加入時間: 2021-12-2 15:43:00</li>
            <li>狀態: 正常</li>
          </ul>
        </Card>
      </div>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={props.data}
        widthSize={actionWidthSize || "md"}
        action={actionName}
      />
      <div
        style={{
          fontSize: 22,
          marginTop: 20,
          marginBottom: 10,
          borderBottom: "1px solid",
        }}
      >
        封鎖狀態
      </div>
      <TemplateTable
        action={action}
        data={fakeBannedStatus}
        header={header}
        rowsPerPage={pageSizeList}
        filter={{ pages: 0, pageSize: 10 }}
        setFilter={() => {}}
        reloading={bannedStatusLoading}
        sort={[]}
        toolbar
        summary={[]}
        isNextPageHaveData={isBannedHaveNextPage}
        pagination={false}
      />
    </CustomDialog>
  );
};

export default Edit;
