import {
  IMember,
  MemberStatusEnum,
} from "../../pages/club-service/member-list/type";

export const fakeMemberList: IMember[] = [
  {
    playerID: "playerID",
    nickname: "nickname",
    clubID: "ClubA",
    agent: "string",
    point: 100,
    credit: 1000,
    joinTime: "2021-10-22T00:00:00+08:00",
    lastLoginTime: "2021-10-22T00:00:00+08:00",
    status: MemberStatusEnum.offline,
  },
  {
    playerID: "playerID",
    nickname: "nickname",
    clubID: "ClubA",
    agent: "string",
    point: 100,
    credit: 1000,
    joinTime: "2021-10-22T00:00:00+08:00",
    lastLoginTime: "2021-10-22T00:00:00+08:00",
    status: MemberStatusEnum.locked,
  },
  {
    playerID: "playerID",
    nickname: "nickname",
    clubID: "ClubA",
    agent: "string",
    point: 100,
    credit: 1000,
    joinTime: "2021-10-22T00:00:00+08:00",
    lastLoginTime: "2021-10-22T00:00:00+08:00",
    status: MemberStatusEnum.online,
  },
];
