import React from "react";
import { Form, Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  defaultFilter,
  ISearchProps,
  PlayerStatusEnum,
  PlayerTagEnum,
} from "./type";
import { SearchAccordion } from "../../../common";
import {
  generateSearchFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const Search = ({ filter, setFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          initialValues={filter}
          onSubmit={(values, { setSubmitting }) => {
            setFilter({ ...values, pages: 0 });
            setSubmitting(false);
          }}
        >
          {(form) => (
            <Form style={{ width: "100%" }}>
              {generateSearchFormItem(
                form,
                [
                  {
                    type: "time",
                    name: "time",
                    label: "PlayerManagement.createdAt",
                  },
                  {
                    type: "text",
                    name: "id",
                    label: "PlayerManagement.id",
                  },
                  {
                    type: "multiSelect",
                    name: "status",
                    label: "PlayerManagement.status",
                    treeData: getTreeData(mapEnumToList(PlayerStatusEnum)),
                  },
                  {
                    type: "text",
                    name: "nickname",
                    label: "PlayerManagement.nickname",
                  },
                  {
                    type: "text",
                    name: "level",
                    label: "PlayerManagement.level",
                  },
                  {
                    type: "multiSelect",
                    name: "clubID",
                    label: "PlayerManagement.clubIDs",
                    treeData: getTreeData(["Club1", "Club2"]),
                  },
                  {
                    type: "multiSelect",
                    name: "tag",
                    label: "PlayerManagement.tag",
                    treeData: getTreeData(mapEnumToList(PlayerTagEnum)),
                  },
                ],
                defaultFilter(),
                t
              )}
            </Form>
          )}
        </Formik>
      </MuiPickersUtilsProvider>
    </SearchAccordion>
  );
};

export default Search;
