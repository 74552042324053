import { Grid, InputLabel } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ICreateProps,
  IFormItem,
} from "../../../../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../../../../utils/helper";
import { CustomDialog } from "../../../../../../common";
import { formInitialValues, IMarquee, MarqueeModeEnum } from "./type";
import { TimePicker } from "antd";

const Create = (props: ICreateProps<IMarquee>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Marquee.${name}`);
  };

  const getRepeatSelection = (frequency: MarqueeModeEnum): IFormItem[] => {
    if (frequency === MarqueeModeEnum.once) {
      return [
        {
          type: "singleTime",
          name: "frequencyDetail",
          label: "frequencyDetail",
          size: { xs: 12 },
        },
      ];
    } else {
      return [
        {
          type: "singleSelect",
          name: "repeatDay",
          label: "repeatDay",
          treeData: getTreeData(["1", "2", "3", "4", "5", "6", "7"], false),
          size: { xs: 12 },
        },
        {
          type: "custom",
          name: "repeatTime",
          label: "repeatTime",
          render: (
            <>
              <Grid xs={8}>
                <InputLabel required={true} shrink={true}>
                  {T("repeatTime")}
                </InputLabel>
                <TimePicker format="HH:mm" />
              </Grid>
            </>
          ),
          size: { xs: 12 },
        },
      ];
    }
  };

  return (
    <CustomDialog title={t("common.create")} {...props}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "singleSelect",
                  name: "frequency",
                  label: "frequency",
                  treeData: getTreeData(mapEnumToList(MarqueeModeEnum), false),
                  size: { xs: 12 },
                },
                ...getRepeatSelection(form.values.frequency),
              ],
              formInitialValues,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default Create;
