import { Button, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { ISuggestion, SuggestionTypeEnum } from "./type";

const Edit = (props: IEditProps<ISuggestion>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Suggestion.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "playerID",
                  label: "playerID",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "status",
                  label: "status",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "suggestionID",
                  label: "suggestionID",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "type",
                  label: "type",
                  treeData: getTreeData(
                    mapEnumToList(SuggestionTypeEnum),
                    false
                  ),
                  size: { xs: 12 },
                },
                {
                  type: "content",
                  name: "content",
                  label: "content",
                  disable: true,
                  size: { xs: 12 },
                  style: {
                    marginTop: 10,
                  },
                },
                {
                  type: "content",
                  name: "replay",
                  label: "replay",
                  size: { xs: 12 },
                  style: {
                    marginTop: 10,
                  },
                },
                {
                  type: "singleTime",
                  name: "createdAt",
                  label: "createdAt",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "assignedUser",
                  label: "assignedUser",
                  size: { xs: 6 },
                  treeData: getTreeData(["user1", "user2"], false),
                },
                {
                  type: "custom",
                  name: "readStatus",
                  label: "ReadStatus",
                  render: (
                    <Grid
                      container
                      item
                      xs={2}
                      justify="flex-start"
                      alignItems="flex-end"
                      key={"assignMeButton"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          form.setFieldValue(
                            "assignedUser",
                            localStorage.getItem("username")
                          );
                        }}
                      >
                        {T("assignMe")}
                      </Button>
                    </Grid>
                  ),
                },
                {
                  type: "text",
                  name: "mark",
                  label: "mark",
                  size: { xs: 12 },
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Edit;
