import moment from "moment";
import React from "react";
import { IBasicFilter, IGameInfo } from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";

export interface ITransactionRound {
  transactionID: string;
  clubsID: number;
  roundCount: number;
  gameID: string;
  playersID: number;
  fakeID: number;
  bet: number;
  win: number; // 實際贏分
  winLose: number; // 輸贏分
  revenue: number; // 輸贏分 - gameDetail.Rake
  ruleID: number;
  roomID: string;
  status: string;
  createdAt: string;
  gameDetail: IGameDetail;
}

export interface IGameDetail {
  list: number[];
  rake: number;
  roomFee: number;
}

export interface IFilter extends IBasicFilter {
  transactionID: string;
  fakeID?: string;
  gameIDs: string[];
  clubsID?: string;
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  fixedFilter?: Partial<IFilter>;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  gameInfos: IGameInfo[];
}

export interface ITransactionRoundDetail {
  transactionID: string;
  gameID: string;
  playersID: number;
  fakeID: number;
  bet: number;
  win: number;
  ruleID: number;
  describe: string;
  status: string;
  createdAt: string;
  gameDetailsID: number;
  rake: number;
  roomID: string;
  roundID: number;
  score: number;
}

export const detailSortableColumns = ["time"];

export const sortableColumns = ["time", "gameID", "roomID", "fakeID", "rake"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    isElectronic: false, // 遊戲類型
    clubsID: 0, // 大廳的俱樂部ID為0
    transactionID: filter.transactionID,
    fakeID: filter.fakeID,
    gameIDs: filter.gameIDs.join(","),
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    pageSize: filter.pageSize,
    page: filter.pages,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  transactionID: "",
  fakeID: "",
  gameIDs: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
