import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICreateProps } from "../../../../utils/common-type";
import { generateFormItem, getTreeData } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { formInitialValues, IUser } from "./type";

const Create = (props: ICreateProps<IUser>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`UserManagement.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={formInitialValues}
        validate={(values) => {
          const error: any = {};
          if (values.confirmPassword && values.confirmPassword !== "") {
            if (values.confirmPassword !== values.password) {
              error.confirmPassword = "與密碼不相符";
            }
          }
          console.log(values);
          return error;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "username",
                  label: "username",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "nickname",
                  label: "nickname",
                  size: { xs: 12 },
                },
                {
                  type: "password",
                  name: "password",
                  label: "password",
                  size: { xs: 12 },
                },
                {
                  type: "password",
                  name: "confirmPassword",
                  label: "confirmPassword",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "roleID",
                  label: "roleID",
                  treeData: getTreeData(
                    ["Owner", "PM", "GM", "Club Owner"],
                    false
                  ),
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "clubID",
                  label: "clubID",
                  treeData: getTreeData(["Club1", "Club2"], false),
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "status",
                  label: "status",
                  treeData: getTreeData(["activated", "locked"], false),
                  size: { xs: 12 },
                },
              ],
              formInitialValues,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default Create;
