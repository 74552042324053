import { makeStyles } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IsAuthorization } from "../../../../../app";
import { IDetailKey } from "../../../../../utils/common-type";
import {
  fakeOnlineAnalysis,
  fakeOnlineChartData,
} from "../../../../fake-data/report-management/online-analysis";
import { IAction, TemplateTable } from "../../../../layout";
import Detail from "./detail";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  IOnlineAnalysis,
  sortableColumns,
} from "./type";
const useStyles = makeStyles((theme) => ({
  chartDiv: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    marginTop: 10,
    marginBottom: 20,
    boxShadow: theme.shadows[1],
  },
  title: {
    fontSize: 25,
    marginBottom: 10,
  },
}));
const OnlineAnalysis = () => {
  const style = useStyles();
  const auth = useContext(IsAuthorization);
  const [data] = useState<any>(fakeOnlineAnalysis);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detail, setDetail] = useState<IOnlineAnalysis>({} as any);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`OnlineAnalysis.${name}`);

  const header: IDetailKey<IOnlineAnalysis>[] = [
    { key: "date", name: T("date") },
    { key: "highestTime", name: T("highestTime") },
    { key: "highestTimeTotal", name: T("highestTimeTotal") },
    { key: "total", name: T("total"), isInt: true },
  ];
  const action: IAction<IOnlineAnalysis> = {
    create: false,
    del: false,
    edit: false,
    read: true,
    onRead: (onlineAnalysis: IOnlineAnalysis) => {
      setIsDetailOpen(true);
      setDetail(onlineAnalysis);
    },
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Detail setOpen={setIsDetailOpen} open={isDetailOpen} data={detail} />
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <div className={style.chartDiv}>
        <div className={style.title}>期間平均流量分析</div>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={730}
            height={250}
            data={fakeOnlineChartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              label={{
                value: T("hour"),
                position: "insideBottomCenter",
                dy: 20,
              }}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="玩家人數"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <TemplateTable<IOnlineAnalysis, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default OnlineAnalysis;
