import React, { useEffect, useState } from "react";
import packageJson from "../../../package.json";

interface IAppVersionProps {
  isVisible?: boolean;
}

const AppVersion = ({ isVisible = true }: IAppVersionProps) => {
  const [appVersion, setAppVersion] = useState<string>();
  const [masterVersion, setMasterVersion] = useState<string>();

  useEffect(() => {
    setAppVersion(`version: v${packageJson.version}`);
    setMasterVersion(
      `master: ${packageJson.version.replace(/(\w+).(\w+).(\w+)/, "$1.$2.0")}`
    );
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        left: 10,
      }}
      hidden={!isVisible}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "gray",
        }}
      >
        {masterVersion}
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "gray",
        }}
      >
        {appVersion}
      </div>
    </div>
  );
};

export default AppVersion;
