import { Grid, InputLabel } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getTreeData, mapEnumToList } from "../../../../../../utils/helper";
import { TreeSelect } from "antd";
import { DatePicker } from "antd";

import { PlayerBannedStatusEnum } from "../../type";
import { BannedGameTimeEnum } from "./type";
import { FormButton } from "../../../../../common";

const CreateBannedStatus = () => {
  const [selectBannedGame, setSelectBannedGame] = useState("Game 1");
  const [selectBannedTime, setSelectBannedTime] = useState(
    BannedGameTimeEnum.oneDay
  );
  const [selectBannedStatus, setSelectBannedStatus] = useState(
    PlayerBannedStatusEnum.app
  );

  const [time, setTime] = useState(moment());
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`PlayerManagement.${name}`);
  };

  const getTimeSelect = (banned: string) => {
    switch (banned) {
      case PlayerBannedStatusEnum.chat:
      case PlayerBannedStatusEnum.gift:
      case PlayerBannedStatusEnum.app: {
        return (
          <>
            <Grid>
              <InputLabel
                shrink={true}
                // style={{ color: error ? "red" : "initial" }}
              >
                {T("expiredTime")}
              </InputLabel>
              <DatePicker
                showTime
                allowClear={false}
                value={time}
                onChange={(e: any) => {
                  setTime(e);
                }}
              />
            </Grid>
          </>
        );
      }
      case PlayerBannedStatusEnum.game: {
        return (
          <>
            <Grid style={{ marginRight: 10 }}>
              <InputLabel shrink={true}>
                {t("GameManagement.gameID")}
              </InputLabel>
              <TreeSelect
                value={selectBannedGame}
                multiple={false}
                onChange={(v) => {
                  setSelectBannedGame(v);
                }}
                treeData={getTreeData(
                  ["Game 1", "Game 2", "Game 3", "Game 4"],
                  false
                )}
              />
            </Grid>
            <Grid>
              <InputLabel shrink={true}>{T("expiredTime")}</InputLabel>
              <TreeSelect
                value={selectBannedTime}
                multiple={false}
                onChange={(v) => {
                  setSelectBannedTime(v);
                }}
                treeData={getTreeData(mapEnumToList(BannedGameTimeEnum), false)}
              />
            </Grid>
          </>
        );
      }
    }
  };

  return (
    <Grid container justify="center">
      <Grid item>
        <InputLabel shrink={true}>{T("banned")}</InputLabel>
        <TreeSelect
          value={selectBannedStatus}
          multiple={false}
          onChange={(v) => {
            setSelectBannedStatus(v);
          }}
          treeData={getTreeData(mapEnumToList(PlayerBannedStatusEnum), false)}
        />
      </Grid>
      <Grid container justify="center" item style={{ marginTop: 10 }}>
        {getTimeSelect(selectBannedStatus)}
      </Grid>
      <Grid style={{ marginTop: 10 }}>
        <FormButton disable={false} cancelEvent={() => {}} okEvent={() => {}} />
      </Grid>
    </Grid>
  );
};

export default CreateBannedStatus;
