import { IClub } from "../../pages/club-service/club-management/type";

export const fakeClub: IClub[] = [
  {
    id: "club id",
    name: "club name",
    ownerUID: "owner uid",
    ownerPlayerUID: "owner player uid",
    ownerName: "owner name",
    totalPoints: 1000,
    level: 1,
    members: 95,
    memberLimit: 100,
    avatar: "",
    ownerEmail: "abc123@gmail.com",
    ownerPhone: "0912345678",
    openGames: [],
    rules: [],
    diamonds: 100,
    accDiamonds: 1000,
  },
];
