import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface IButton {
  cancelEvent: (e: any) => void;
  cancelName?: string;
  cancelVariant?: "text" | "outlined" | "contained" | undefined;
  disable: boolean;
  disableCancel?: boolean;
  okEvent: (e: any) => void;
  okName?: string;
  okVariant?: "text" | "outlined" | "contained" | undefined;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  gridButton: {
    padding: theme.spacing(1.5),
  },
}));

const FormButton = ({
  cancelEvent,
  cancelName = "reset",
  cancelVariant = "contained",
  disable,
  disableCancel = false,
  okEvent,
  okName = "submit",
  okVariant = "contained",
}: IButton) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };
  return (
    <React.Fragment>
      <Button
        className={classes.button}
        color="primary"
        disabled={disable}
        onClick={(e: any) => okEvent(e)}
        variant={okVariant}
      >
        {T(okName)}
      </Button>
      <Button
        className={classes.button}
        color="default"
        disabled={disableCancel}
        onClick={(e: any) => cancelEvent(e)}
        variant={cancelVariant}
      >
        {T(cancelName)}
      </Button>
    </React.Fragment>
  );
};

export default FormButton;
