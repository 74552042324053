import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetailKey } from "../../../../../../utils/common-type";
import { pageSizeList } from "../../../../../../utils/helper";
import { fakeBannedStatusRecord } from "../../../../../fake-data/platform-management/player";
import { IAction, TemplateTable } from "../../../../../layout";
import { IPlayerBannedStatusRecord } from "./../../type";

const BannedStatusRecord = () => {
  const [bannedStatusLoading] = useState(false);
  const [isBannedHaveNextPage] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`PlayerManagement.${name}`);
  };

  const header: IDetailKey<IPlayerBannedStatusRecord>[] = [
    { key: "banned", name: T("banned") },
    { key: "action", name: T("action") },
    { key: "time", name: t("common.time") },
  ];

  const action: IAction<IPlayerBannedStatusRecord> = {
    create: false,
    del: false,
    edit: false,
  };

  return (
    <TemplateTable
      action={action}
      data={fakeBannedStatusRecord}
      header={header}
      rowsPerPage={pageSizeList}
      filter={{ pages: 0, pageSize: 10 }}
      setFilter={() => {}}
      reloading={bannedStatusLoading}
      sort={[]}
      toolbar
      summary={[]}
      isNextPageHaveData={isBannedHaveNextPage}
    />
  );
};

export default BannedStatusRecord;
