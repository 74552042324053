import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../../../../app";
import { IDetailKey } from "../../../../../../../utils/common-type";
import { pageSizeList } from "../../../../../../../utils/helper";
import { fakeGameActive } from "../../../../../../fake-data/platform-management/active-games";
import { IAction, TemplateTable } from "../../../../../../layout";
import Create from "./create";
import Edit from "./edit";
import { defaultFilter, IActiveGames, IFilter } from "./type";
const ActiveGames = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IActiveGames[]>(fakeGameActive);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IActiveGames>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`ActiveGames.${name}`);
  };

  const header: IDetailKey<IActiveGames>[] = [
    { key: "gameID", name: T("gameID") },
    { key: "ruleID", name: T("ruleID") },
    {
      key: "rake",
      name: T("rake"),
      custom: (data) => {
        if (!data) return "-";
        return data;
      },
    },
  ];

  const action: IAction<IActiveGames> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IActiveGames) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
  };

  return auth.login ? (
    <>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="sm"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="sm"
      ></Create>
      <TemplateTable<IActiveGames, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default ActiveGames;
