import React from "react";
import { CustomDialog } from "../../../../../common";
import CompetitiveReport from "./competitive-game-report";
import ElectronicReport from "./electronic-game-report";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const getAction = (action: string) => {
    switch (action) {
      case "electronic": {
        return (
          <ElectronicReport
            fakeID={props.data?.fakeID}
            zone={props.defaultFilter.zone}
            time={props.defaultFilter.time}
          />
        );
      }
      case "competitive": {
        return (
          <CompetitiveReport
            fakeID={props.data?.fakeID}
            zone={props.defaultFilter.zone}
            time={props.defaultFilter.time}
          />
        );
      }
    }
  };

  return (
    <CustomDialog title={`${props.title}`} {...props}>
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
