import React from "react";
import { Form, Formik } from "formik";
import { defaultFilter, ISearchProps, MemberStatusEnum } from "./type";
import { SearchAccordion } from "../../../common";
import {
  generateSearchFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { useTranslation } from "react-i18next";

const Search = ({ filter, selectionList, setFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          setFilter({ ...values, pages: 0 });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "MemberList.joinTime",
                },
                {
                  type: "multiSelect",
                  name: "club",
                  label: "MemberList.clubID",
                  treeData: getTreeData(selectionList["club"]),
                },
                {
                  type: "multiSelect",
                  name: "status",
                  label: "MemberList.status",
                  treeData: getTreeData(mapEnumToList(MemberStatusEnum)),
                },
                {
                  type: "text",
                  name: "playerID",
                  label: "MemberList.playerID",
                },
              ],
              defaultFilter(),
              t
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
