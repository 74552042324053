import moment from "moment";
import React from "react";
import {
  IBasicFilter,
  IGameInfo,
  IPopup,
} from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";
import { ITransaction } from "../lobby-electronic-game-record/type";

export interface IFilter extends IBasicFilter {
  transactionID?: string;
  roundID?: string;
  fakeID?: string;
  gameIDs?: string[];
  clubsID?: string;
  time?: moment.Moment[];
  zone?: string;
}

export interface ISearchProps extends IPopup {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ITransaction[];
  gameInfos: IGameInfo[];
}

export const sortableColumns = [
  "gameID",
  "clubID",
  "fakeID",
  "bet",
  "win",
  "time",
];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    isElectronic: true, // 遊戲類型
    transactionID: filter.transactionID,
    roundID: filter.roundID,
    fakeID: filter.fakeID,
    gameIDs: filter.gameIDs?.join(","),
    clubsID: filter.clubsID,
    startTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[0], filter.zone)
        : undefined,
    endTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[1], filter.zone)
        : undefined,
    pageSize: filter.pageSize,
    page: filter.pages,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  transactionID: "",
  roundID: "",
  fakeID: "",
  gameIDs: [],
  clubsID: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
