import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  generateSearchFormItem,
  getGameTreeData,
} from "../../../../../utils/helper";
import { SearchAccordion } from "../../../../common";
import { defaultFilter, ISearchProps } from "./type";

const Search = ({
  filter,
  fixedFilter,
  data,
  setFilter,
  gameInfos = [],
}: ISearchProps) => {
  const { t, i18n } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        enableReinitialize={true}
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          setFilter({ ...values, pages: 0, ...fixedFilter });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "common.Time",
                },
                {
                  type: "text",
                  name: "transactionID",
                  label: "LobbyElectronicGameRecord.transactionID",
                  disable: Boolean(
                    form.values.fakeID !== "" || form.values.gameIDs?.length
                  ),
                },
                {
                  type: "number",
                  name: "roundID",
                  label: "LobbyElectronicGameRecord.roundID",
                  disable: form.values.transactionID === "",
                },
                {
                  type: "multiSelect",
                  name: "gameIDs",
                  label: "LobbyElectronicGameRecord.gameID",
                  treeData: getGameTreeData(gameInfos, i18n.language),
                  disable: form.values.transactionID !== "",
                },
                {
                  type: "number",
                  name: "fakeID",
                  label: "LobbyElectronicGameRecord.fakeID",
                  disable: form.values.transactionID !== "",
                },
              ].filter((v) => {
                // 過濾掉固定的搜尋條件
                return !(
                  fixedFilter &&
                  Object.keys(fixedFilter).find((key) => key === v.name)
                );
              }) as any,
              defaultFilter(),
              t,
              data
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
