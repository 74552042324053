import { Grid, useMediaQuery } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  colorStyles,
  useBusinessStatisticStyle,
} from "../../../../../assets/styles/business-statistic";
import { dataTypeCheck } from "../../../../../utils/helper";
import { deviceData01 } from "../../../../fake-data/business-statistics";
import { CustomPieChart } from "../../common-component";
import DataColorInfo from "../../common-component/data-color-info";
import DataInfo from "../../common-component/data-info";

interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlatformInfo: React.FC<Props> = () => {
  const [fakeLobbyData] = useState({
    customRevenue: 1005412,
    thirdRevenue: 1005412,
    electronicBet: 1005412,
    electronicRevenue: 1005412,
    competitiveRevenue: 1005412,
    friendsRoom: 1005412,
    loginPlayers: 1005,
  });
  const [fakeClubData] = useState({
    clubRevenue: 1005412,
    electronicRevenue: 1005412,
    electronicDiamonds: 1005412,
    roomDiamonds: 1005412,
    loginPlayers: 1005,
  });
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalClubs, setTotalClubs] = useState(0);
  const intFields = ["loginPlayers"];
  const classes = useBusinessStatisticStyle();
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  // fake loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setTotalClubs(200);
      setTotalMembers(1000);
    }, 3000);
  }, []);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <Grid
      container
      className={`${classes.gridRow} ${
        isSingleMode && classes.singleModeGridRow
      }`}
    >
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("todayLobbyData")}
            titleStyle={colorStyles.lightPurple}
            data={Object.entries(fakeLobbyData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("todayClubData")}
            titleStyle={colorStyles.blue}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("lastDayLobbyData")}
            titleStyle={colorStyles.orange}
            data={Object.entries(fakeLobbyData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("lastDayClubData")}
            titleStyle={colorStyles.green}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("thisMonthLobbyData")}
            titleStyle={colorStyles.red}
            data={Object.entries(fakeLobbyData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("thisMonthClubData")}
            titleStyle={colorStyles.purple}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFields.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>

      <Grid xs={isSingleMode ? 12 : 3} item container>
        <Grid xs={12} item container>
          <DataInfo
            title={T("totalMembers")}
            number={totalMembers}
            loading={loading}
          />
          <DataInfo
            title={T("totalClubs")}
            number={totalClubs}
            loading={loading}
          />
        </Grid>
        <CustomPieChart
          title={T("useDevices")}
          chartData={deviceData01}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default PlatformInfo;
