import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import ClubPriority from "./club-priority";
import LobbyPriority from "./lobby-priority";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`GameManagement.${props.action}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "lobbyPriority":
        return <LobbyPriority allGames={props.allGames} />;
      case "clubPriority":
        return <ClubPriority allGames={props.allGames} />;
    }
  };

  return (
    <CustomDialog title={`${T(props.action)}`} {...props}>
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
