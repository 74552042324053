import {
  IPointsRecord,
  PointsRecordEnum,
} from "../../pages/club-service/points-record/type";

export const fakePointsRecord: IPointsRecord[] = [
  {
    playerID: "player1",
    type: PointsRecordEnum.agent,
    gameID: "",
    agent: "agent1",
    pointsInOut: 100,
    remainPoints: 1000,
    time: "2021-12-2 09:48:00",
  },
  {
    playerID: "player1",
    type: PointsRecordEnum.game,
    gameID: "Game1 玩法1",
    agent: "",
    pointsInOut: -100,
    remainPoints: 900,
    time: "2021-12-2 09:47:00",
  },
  {
    playerID: "player1",
    type: PointsRecordEnum.agent,
    gameID: "",
    agent: "agent1",
    pointsInOut: 500,
    remainPoints: 1400,
    time: "2021-12-2 09:46:00",
  },
];
