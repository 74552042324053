import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../../../app";

const PlayerInfo = () => {
  const auth = useContext(IsAuthorization);
  const { t } = useTranslation();
  const [playerID] = useState({});
  const T = (name: string) => {
    return t(`PlayerManagement.${name}`);
  };

  const getAccountInfoList = () => {
    return Object.entries(playerID).map(([key, value], index) => {
      return (
        <li key={key + index}>
          {T(key)}: {value}
        </li>
      );
    });
  };

  return auth.login ? (
    <>
      <ul>{getAccountInfoList()}</ul>
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default PlayerInfo;
