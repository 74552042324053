import _ from "lodash";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { api } from "../../../../environment";
import { instance } from "../../../../environment/axios";
import {
  IDetailKey,
  IPagedResponse,
  ITreeNodes,
} from "../../../../utils/common-type";
import { unAuthorizationHandle } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { IAction, TemplateTable } from "../../../layout";
import EditCreateForm from "./edit-create-form";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  formInitialValues,
  IFilter,
  IRole,
  IRoleForm,
  sortableColumns,
} from "./type";

const RoleManagement = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<IRole[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [dialogType, setDialogType] = useState<string | null>(null);
  const [tempData, setTempData] = useState<IRoleForm>(formInitialValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const { t } = useTranslation();
  const initialFlag = useRef(true);
  const T = (name: string) => t(`roleManagement.${name}`);

  const header: IDetailKey<IRole>[] = [
    { key: "name", name: T("roleName") },
    { key: "createdAt", name: T("createdAt") },
    { key: "updatedAt", name: T("updatedAt") },
  ];

  const action: IAction<IRole> = {
    create: true,
    edit: true,
    del: false,
    onCreate: () => {
      setDialogType("create");
      setTempData(cloneDeep(formInitialValues));
    },
    onEdit: (data: IRole) => {
      setDialogType("edit");
      const { ...editableFields } = data;
      setTempData(Object.assign(editableFields));
    },
  };

  // 將權限陣列轉成階層 Nested Object
  const mapPermissions = useCallback(
    (permissions: string[], parentPath: string = "") => {
      const permissionMap: any = {};
      permissions.forEach((permission) => {
        let path = parentPath;
        const p = permission.split("/");
        if (p.length === 1) {
          permissionMap[p[0]] = `${path}${p[0]}`;
        } else if (p.length > 1) {
          const parent = p.shift() as string;
          path += `${parent}/`;
          permissionMap[`${parentPath}${parent}`] = _.merge(
            {},
            permissionMap[`${parentPath}${parent}`],
            mapPermissions([p.join("/")], path)
          );
        }
      });
      return permissionMap;
    },
    []
  );

  // 將 Nested Object 轉換成 Tree Data
  const mapPermissionsToTreeData = useCallback(
    (permissionMap: Object): ITreeNodes[] => {
      const tree: ITreeNodes[] = Object.entries(permissionMap).map(
        ([key, value]) => {
          if (typeof value !== "string") {
            return {
              title: t(`Page.${key}`),
              value: key,
              key,
              children: mapPermissionsToTreeData(value),
            };
          } else {
            return {
              title: t(`Page.${value}`),
              value: value,
              key: value,
            };
          }
        }
      );
      return tree;
    },
    [t]
  );

  const permissionsTreeData = useMemo(() => {
    return mapPermissionsToTreeData(mapPermissions(auth.pagePermission));
  }, [auth.pagePermission, mapPermissions, mapPermissionsToTreeData]);

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<IRole[]>>({
        method: api.getRole.method,
        url: api.getRole.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => {
            return {
              ...d,
              createdAt: moment(d.createdAt).format(),
              updatedAt: moment(d.updatedAt).format(),
            };
          })
        );
        if (roles.length === 0) {
          setRoles(data.map((d) => d.name));
        }
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter, roles.length]);

  const onEdit = (values: IRoleForm) => {
    instance
      .request<IPagedResponse<IRole[]>>({
        method: api.editRole.method,
        url: api.editRole.url(),
        data: values,
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        onSearch();
        setDialogType(null);
      });
  };

  const onCreate = (values: IRoleForm) => {
    instance
      .request<IPagedResponse<IRole[]>>({
        method: api.createRole.method,
        url: api.createRole.url(),
        data: values,
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        onSearch();
        setDialogType(null);
      });
  };

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onSearch();
  }, [onSearch]);

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        roles={roles}
        permissions={permissionsTreeData}
      />
      <CustomDialog
        open={dialogType !== null}
        title={dialogType ? t(`common.${dialogType}`) : ""}
        setOpen={() => {}}
        onClose={() => {
          setDialogType(null);
        }}
      >
        <EditCreateForm
          tempData={tempData}
          permissions={permissionsTreeData}
          dialogType={dialogType}
          setDialogType={setDialogType}
          onCreate={onCreate}
          onEdit={onEdit}
        />
      </CustomDialog>
      <TemplateTable<IRole, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default RoleManagement;
