import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { generateFormItem, getTreeData } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { formInitialValues, IGameRiskConfig } from "./type";

const Create = (props: IEditProps<IGameRiskConfig>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`RiskConfig.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "singleSelect",
                  name: "gameID",
                  label: "gameID",
                  treeData: getTreeData(
                    ["Game1", "Game2", "Game3", "Game4"],
                    false
                  ),
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "repeatFreeSlotCount",
                  label: "repeatFreeSlotCount",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "bigWin",
                  label: "bigWin",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "bigWinRate",
                  label: "bigWinRate",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "jackpot",
                  label: "jackpot",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "rtp",
                  label: "rtp",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "netProfit",
                  label: "netProfit",
                  size: { xs: 12 },
                },
              ],
              formInitialValues,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Create;
