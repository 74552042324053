import { cloneDeep } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { IAction, TemplateTable } from "../../../layout";
import EditCreateForm from "./edit-create-form";
import Search from "./search";
import {
  defaultFilter,
  formInitialValues,
  IEvent,
  IEventForm,
  IFilter,
  sortableColumns,
} from "./type";

const Event = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IEvent[]>([
    {
      title: "string",
      content: "string",
      reward: "string",
      startTime: "2020-01-01T10:20:20+08:00",
      endTime: "2020-01-01T10:20:20+08:00",
      createTime: "2020-01-01T10:20:20+08:00",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [dialogType, setDialogType] = useState<string | null>(null);
  const [tempData, setTempData] = useState<IEventForm>(formInitialValues);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`Event.${name}`);

  const header: IDetailKey<IEvent>[] = [
    { key: "title", name: T("title") },
    { key: "content", name: T("content") },
    { key: "reward", name: T("reward") },
    { key: "startTime", name: T("startTime") },
    { key: "endTime", name: T("endTime") },
    { key: "createTime", name: T("createTime") },
  ];

  const action: IAction<IEvent> = {
    create: true,
    edit: true,
    del: false,
    onCreate: () => {
      setDialogType("create");
      setTempData(cloneDeep(formInitialValues));
    },
    onEdit: (data: IEvent) => {
      setDialogType("edit");
      setTempData({
        title: data.title,
        startTime: moment(data.startTime),
        endTime: moment(data.endTime),
      });
    },
  };

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        downloadData={downloadData}
      />
      <CustomDialog
        open={dialogType !== null}
        title={dialogType ? t(`common.${dialogType}`) : ""}
        setOpen={() => {}}
        onClose={() => {
          setDialogType(null);
        }}
      >
        <EditCreateForm
          tempData={tempData}
          dialogType={dialogType}
          setDialogType={setDialogType}
          onCreate={() => {}}
          onEdit={() => {}}
        />
      </CustomDialog>
      <TemplateTable<IEvent, IFilter>
        action={action}
        data={data}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default Event;
