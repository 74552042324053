import { Button } from "antd";
import React from "react";

interface Props {
    onClick: React.MouseEventHandler<HTMLElement>;
}

const LinkText: React.FunctionComponent<Props> = (props) => {
    return (
        <Button
            type="link"
            size="small"
            onClick={props.onClick}
            style={{ margin: 0, padding: 0, border: 0 }}
        >
            <span style={{ textDecoration: "underline", }}>
                {props.children}
            </span>
        </Button>
    );
};

export default LinkText;
