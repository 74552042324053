import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
} from "../../../../utils/helper";

export interface IPlayer {
  id: string;
  level: number;
  clubID: string[];
  status: string;
  tag: string[];
  gem: number;
  coin: number;
  point: number;
  createdAt: string;
  lastLoginAt: string;
  avatar: string;
  nickname: string;
  gender: GenderEnum;
  introduction: string;
  experience: number;
  totalCharge: number;
  items: string[];
  extraSetting: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VoiceLanguage: number;
  };
}

export enum PlayerBannedStatusEnum {
  app = "不可進入App",
  game = "不可遊玩某遊戲",
  gift = "不可贈禮",
  chat = "不可發言",
}

export enum PlayerBannedActionEnum {
  banned = "禁止",
  unblock = "解除",
}

export enum PlayerStatusEnum {
  normal = "正常",
  locked = "停用",
  banned = "禁用",
  chat = "禁言",
}

export enum PlayerTagEnum {
  loss = "輸多",
  win = "贏多",
  huge = "超大戶",
  big = "大戶",
  normal = "一般",
  money = "洗錢",
  dealer = "幣商",
}

export enum GenderEnum {
  未知 = 0,
  男 = 1,
  女 = 2,
}

export interface IPlayerBannedTable {
  banned: string;
  time: string;
  expiredTime: string;
}

export interface IPlayerBannedStatusRecord {
  banned: string;
  action: PlayerBannedActionEnum;
  time: string;
}

export const formInitialValues: IPlayer & any = {
  id: "string",
  level: 0,
  clubID: [],
  status: "string",
  tag: [],
  gem: 0,
  coin: 0,
  point: 0,
  createdAt: "string",
  lastLoginAt: "string",
  avatar: "string",
  nickname: "string",
  gender: 0,
  introduction: "string",
  experience: 0,
  totalCharge: 0,
  items: [],
};

export const sortableColumns = [
  "uid",
  "status",
  "tag",
  "diamondBalance",
  "goldBalance",
];

export interface IFilter extends IBasicFilter {
  uid: string;
  nickname: string;
  status: string[];
  tag: string[];
  level: string;
  clubID: string[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    uid: filter.uid,
    nickname: filter.nickname,
    clubID: filter.clubID.join(),
    level: filter.level,
    status: filter.status.join(),
    tag: filter.tag,
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  uid: "",
  nickname: "",
  level: "",
  tag: [],
  clubID: [],
  status: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
