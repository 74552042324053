import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IDetailEdit } from "../../../../../utils/common-type";
import { DetailDialog } from "../../../../common";
import { fakeOnlineChartData } from "../../../../fake-data/report-management/online-analysis";
import { IOnlineAnalysis } from "./type";
const useStyles = makeStyles((theme) => ({
  chartDiv: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    marginTop: 10,
    marginBottom: 20,
    boxShadow: theme.shadows[1],
  },
  title: {
    fontSize: 25,
    marginBottom: 10,
  },
}));

const Detail = ({ data, open, setOpen }: IDetailEdit<IOnlineAnalysis>) => {
  const { t } = useTranslation();
  const style = useStyles();
  const T = (name: string) => {
    return t(`OnlineAnalysis.${name}`);
  };
  return (
    <DetailDialog
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={data.date}
    >
      <div className={style.chartDiv}>
        <div className={style.title}>期間平均流量分析</div>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            width={730}
            height={250}
            data={fakeOnlineChartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              label={{
                value: T("hour"),
                position: "insideBottomCenter",
                dy: 20,
              }}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="玩家人數"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </DetailDialog>
  );
};
export default Detail;
