import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Button } from "antd";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { LinkText } from "../../../common";
import { fakePointDetail } from "../../../fake-data/club-service/points-detail";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Search from "./search";
import { defaultFilter, IFilter, IPointDetail } from "./type";

const PointsDetail = ({ popup = false }: { popup?: boolean }) => {
  const auth = useContext(IsAuthorization);
  const [detail, setDetail] = useState<IPointDetail>({} as any);
  const [originData, setOriginData] = useState<IPointDetail[]>(fakePointDetail);
  const [downloadData] = useState<any>(fakePointDetail);
  const [loading] = useState<boolean>(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isNextPageHaveData] = useState(false);
  const [filter, setFilter] = useState<IFilter>(cloneDeep(defaultFilter()));
  const [agentHistory, setAgentHistory] = useState<string[]>([]);

  let action: IAction<IPointDetail> = {
    create: false,
    del: false,
    edit: false,
    custom: [
      {
        name: "積分紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (member: IPointDetail) => {
          setDetail(member);
          setIsActionOpen(true);
          setActionName("pointsRecord");
        },
        position: "IN_ROW",
      },
    ],
  };

  const { t } = useTranslation();

  const T = (name: string) => {
    return t(`PointsDetail.${name}`);
  };

  const header: IDetailKey<IPointDetail>[] = [
    {
      key: "uid",
      name: T("UID"),
      custom: (UID: string) => {
        return (
          <LinkText
            onClick={() => {
              if (!agentHistory.includes(UID)) {
                setAgentHistory([...agentHistory, UID]);
              }
              setFilter(cloneDeep(Object.assign(filter, { agent: UID })));
            }}
          >
            {UID}
          </LinkText>
        );
      },
    },
    { key: "agent", name: T("agent") },
    { key: "round", name: T("round"), isInt: true },
    { key: "winLose", name: T("winLose") },
    { key: "totalPromotionPoints", name: T("totalPromotionPoints") },
    { key: "totalContributionPoints", name: T("totalContributionPoints") },
    { key: "totalTransferPoints", name: T("totalTransferPoints") },
    { key: "total", name: T("total") },
  ];

  // mock filter
  useEffect(() => {
    setOriginData(
      fakePointDetail.filter((v) => v.agent.includes(filter.agent))
    );
  }, [filter]);

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
        popup={popup}
      />
      <div style={{ padding: 5 }}>
        {agentHistory.length != 0 && (
          <span style={{ marginRight: "10px" }}>
            {t("PointsDetail.history")}
          </span>
        )}
        {agentHistory.map((agent: string) => {
          return (
            <Button
              key={`${agent}link`}
              size="middle"
              type="dashed"
              onClick={() => {
                if (agentHistory.length === 1) {
                  setAgentHistory([]);
                  setFilter(cloneDeep(Object.assign(filter, { agent: "" })));
                } else {
                  setFilter(cloneDeep(Object.assign(filter, { agent })));
                  setAgentHistory(
                    agentHistory.slice(
                      0,
                      agentHistory.findIndex((v) => v === agent) + 1
                    )
                  );
                }
              }}
            >
              {agent}
            </Button>
          );
        })}
      </div>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize="md"
        action={actionName}
      />
      <TemplateTable<IPointDetail, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        reloading={loading}
        sort={["uid", "agent", "round", "winLose", "reward", "total"]}
        toolbar={false}
        filter={filter}
        setFilter={setFilter}
        isNextPageHaveData={isNextPageHaveData}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default PointsDetail;
