import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { generateFormItem } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { IClub } from "./type";

const Edit = (props: IEditProps<IClub>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`ClubManagement.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "ID",
                  label: "ID",
                  size: { xs: 12 },
                  disable: true,
                },
                {
                  type: "text",
                  name: "avatar",
                  label: "avatar",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "name",
                  label: "name",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "ownerUID",
                  label: "ownerUID",
                  size: { xs: 12 },
                  disable: true,
                },
                {
                  type: "text",
                  name: "ownerPlayerUID",
                  label: "ownerPlayerUID",
                  size: { xs: 12 },
                  disable: true,
                },
                {
                  type: "text",
                  name: "ownerName",
                  label: "ownerName",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "ownerEmail",
                  label: "ownerEmail",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "ownerPhone",
                  label: "ownerPhone",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "totalPoints",
                  label: "totalPoints",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "level",
                  label: "level",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "memberLimit",
                  label: "memberLimit",
                  size: { xs: 12 },
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default Edit;
