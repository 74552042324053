import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { IAction, TemplateTable } from "../../../layout";
import Edit from "./edit";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  IWarningMessage,
  sortableColumns,
  WarningMessageStatusEnum,
} from "./type";

const WarningMessage = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IWarningMessage[]>([
    {
      id: "FE9684164854",
      message: "玩家A於Game贏得了1000000分",
      riskConfig: "開獎金額提醒",
      time: "2020-10-10T08:08:08+08:00",
      status: WarningMessageStatusEnum.已解決,
      mark: "註記",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [detail, setDetail] = useState<IWarningMessage>();
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`WarningMessage.${name}`);

  const header: IDetailKey<IWarningMessage>[] = [
    { key: "riskConfig", name: T("riskConfig") },
    { key: "message", name: T("message") },
    { key: "time", name: t("common.Time") },
    { key: "status", name: T("status") },
  ];
  const action: IAction<IWarningMessage> = {
    create: false,
    del: false,
    edit: true,
    onEdit: (data: IWarningMessage) => {
      setIsEditOpen(true);
      setDetail(data);
    },
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="xs"
      ></Edit>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<IWarningMessage, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default WarningMessage;
