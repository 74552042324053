import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { generateFormItem } from "../../../../utils/helper";
import { fakeSystemConfig } from "../../../fake-data/platform-management/system-config";
const useStyles = makeStyles((theme) => ({
  divWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  chartDiv: {
    width: "60%",
    minHeight: 500,
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    marginTop: 60,
    marginBottom: 20,
    boxShadow: theme.shadows[1],
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 25,
    marginBottom: 20,
  },
}));

const SystemConfig = () => {
  const auth = useContext(IsAuthorization);
  const classes = useStyles();
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`SystemConfig.${name}`);
  };

  return auth.login ? (
    <>
      <div className={classes.divWrapper}>
        <div className={classes.chartDiv}>
          <Formik
            initialValues={fakeSystemConfig}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {(form) => (
              <Form style={{ width: "100%" }}>
                <div className={classes.title}>系統參數</div>
                {generateFormItem(
                  form,
                  [
                    {
                      type: "number",
                      name: "maxClubLevel",
                      label: "maxClubLevel",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "maxClubMembers",
                      label: "maxClubMembers",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "maxClubPoints",
                      label: "maxClubPoints",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "maxPlayerJoinClubs",
                      label: "maxPlayerJoinClubs",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "maxClubAgent",
                      label: "maxClubAgent",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "lobbyFriendsRoomDiamond",
                      label: "lobbyFriendsRoomDiamond",
                      size: { xs: 12 },
                    },
                    {
                      type: "number",
                      name: "clubRoomDiamond",
                      label: "clubRoomDiamond",
                      size: { xs: 12 },
                    },
                  ],
                  fakeSystemConfig,
                  T
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  ) : (
    <Redirect to="/login" />
  );
};

export default SystemConfig;
