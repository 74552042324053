import moment from "moment";
import React from "react";
import { IBasicFilter, IGameInfo } from "../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";

export interface ITransaction {
  transactionID: string;
  roundID: number;
  clubsID: number;
  gameID: string;
  playersID: number; // 玩家真實 ID
  fakeID: number; // 玩家顯示 ID
  bet: number;
  win: number; // 實際贏分
  gameBet: number;
  gameWin: number; // 遊戲贏分
  ruleID: number;
  roomID: string;
  describe: string;
  status: string;
  createdAt: string;
  rake: number; // 抽水 (gameWin - win)
  winLose: number; // 輸贏分 (game win - bet)
  revenue: number; // 實際輸贏分 (win - bet)
  gameDetail: IGameDetail;
}

export interface IGameDetail {
  List: number[];
  Rake: number;
  RoomFee: number;
}

export interface IFilter extends IBasicFilter {
  transactionID: string;
  roundID?: string;
  fakeID?: string;
  gameIDs: string[];
  time: moment.Moment[];
  zone: string;
  gameDetail?: string;
}

export interface ISearchProps {
  filter: IFilter;
  fixedFilter?: Partial<IFilter>;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ITransaction[];
  gameInfos: IGameInfo[];
}

export const sortableColumns = ["time", "gameID", "playerID", "bet", "win"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    isElectronic: true, // 遊戲類型
    clubsID: 0, // 大廳的俱樂部ID為0
    transactionID: filter.transactionID,
    roundID: filter.roundID,
    fakeID: filter.fakeID,
    gameIDs: filter.gameIDs?.join(","),
    startTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[0], filter.zone)
        : undefined,
    endTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[1], filter.zone)
        : undefined,
    pageSize: filter.pageSize,
    page: filter.pages,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  transactionID: "",
  fakeID: "",
  roundID: "",
  gameIDs: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
