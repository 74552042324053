import { Card } from "antd";
import React from "react";

interface Props {
  loading?: boolean;
  title: string | JSX.Element;
  content: string | JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const GiftInfo: React.FC<Props> = ({ title, content, loading = false }) => {
  return (
    <Card loading={loading}>
      <div style={{ fontSize: 16, margin: 15, fontWeight: "bold" }}>
        {" "}
        {title}
      </div>
      <div style={{ fontSize: 16, margin: 15 }}>{content}</div>
    </Card>
  );
};
export default GiftInfo;
