import { Table, TableColumnsType } from "antd";
import React from "react";
import { IDetailEdit } from "../../../../../../utils/common-type";
import { DetailDialog } from "../../../../../common";

interface Props extends IDetailEdit<any> {
  loading?: boolean;
  tableHeader: TableColumnsType<any>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const RankDetail: React.FC<Props> = ({
  data,
  tableHeader,
  open,
  setOpen,
  widthSize,
  title,
  loading = false,
}) => {
  return (
    <DetailDialog
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={title}
      widthSize={widthSize}
    >
      <Table
        dataSource={data}
        loading={loading}
        key="rank-table-detail"
        pagination={false}
        columns={tableHeader}
        rowKey={(record) => record.playerID}
      />
    </DetailDialog>
  );
};
export default RankDetail;
