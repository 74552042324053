import React from "react";
import { IEditProps } from "../../../../utils/common-type";
import { CustomDialog } from "../../../common";
import { IConsumeRecord } from "./type";
import { makeStyles, Typography } from "@material-ui/core";
import { fakeProductItem } from "../../../fake-data/consume/consume-record";
import { dataTypeCheck } from "../../../../utils/helper";

const useStyles = makeStyles(() => ({
  itemDetail: {
    fontSize: 16,
    marginTop: 5,
  },
}));

// eslint-disable-next-line @typescript-eslint/naming-convention
const Detail = (props: IEditProps<IConsumeRecord>) => {
  const classes = useStyles();

  return (
    <CustomDialog title={`${props.title}`} {...props}>
      {fakeProductItem.length > 0 &&
        fakeProductItem.map((item) => {
          return (
            <div className={classes.itemDetail} key={item.itemId}>{`${
              item.itemName
            }: ${dataTypeCheck(item.amount)}`}</div>
          );
        })}
      {fakeProductItem.length <= 0 && (
        <Typography variant="h5" component="div">
          {props.data?.product}
        </Typography>
      )}
    </CustomDialog>
  );
};
export default Detail;
