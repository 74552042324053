import { Grid, useMediaQuery } from "@material-ui/core";
import { TableColumnsType } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Line } from "recharts";
import { chartColors } from "../../../../../utils/helper";
import Rank1 from "../../../../../assets/images/rank-1.png";
import Rank2 from "../../../../../assets/images/rank-2.png";
import Rank3 from "../../../../../assets/images/rank-3.png";
import {
  fakeFullCompetitiveRank,
  fakeFullElectronicRank,
  fakeFullLobbyGiftRank,
  fakeMonthlyRevenue,
} from "../../../../fake-data/business-statistics";
import { CustomLineChart, Rank } from "../../common-component";
import DateRangeSelector from "../../common-component/date-range-selector";
import { DateRangeEnum } from "../type";
import GiftInfo from "./gift-info";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";

interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlayerData: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [selectedPlayerDataDateRange, setSelectedPlayerDataDateRange] =
    useState(DateRangeEnum.今日);
  const [playerDataTime, setPlayerDataTime] = useState<any>([
    moment().startOf("days"),
    moment().endOf("days"),
  ]);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);
  const rankIcons = [Rank1, Rank2, Rank3];

  const lobbyElectronicRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const lobbyCompetitiveRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const clubElectronicRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const clubCompetitiveRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const lobbyGiftRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      align: "center",
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "giftGolds",
      title: T("giftGolds"),
      align: "center",
    },
  ];

  const lobbyRankProps = [
    {
      tabName: T("electronic"),
      title: `${T("lobbyElectronicRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullElectronicRank,
      tableHeader: lobbyElectronicRankHeader,
    },
    {
      tabName: T("competitive"),
      title: `${T("lobbyCompetitiveRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullCompetitiveRank,
      tableHeader: lobbyCompetitiveRankHeader,
    },
  ];

  const clubRankProps = [
    {
      tabName: T("electronic"),
      title: `${T("clubElectronicRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullElectronicRank,
      tableHeader: clubElectronicRankHeader,
    },
    {
      tabName: T("competitive"),
      title: `${T("clubCompetitiveRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullCompetitiveRank,
      tableHeader: clubCompetitiveRankHeader,
    },
  ];

  const giftRankProps = [
    {
      title: `${T("giftRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullLobbyGiftRank,
      tableHeader: lobbyGiftRankHeader,
    },
  ];

  const isDateLaterThanOneDay = (
    date1: moment.Moment,
    date2: moment.Moment
  ) => {
    return !moment(date1).add(1, "days").isAfter(moment(date2));
  };

  // fake loading
  useEffect(() => {
    setPlayerDataLoading(true);
    setTimeout(() => {
      setPlayerDataLoading(false);
    }, 3000);
  }, [playerDataTime]);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <div className={`${classes.card} ${classes.greenOpacity}`}>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center">
          <div
            className={`${classes.whiteTitle} ${
              isSingleMode && classes.singleModeFullWidth
            }`}
          >{`${T("playerData")}`}</div>
          <DateRangeSelector
            selectedDateRange={selectedPlayerDataDateRange}
            setSelectedDateRange={setSelectedPlayerDataDateRange}
            dateRange={playerDataTime}
            setDateRange={setPlayerDataTime}
            datePickerOnChange={(e: any) => {
              setSelectedPlayerDataDateRange(DateRangeEnum.無);
              setPlayerDataTime([e[0], moment(e[1]).endOf("days")]);
            }}
          />
        </Grid>
        {
          // 贈送交易量及次數
          // 大於一日範圍用圖表顯示，否則使用純數據顯示
          isDateLaterThanOneDay(playerDataTime[0], playerDataTime[1]) ? (
            <Grid item xs={12}>
              <CustomLineChart
                title={`${moment().format("M")}${T("giftStatistics")}`}
                loading={playerDataLoading}
                chartData={fakeMonthlyRevenue.slice(
                  playerDataTime[0].date() - 1,
                  playerDataTime[1].date()
                )}
                chartLine={[
                  <Line
                    key={"giftGolds"}
                    name={"giftGolds"}
                    type="monotone"
                    dataKey="totalRevenue"
                    stroke={chartColors[0]}
                  />,
                  <Line
                    key={"giftTimes"}
                    name={"giftTimes"}
                    type="monotone"
                    dataKey="lobbyRevenue"
                    stroke={chartColors[1]}
                  />,
                ]}
              />
            </Grid>
          ) : (
            <>
              {
                // 贈送交易量
              }
              <Grid item xs={isSingleMode ? 12 : 6}>
                <GiftInfo
                  title={`${playerDataTime[0].format(
                    "YYYY/MM/DD HH:mm:ss（Z）"
                  )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
                  content={`${T("giftGolds")}：500152${t(`Unit.giftGolds`)}`}
                  loading={playerDataLoading}
                />
              </Grid>
              <Grid item xs={isSingleMode ? 12 : 6}>
                <GiftInfo
                  title={`${playerDataTime[0].format(
                    "YYYY/MM/DD HH:mm:ss（Z）"
                  )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
                  content={`${T("giftTimes")}：500152${t(`Unit.giftTimes`)}`}
                  loading={playerDataLoading}
                />
              </Grid>
            </>
          )
        }
        <Grid item xs={isSingleMode ? 12 : 6}>
          <Rank tabDatas={lobbyRankProps} loading={playerDataLoading} />
        </Grid>
        <Grid item xs={isSingleMode ? 12 : 6}>
          <Rank tabDatas={clubRankProps} loading={playerDataLoading} />
        </Grid>
        <Grid item xs={12}>
          <Rank tabDatas={giftRankProps} loading={playerDataLoading} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerData;
