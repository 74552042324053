import { Grid, InputLabel } from "@material-ui/core";
import { TreeSelect } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { useBusinessStatisticStyle } from "../../../../assets/styles/business-statistic";
import { getTreeData } from "../../../../utils/helper";
import GameData from "./game-data";
import PlatformInfo from "./platform-info";
import PlayerData from "./player-data";
const ClubBusinessReport = () => {
  const classes = useBusinessStatisticStyle();
  const auth = useContext(IsAuthorization);
  const [selectedClub, setSelectedClub] = useState("Club A");
  const { t } = useTranslation();
  const T = (name: string) => t(`BusinessReport.${name}`);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className={classes.root}>
        <Grid container className={classes.gridRow}>
          <Grid container item xs={12}>
            <Grid item className={classes.clubSelect}>
              <InputLabel
                shrink={true}
                // style={{ color: error ? "red" : "initial" }}
              >
                {T("clubID")}
              </InputLabel>
              <TreeSelect
                onChange={(e) => {
                  setSelectedClub(e);
                }}
                value={selectedClub}
                treeData={getTreeData(["Club A", "Club B", "Club C"], false)}
              />
            </Grid>
          </Grid>
          {
            // 上方整體資訊
          }
          <Grid container item xs={12}>
            <PlatformInfo />
          </Grid>
          {
            // 遊戲數據
          }
          <Grid item container xs={12} className={classes.gridRow}>
            <GameData />
          </Grid>

          {
            // 玩家數據以及排行
          }
          <Grid item container xs={12} className={classes.gridRow}>
            <PlayerData />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ClubBusinessReport;
