import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
} from "../../../../utils/helper";

export interface IUser {
  username: string;
  nickname: string;
  password: string;
  roleID: string;
  clubID: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  permissions: string;
}

export const formInitialValues = {
  username: "user 1",
  nickname: "user nickname",
  password: "",
  confirmPassword: "",
  roleID: "Owner",
  permissions: "",
  clubID: "Club1",
  status: "activated",
  createdAt: "2021-11-29 16:42:30",
  updatedAt: "2021-11-29 16:42:30",
};

export const sortableColumns = [
  "username",
  "nickname",
  "roleID",
  "clubID",
  "status",
  "createdAt",
  "updatedAt",
];

export interface IFilter extends IBasicFilter {
  username: string;
  nickname: string;
  clubID: string[];
  roleID: string[];
  status: string[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IUser[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    username: filter.username,
    nickname: filter.nickname,
    roleID: filter.roleID.join(),
    clubID: filter.clubID.join(),
    status: filter.status.join(),
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  username: "",
  nickname: "",
  roleID: [],
  clubID: [],
  status: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
