import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import { IDetailKey, IPagedResponse } from "../../../../../utils/common-type";
import { unAuthorizationHandle } from "../../../../../utils/helper";
import { IAction, TemplateTable } from "../../../../layout";
import Action from "./actions";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  ILobbyPlayerReport,
  sortableColumns,
} from "./type";

const LobbyPlayerReport = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<ILobbyPlayerReport[]>([]);
  const [detail, setDetail] = useState<ILobbyPlayerReport>();
  const [actionName, setActionName] = useState("");
  const [actionTitle, setActionTitle] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  // const [summary, setSummary] = useState<ISummary<any>[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const initialFlag = useRef(true);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`LobbyPlayerReport.${name}`);

  const header: IDetailKey<ILobbyPlayerReport>[] = [
    { key: "fakeID", name: T("fakeID"), isInt: true },
    { key: "nickname", name: T("nickname") },
    { key: "totalStoreAmount", name: T("totalStoreAmount") },
    { key: "electronicTotalBet", name: T("electronicTotalBet") },
    { key: "electronicTotalWin", name: T("electronicTotalWin") },
    { key: "competitiveTotalWinLose", name: T("competitiveTotalWinLose") },
    { key: "competitiveTotalRoomFee", name: T("competitiveTotalRoomFee") },
    { key: "competitiveTotalRake", name: T("competitiveTotalRake") },
  ];
  const action: IAction<ILobbyPlayerReport> = {
    create: false,
    del: false,
    edit: false,
    custom: [
      {
        name: "電子遊戲報表",
        actionIcon: <VideogameAssetIcon />,
        onEvent: (data: ILobbyPlayerReport) => {
          setDetail(data);
          setIsActionOpen(true);
          setActionName("electronic");
          setActionTitle(
            `${data.fakeID} 電子遊戲報表 ${filter.time[0].format(
              "YYYY-MM-DD HH:mm:ss"
            )} ~ ${filter.time[1].format("YYYY-MM-DD HH:mm:ss")}`
          );
        },
        position: "IN_ROW",
      },
      {
        name: "競技遊戲報表",
        actionIcon: <SupervisedUserCircleIcon />,
        onEvent: (data: ILobbyPlayerReport) => {
          setDetail(data);
          setIsActionOpen(true);
          setActionName("competitive");
          setActionTitle(
            `${data.fakeID} 競技遊戲報表 ${filter.time[0].format(
              "YYYY-MM-DD HH:mm:ss"
            )} ~ ${filter.time[1].format("YYYY-MM-DD HH:mm:ss")}`
          );
        },
        position: "IN_ROW",
      },
    ],
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ILobbyPlayerReport[]>>({
        method: api.getLobbyPlayerReport.method,
        url: api.getLobbyPlayerReport.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => ({
            ...d,
            electronicTotalBet: d.electronicTotalBet / 10000,
            electronicTotalWin: d.electronicTotalWin / 10000,
            competitiveTotalWinLose: d.competitiveTotalWinLose / 10000,
          }))
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onSearch();
  }, [onSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize="md"
        action={actionName}
        title={actionTitle}
        defaultFilter={filter}
      />
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<ILobbyPlayerReport, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default LobbyPlayerReport;
