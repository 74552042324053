import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import {
  IDetailKey,
  IGameInfo,
  IPagedResponse,
  ISearchOptions,
} from "../../../../../utils/common-type";
import {
  mapGameLang,
  unAuthorizationHandle,
} from "../../../../../utils/helper";
import { IAction, ISummary, TemplateTable } from "../../../../layout";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  ICompetitiveGameReport,
  ICompetitiveGameReportSummary,
  initialSummary,
  sortableColumns,
} from "./type";

const LobbyCompetitiveGameReport = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<ICompetitiveGameReport[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [gameInfos, setGameInfos] = useState<IGameInfo[]>([]);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const initialFlag = useRef(true);
  const [summary, setSummary] =
    useState<ISummary<ICompetitiveGameReportSummary>[]>(initialSummary);
  const downloadData = useMemo(() => data, [data]);
  const { t, i18n } = useTranslation();
  const T = (name: string) => t(`GameReport.${name}`);

  const header: IDetailKey<ICompetitiveGameReport>[] = [
    {
      key: "gameID",
      name: T("gameID"),
      custom: (gameID) => mapGameLang(gameID, gameInfos, i18n.language),
    },
    { key: "totalRoomFee", name: T("totalRoomFee") },
    { key: "totalRooms", name: T("totalRooms"), isInt: true },
    { key: "totalWinLose", name: T("totalWinLose") },
    { key: "totalRake", name: T("totalRake") },
    { key: "playerCount", name: T("playerCount"), isInt: true },
  ];

  const action: IAction<ICompetitiveGameReport> = {
    create: false,
    del: false,
    edit: false,
  };

  useEffect(() => {
    instance
      .request<ISearchOptions>({
        method: api.getOptions.method,
        url: api.getOptions.url(),
      })
      .then((res) => {
        setGameInfos(res.data.gameInfos || []);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin]);

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ICompetitiveGameReport[]>>({
        method: api.getCompetitiveGameReport.method,
        url: api.getCompetitiveGameReport.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => ({
            ...d,
            totalWinLose: d.totalWinLose / 10000,
            totalRake: d.totalRake / 10000,
          }))
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  const searchSummary = (filter: IFilter) => {
    setSummary(initialSummary);
    instance
      .request<ICompetitiveGameReportSummary>({
        method: api.getCompetitiveGameReportSummary.method,
        url: api.getCompetitiveGameReportSummary.url(),
        params: {
          ...buildFilter(filter),
          page: undefined,
          pageSize: undefined,
        },
      })
      .then((res) => {
        const data: ICompetitiveGameReportSummary = {
          ...res.data,
          totalWinLose: res.data.totalWinLose / 10000,
          totalRake: res.data.totalRake / 10000,
          avgRake: res.data.avgRake / 10000,
        };
        setSummary(summary.map((s) => ({ ...s, value: data[s.key] })));
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }
    onSearch();
  }, [onSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        data={downloadData}
        filter={filter}
        setFilter={setFilter}
        searchSummary={searchSummary}
        gameInfos={gameInfos}
      />
      <TemplateTable<ICompetitiveGameReport, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        summary={summary.map((s) => {
          return { key: T(s.key), value: s.value, isInt: s.isInt };
        })}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default LobbyCompetitiveGameReport;
