import React from "react";
import { useTranslation } from "react-i18next";
import { IDetailEdit } from "../../../../../../utils/common-type";
import { DetailDialog } from "../../../../../common";
import ActiveGames from "./active-games";
import ClubMarquee from "./club-marquee";

const ClubDetail = ({
  open,
  setOpen,
  widthSize,
  title = "clubDetail",
}: IDetailEdit<any>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`BusinessReport.${name}`);
  };

  return (
    <DetailDialog
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={T(title)}
      widthSize={widthSize}
    >
      <div
        style={{
          fontSize: 22,
          marginTop: 20,
          marginBottom: 10,
          borderBottom: "1px solid",
        }}
      >
        {t("Marquee.clubMarquee")}
      </div>
      <ClubMarquee />
      <div
        style={{
          fontSize: 22,
          marginTop: 20,
          marginBottom: 10,
          borderBottom: "1px solid",
        }}
      >
        {t("BusinessReport.activeGames")}
      </div>
      <ActiveGames />
    </DetailDialog>
  );
};
export default ClubDetail;
