import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import PointsRecord from "./points-record";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`Page.${props.action}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "pointsRecord":
        return <PointsRecord />;
    }
  };

  return (
    <CustomDialog
      title={`${T(props.action)} uid: ${props.data?.uid}`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
