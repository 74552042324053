import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export interface IAccess {
  UID: string;
  enterTime: string;
  exitTime: string;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  UID: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  downloadData: IAccess[];
}

export const sortableColumns = ["UID", "enterTime", "exitTime"];

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  UID: "",
});
