import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export interface ICustomerServiceLog {
  permission: string;
  time: string;
  ip: string;
  username: string;
}

export interface IFilter extends IBasicFilter {
  username: string;
  permission: string[];
  ip: string;
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ICustomerServiceLog[];
}

export const sortableColumns = [];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    permission: filter.permission.join(),
    username: filter.username,
    ip: filter.ip,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  username: "",
  permission: [],
  ip: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
