import { Card } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dataTypeCheck } from "../../../../../utils/helper";
import LoadingSpin from "../../../../common/loading-spin";

interface Props {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  chartBar: JSX.Element[]; // TODO: check correct Bar type
  chartData: { [key: string]: any }[];
  loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const CustomBarChart: React.FC<Props> = ({
  title,
  subTitle,
  chartBar,
  chartData,
  loading = false,
}) => {
  const { t } = useTranslation();

  return (
    <Card bodyStyle={{ height: "100%" }}>
      <LoadingSpin loading={loading} />
      <div style={{ fontSize: 16, margin: 15, fontWeight: "bold" }}>
        {title}
      </div>
      <div style={{ fontSize: 14, margin: 15 }}>{subTitle}</div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" />
          <Tooltip
            formatter={(value: any, name: string) => [
              `${dataTypeCheck(value)}${t(`Unit.${name}`)}`,
              `${t(`BusinessReport.${name}`)}`,
            ]}
          />
          <Legend
            formatter={(name: string) => `${t(`BusinessReport.${name}`)}`}
          />
          {chartBar}
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default CustomBarChart;
