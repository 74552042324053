import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateSearchFormItem, getTreeData } from "../../../../utils/helper";
import { SearchAccordion } from "../../../common";
import { defaultFilter, ISearchProps } from "./type";

// eslint-disable-next-line @typescript-eslint/naming-convention
const Search = ({ filter, setFilter }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        enableReinitialize={true}
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setFilter({ ...values, pages: 0 });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "time",
                  name: "time",
                  label: "common.Time",
                },
                {
                  type: "text",
                  name: "username",
                  label: "CustomServiceLog.username",
                },
                {
                  type: "multiSelect",
                  name: "permission",
                  label: "CustomServiceLog.permission",
                  treeData: getTreeData(["Permission1", "Permission2"]),
                },
                {
                  type: "text",
                  name: "ip",
                  label: "CustomServiceLog.ip",
                },
              ],
              defaultFilter(),
              t
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
