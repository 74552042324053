import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export interface IConsumeRecord {
  /** 時間 */
  time: string;
  /** 單號 */
  id: string;
  /** 玩家ID */
  playerID: string;
  /** 商品 */
  product: string;
  /** 購買管道 */
  type: string;
  /** 花費金額 */
  cost: number;
  /** 金額單位 */
  unit: string;
  /** 內容物 */
  contain?: IProductItem;
}

export interface IProductItem {
  /** 項目ID */
  itemId: string;
  /** 項目名稱 */
  itemName: string;
  /** 數量 */
  amount: number;
}

export const sortableColumns = ["time", "cost"];

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  selectionList: Record<string, string[]>;
}

export interface IFilter extends IBasicFilter {
  playerID: string;
  time: moment.Moment[];
}

export const defaultFilter: () => IFilter = () => ({
  playerID: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  id: "",
  product: "",
  type: [],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
