import {
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { Empty, Popover, TreeSelect } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBasicFilter } from "../../utils/common-type";
import { dataTypeCheck, getTreeData, pageSizeList } from "../../utils/helper";
import LoadingSpin from "../common/loading-spin";
import {
  ITableContain,
  ITableHeader,
  ITablePagination,
  ITableTitle,
  ITemplateTable,
} from "./template-type";

const useHeaderStyle = makeStyles(() => ({
  tableHead: {},
  tableHeadCell: {
    color: "white",
    textTransform: "capitalize",
    fontSize: 14,
  },
}));
const useContainStyles = makeStyles(() => ({
  body: {
    position: "relative",
  },
  row: {},
  cell: {
    borderBottom: "none",
    fontSize: 20,
  },
  icon: {
    fontSize: "5rem",
  },
  buttonLabel: {
    flexDirection: "column",
  },
  actionButton: {
    padding: 5,
  },
  contain: {
    textOverflow: "ellipsis",
    maxWidth: "20vw",
    overflow: "hidden",
    width: "auto",
    fontSize: 14,
    overflowX: "auto",
  },
}));

const usePaginationStyles = makeStyles((theme) => ({
  iconButton: {
    height: 48,
  },
  pageText: {
    fontSize: 20,
    width: 30,
    height: 48,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textPrimary: {
    color: theme.palette.type === "dark" ? blue[100] : blue[900],
  },
  textSecondary: {
    "&:hover": {
      backgroundColor: theme.palette.type === "dark" ? red[400] : red[50],
    },
    fontWeight: "bold",
    backgroundColor: theme.palette.type === "dark" ? red[400] : red[50],
    color: theme.palette.type === "dark" ? red[50] : red.A700,
  },
}));
const useStyles = makeStyles((theme: Theme) => ({
  template: {
    // padding: theme.spacing(3),
    paddingTop: 0,
  },
  table: {
    overflow: "auto",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function checkDataInArray(array: any[], target: number | string) {
  return array.find((data) => data._id === target) !== undefined;
}
function deleteOneData(array: any[], target: number | string) {
  return array.filter((sub) => sub._id !== target);
}
function getDataHead(array: object) {
  return Object.keys(array).map((key) => {
    return key;
  });
}
const TableToolbar = <T extends Object>({
  action,
  dataSelected,
  setDataSelected,
}: ITableTitle<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };
  const custom = action.custom?.filter((v) => v.position === "TOP");

  return (
    <div className={classes.toolbar}>
      {custom &&
        custom.length > 0 &&
        custom.map((a, idx) => {
          return (
            <Tooltip title={a.name} key={`custom-top-${idx}`}>
              <IconButton
                aria-label={a.name}
                onClick={(e) => {
                  e.stopPropagation();
                  (a.onEvent as () => void)();
                  setDataSelected([]);
                }}
              >
                {a.actionIcon}
              </IconButton>
            </Tooltip>
          );
        })}
      {action.create && (
        //如果有新增功能 顯示新增ICON
        <Tooltip title={T("create")}>
          <IconButton
            aria-label="create"
            component="span"
            onClick={(e: any) => {
              if (action.onCreate) action.onCreate(e);
              setDataSelected([]);
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {action.del && (
        //如果有新增功能 顯示刪除ICON
        //沒有選取任何資料前 該ICON禁用
        <Tooltip title={T("delete")}>
          <IconButton
            aria-label="delete"
            component="div"
            disabled={dataSelected.length === 0}
            onClick={() => {
              if (action.onDel) action.onDel(dataSelected);
              setDataSelected([]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {/* {action.onRead && (
        //如果有新增功能 顯示刪除ICON
        //沒有選取任何資料前 該ICON禁用
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            component="div"
            disabled={dataSelected.length === 0}
            onClick={() => {
              if (action.onDel) action.onDel(dataSelected);
              setDataSelected([]);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </div>
  );
};
const TableHeader = <T extends Object>({
  action,
  data,
  dataSelected,
  header,
  order,
  orderBy,
  rowCount,
  resetOrderPriority,
  setDataSelected,
  visibleField,
  sort,
}: ITableHeader<T>) => {
  const classes = useHeaderStyle();
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };

  if (action.conditions && action.conditions.del) {
    rowCount = data.filter((v) => action.conditions?.del(v)).length;
  }

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {action.del && action.onDel && (
          //有刪除功能且刪除函數有設定時
          //顯示選取框
          <TableCell padding="checkbox" align="center">
            <Checkbox
              checked={rowCount > 0 && dataSelected.length === rowCount}
              className={classes.tableHeadCell}
              indeterminate={
                dataSelected.length > 0 && dataSelected.length < rowCount
              }
              onChange={() => {
                setDataSelected([]);
                if (dataSelected.length !== rowCount) {
                  if (action.conditions && action.conditions.del) {
                    setDataSelected(
                      data.filter((v) => action.conditions?.del(v))
                    );
                  } else {
                    setDataSelected(data);
                  }
                }
              }}
            />
          </TableCell>
        )}
        {header
          ? header
              .filter((h) => {
                if (
                  visibleField &&
                  visibleField.find((field) => field === h.name)
                ) {
                  return h;
                }
              })
              .map(({ key, name, headAlign = "inherit" }) => (
                <TableCell
                  key={key}
                  className={classes.tableHeadCell}
                  align={headAlign}
                >
                  {sort.indexOf(key) !== -1 ? (
                    //檢查這列資料是否可以排序
                    <TableSortLabel
                      active={order === key && orderBy !== undefined}
                      direction={
                        //排序方向
                        order === key &&
                        orderBy !== undefined &&
                        (orderBy === "asc" || orderBy === "desc")
                          ? orderBy
                          : undefined
                      }
                      onClick={() => {
                        //重新排序資料
                        resetOrderPriority(orderBy, key);
                      }}
                    >
                      {name ? name : key}
                    </TableSortLabel>
                  ) : name ? (
                    name
                  ) : (
                    key
                  )}
                </TableCell>
              ))
          : getDataHead(data[0] || []).map((headCell: string) => (
              //以第一筆資料的資料順序作為資料排序順序
              <TableCell key={headCell} className={classes.tableHeadCell}>
                {sort.indexOf(headCell) !== -1 ? (
                  <TableSortLabel
                    active={order === headCell}
                    direction={
                      order === headCell &&
                      orderBy !== undefined &&
                      (orderBy === "asc" || orderBy === "desc")
                        ? orderBy
                        : undefined
                    }
                    onClick={() => {
                      resetOrderPriority(orderBy, headCell);
                    }}
                  >
                    {headCell}
                  </TableSortLabel>
                ) : (
                  headCell
                )}
              </TableCell>
            ))}
        {(action.del ||
          action.edit ||
          action.read ||
          (action.custom?.length &&
            action.custom.find((a) => a.position === "IN_ROW"))) && (
          //有刪除或是修改或是讀取功能時
          //多新增該HEADER
          <TableCell align="center" className={classes.tableHeadCell}>
            {T("action")}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
const TableContain = <T extends Object>({
  action,
  data,
  dataSelected,
  header,
  reloading,
  rowStyle,
  setDataSelected,
  visibleField,
}: ITableContain<T>) => {
  const classes = useContainStyles();
  const {
    del,
    edit,
    read,
    expand,
    onDel,
    onEdit,
    onRead,
    onExpand,
    onClick,
    conditions,
  } = action;
  const { t } = useTranslation();
  const T = useCallback(
    (name: string) => {
      return t(`common.${name}`);
    },
    [t]
  );

  const custom = action.custom?.filter((v) => v.position === "IN_ROW");

  const [isExpanded, setIsExpanded] = useState<boolean[]>([]);
  useEffect(() => {
    setIsExpanded([...Array(data.length)].map(() => false));
  }, [data]);

  const getTableCell = useCallback(
    (source: any) => {
      const contain: any[] = [];
      //如果有設定header 順序 會依照該順序排列
      //若沒設定就直接根據資料順序排列
      if (header) {
        header
          .filter((h) => {
            if (
              visibleField &&
              visibleField.find((field) => field === h.name)
            ) {
              return h;
            }
          })
          .forEach((head) => {
            contain.push(
              <TableCell
                align={head.dataAlign}
                className={classes.contain}
                key={`${source[head.key]}${head.key}`}
              >
                {head.custom
                  ? head.custom(source[head.key], source)
                  : head.isInt
                  ? source[head.key]
                  : dataTypeCheck(source[head.key])}
              </TableCell>
            );
          });
      } else {
        for (const [key, value] of Object.entries(source)) {
          contain.push(
            <TableCell className={classes.contain} key={`${source[key]}${key}`}>
              {dataTypeCheck(value)}
            </TableCell>
          );
        }
      }
      return contain;
    },
    [classes.contain, header, visibleField]
  );

  // 如果每列操過四個操作，會合併成一個
  const getActions = useCallback(
    (source: any) => {
      let actionCount = 0;
      const actions = (
        <>
          {edit &&
            onEdit &&
            (conditions?.edit ? conditions.edit(source) : true) && (
              <Tooltip title={T("edit")}>
                <IconButton
                  aria-label="edit"
                  className={classes.actionButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(source);
                    setDataSelected([]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          {del && onDel && (conditions?.del ? conditions.del(source) : true) && (
            <Tooltip title={T("delete")}>
              <IconButton
                aria-label="delete"
                className={classes.actionButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onDel([source]);
                  setDataSelected([]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {read &&
            onRead &&
            (conditions?.read ? conditions.read(source) : true) && (
              <Tooltip title={T("read")}>
                <IconButton
                  aria-label="read"
                  className={classes.actionButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRead(source);
                    setDataSelected([]);
                  }}
                >
                  <DescriptionIcon />
                </IconButton>
              </Tooltip>
            )}
          {custom &&
            custom.length > 0 &&
            custom.map((a, index) => {
              if (
                conditions &&
                conditions[a.name] &&
                !conditions[a.name](source)
              ) {
                return;
              }

              if (a.position === "IN_ROW") {
                actionCount++;
              }

              return (
                <Tooltip title={a.name} key={`custom${index}`}>
                  <IconButton
                    aria-label={a.name}
                    className={classes.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      a.onEvent(source);
                      setDataSelected([]);
                    }}
                  >
                    {a.actionIcon}
                  </IconButton>
                </Tooltip>
              );
            })}
        </>
      );

      if (edit && onEdit) actionCount++;
      if (del && onDel) actionCount++;
      if (read && onRead) actionCount++;

      if (actionCount > 4) {
        return (
          <TableCell
            align="center"
            className={classes.contain}
            key="action"
            padding="none"
          >
            <Popover
              trigger="click"
              placement="bottomLeft"
              zIndex={1000}
              content={actions}
            >
              <IconButton aria-label={"more"} className={classes.actionButton}>
                <ArrowDropDownCircleIcon />
              </IconButton>
            </Popover>
          </TableCell>
        );
      }

      return (
        <TableCell
          align="center"
          className={classes.contain}
          key="action"
          padding="none"
        >
          {actions}
        </TableCell>
      );
    },
    [
      T,
      classes.actionButton,
      classes.contain,
      conditions,
      custom,
      del,
      edit,
      onDel,
      onEdit,
      onRead,
      read,
      setDataSelected,
    ]
  );

  return (
    <TableBody className={classes.body}>
      <LoadingSpin loading={reloading} />
      {data.length ? (
        //如果有資料 且並沒有載入中
        data.map((source: any, index: number) => {
          // Add Unique Key For Delete
          source._id = index;

          return (
            <Fragment key={`tableRowContainer${index}`}>
              <TableRow
                hover
                className={classes.row}
                key={`tableRow${index}`}
                style={rowStyle ? { ...rowStyle(source) } : {}}
                onClick={() => {
                  setIsExpanded(
                    isExpanded.map((visible, i) =>
                      i === index ? !visible : visible
                    )
                  );

                  // row onClick
                  if (onClick) {
                    onClick(source);
                  }
                }}
              >
                {del && onDel && (
                  //如果有刪除功能才在個筆資料前面顯示選取匡
                  <TableCell key={"checkbox"} padding="checkbox" align="center">
                    {(conditions?.del ? conditions.del(source) : true) && (
                      <Checkbox
                        checked={checkDataInArray(dataSelected, source._id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          //檢查該筆資料是否已經存在
                          if (checkDataInArray(dataSelected, source._id))
                            //存在就將該筆資料移出
                            setDataSelected(
                              deleteOneData(dataSelected, source._id)
                            );
                          //不存在就新增該筆資料
                          else setDataSelected([...dataSelected, source]);
                        }}
                      />
                    )}
                  </TableCell>
                )}
                {
                  //主要資料
                  getTableCell(source)
                }
                {(del || edit || read || (custom && custom.length !== 0)) &&
                  //只要有刪除或修改其中一個功能
                  //各筆資料後面顯示 對應功能icon
                  getActions(source)}
              </TableRow>
              {/* {expand && onExpand && isExpanded[index] ? ( */}
              {expand && onExpand ? (
                <TableRow>
                  {del && onDel && <TableCell padding="checkbox"></TableCell>}
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      borderBottom: "0px",
                    }}
                    colSpan={
                      header
                        ? header.filter((h) => {
                            if (
                              visibleField &&
                              visibleField.find((field) => field === h.name)
                            ) {
                              return h;
                            }
                          }).length +
                          (del || edit || read || (custom && custom.length > 0)
                            ? 1
                            : 0)
                        : Object.values(data[0]).length
                    }
                  >
                    <Collapse
                      in={isExpanded[index]}
                      timeout="auto"
                      unmountOnExit
                      style={{ padding: 20 }}
                    >
                      {onExpand(source)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </Fragment>
          );
        })
      ) : (
        //沒有資料
        <TableRow className={classes.row}>
          <TableCell
            align="center"
            className={classes.cell}
            colSpan={24}
            sortDirection={false}
          >
            <Empty description={T("No Data.")} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
const TablePagination = <T extends Object, F extends IBasicFilter>({
  filter,
  setFilter,
  rowsPerPage,
  setDataSelected,
  isNextPageHaveData = false,
}: ITablePagination<T, F>) => {
  const classes = usePaginationStyles();

  return (
    <div>
      <IconButton
        className={classes.iconButton}
        disabled={filter.pages === 0}
        aria-label="beforePage"
        onClick={() => {
          setDataSelected([]);
          setFilter({
            ...filter,
            pages: filter.pages !== 0 ? filter.pages - 1 : filter.pages,
          });
        }}
      >
        <NavigateBefore />
      </IconButton>
      <div className={classes.pageText}>{filter.pages + 1}</div>
      <IconButton
        className={classes.iconButton}
        disabled={!isNextPageHaveData}
        aria-label="nextPage"
        onClick={() => {
          setDataSelected([]);
          setFilter({
            ...filter,
            pages: isNextPageHaveData ? filter.pages + 1 : filter.pages,
          });
        }}
      >
        <NavigateNext />
      </IconButton>
      <TreeSelect
        value={`${filter.pageSize}`}
        listHeight={350}
        maxTagCount={"responsive"}
        showCheckedStrategy="SHOW_CHILD"
        treeDefaultExpandAll
        treeData={getTreeData(
          rowsPerPage.map((v) => `${v}`),
          false
        )}
        // dropdownStyle={{
        //   zIndex: 1000000,
        // }}
        onChange={(data) => {
          setDataSelected([]);
          setFilter({
            ...filter,
            pageSize: Number(data),
            pages: Math.floor(
              Number((filter.pageSize * filter.pages) / Number(data))
            ),
          });
        }}
        style={{ width: "70px", alignSelf: "center" }}
      />
      {/* <Select
        value={filter.pageSize}
        style={{
          alignSelf: "center",
        }}
        label="Rows"
        onChange={(e) => {
          setDataSelected([]);
          setFilter({
            ...filter,
            pageSize: e.target.value as number,
            pages: Math.floor(
              Number(
                (filter.pageSize * filter.pages) / (e.target.value as number)
              )
            ),
          });
        }}
      >
        {rowsPerPage.map((data) => (
          <MenuItem key={data} value={data}>
            {data}
          </MenuItem>
        ))}
      </Select> */}
    </div>
    // </Grid>
  );
};

const TemplateTable = <T extends Object, F extends IBasicFilter>({
  action = {
    create: false,
    edit: false,
    del: false,
    read: false,
  },
  data = [],
  header,
  reloading = false,
  rowsPerPage = pageSizeList,
  rowStyle,
  sort = [],
  toolbar = true,
  summary,
  isNextPageHaveData = false,
  filter,
  setFilter,
  pagination = true,
  topPagination = true,
  visibleFieldSelect = true,
}: ITemplateTable<T, F>) => {
  // data = data.map((v, idx) => ({ _id: idx, ...v }));
  const classes = useStyles();
  const [dataOrigin, setDataOrigin] = useState<T[]>(data);
  const [dataSelected, setDataSelected] = useState<T[]>([]);
  const [visibleField, setVisibleField] = useState<string[]>([]);
  const [selectedField, setSelectedField] = useState<string[]>([]);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`common.${name}`);
  };

  useEffect(() => {
    localStorage.setItem("pageSize", filter.pageSize.toString());
  }, [filter.pageSize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filter.pages]);

  useEffect(() => {
    //重新排序
    setDataOrigin(data);
  }, [data]);
  useEffect(() => {
    if (header) {
      setVisibleField(
        header.map((h) => {
          return h.name;
        })
      );
      setSelectedField(
        header.map((h) => {
          return h.name;
        })
      );
    }
  }, [header]);

  //設定目前根據什麼條件order
  //以及目前是升序或降序
  function resetOrderPriority(orderBy: string | undefined, order: string) {
    if (order == filter.order) {
      switch (orderBy) {
        case "asc":
          orderBy = "desc";
          break;
        case "desc":
          orderBy = undefined;
          break;
        case undefined:
          orderBy = "asc";
          break;
        default:
          orderBy = undefined;
          break;
      }
    } else {
      orderBy = "asc";
    }

    setFilter({
      ...filter,
      orderBy,
      order,
    });
  }
  return (
    <div className={classes.template}>
      <Grid
        container
        style={{
          marginTop: "1px",
          marginBottom: "5px",
        }}
        justify="space-between"
      >
        <Grid item container justify="flex-start" style={{ flex: 1 }}>
          {visibleFieldSelect && (
            <>
              <InputLabel shrink={true} id="visibleField">
                {T("Visible Field")}
              </InputLabel>
              <TreeSelect
                value={selectedField}
                multiple
                treeCheckable
                listHeight={350}
                maxTagCount={"responsive"}
                showCheckedStrategy="SHOW_CHILD"
                treeDefaultExpandAll
                treeData={getTreeData(visibleField)}
                onChange={(data) => {
                  setSelectedField(data);
                }}
                style={{ width: "100%" }}
                // dropdownStyle={{
                //   zIndex: 1000000,
                // }}
              />
            </>
          )}
        </Grid>
        <Grid item style={{ display: "flex" }}>
          {toolbar && data.length ? (
            <TableToolbar
              action={action}
              dataSelected={dataSelected}
              setDataSelected={setDataSelected}
            />
          ) : (
            <></>
          )}
          {data.length && pagination && topPagination ? (
            //分頁器
            <TablePagination<T, F>
              filter={filter}
              setFilter={setFilter}
              rowsPerPage={rowsPerPage}
              setDataSelected={setDataSelected}
              isNextPageHaveData={isNextPageHaveData}
            />
          ) : (
            <></>
          )}
        </Grid>
        {summary && (
          <Grid
            item
            container
            justify="space-around"
            alignItems="center"
            xs={12}
          >
            <>
              {summary.map((s) => {
                return (
                  <Grid item style={{ color: "#3f51b5" }} key={Math.random()}>
                    {`${s.key as any}: ${
                      s.isInt ? s.value : dataTypeCheck(s.value)
                    }`}
                  </Grid>
                );
              })}
            </>
          </Grid>
        )}
      </Grid>
      <TableContainer className={classes.table}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHeader
            action={action}
            data={dataOrigin}
            dataSelected={dataSelected}
            header={header}
            order={filter.order}
            orderBy={filter.orderBy}
            resetOrderPriority={resetOrderPriority}
            rowCount={data.length}
            setDataSelected={setDataSelected}
            visibleField={selectedField}
            sort={sort}
          />
          <TableContain
            action={action}
            data={dataOrigin}
            dataSelected={dataSelected}
            header={header}
            reloading={reloading}
            rowStyle={rowStyle}
            visibleField={selectedField}
            setDataSelected={setDataSelected}
          />
        </Table>
      </TableContainer>
      {data.length && pagination ? (
        //分頁器
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <TablePagination<T, F>
            filter={filter}
            setFilter={setFilter}
            rowsPerPage={rowsPerPage}
            setDataSelected={setDataSelected}
            isNextPageHaveData={isNextPageHaveData}
          />
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TemplateTable;
