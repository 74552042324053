import { Grid, useMediaQuery } from "@material-ui/core";
import { TableColumnsType } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Rank1 from "../../../../../assets/images/rank-1.png";
import Rank2 from "../../../../../assets/images/rank-2.png";
import Rank3 from "../../../../../assets/images/rank-3.png";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import {
  fakeFullCompetitiveRank,
  fakeFullElectronicRank,
} from "../../../../fake-data/business-statistics";
import { DateRangeEnum } from "../../business-report/type";
import { Rank } from "../../common-component";
import DateRangeSelector from "../../common-component/date-range-selector";

interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlayerData: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [selectedPlayerDataDateRange, setSelectedPlayerDataDateRange] =
    useState(DateRangeEnum.今日);
  const [playerDataTime, setPlayerDataTime] = useState<any>([
    moment().startOf("days"),
    moment().endOf("days"),
  ]);
  const [playerDataLoading, setPlayerDataLoading] = useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);
  const rankIcons = [Rank1, Rank2, Rank3];

  const clubElectronicRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const clubCompetitiveRankHeader: TableColumnsType<any> = [
    {
      dataIndex: "rank",
      title: T("rank"),
      render: (rank: number) =>
        rank <= 3 ? (
          <img src={rankIcons[rank - 1]} alt={`${rank}`} width={30} />
        ) : (
          rank
        ),
      align: "center",
    },
    {
      dataIndex: "playerID",
      title: T("playerID"),
      align: "center",
    },
    {
      dataIndex: "score",
      title: T("score"),
      align: "center",
    },
  ];

  const clubElectronicRankProps = [
    {
      title: `${T("clubElectronicRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullElectronicRank,
      tableHeader: clubElectronicRankHeader,
    },
  ];

  const clubCompetitiveRankProps = [
    {
      title: `${T("clubElectronicRank")}`,
      subTitle: `${playerDataTime[0].format(
        "YYYY/MM/DD HH:mm:ss（Z）"
      )} ~ ${playerDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`,
      tableData: fakeFullCompetitiveRank,
      tableHeader: clubCompetitiveRankHeader,
    },
  ];

  // fake loading
  useEffect(() => {
    setPlayerDataLoading(true);
    setTimeout(() => {
      setPlayerDataLoading(false);
    }, 3000);
  }, [playerDataTime]);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <div className={`${classes.card} ${classes.greenOpacity}`}>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center">
          <div
            className={`${classes.whiteTitle} ${
              isSingleMode && classes.singleModeFullWidth
            }`}
          >{`${T("playerData")}`}</div>
          <DateRangeSelector
            selectedDateRange={selectedPlayerDataDateRange}
            setSelectedDateRange={setSelectedPlayerDataDateRange}
            dateRange={playerDataTime}
            setDateRange={setPlayerDataTime}
            datePickerOnChange={(e: any) => {
              setSelectedPlayerDataDateRange(DateRangeEnum.無);
              setPlayerDataTime([e[0], moment(e[1]).endOf("days")]);
            }}
          />
        </Grid>
        <Grid item xs={isSingleMode ? 12 : 6}>
          <Rank
            tabDatas={clubElectronicRankProps}
            loading={playerDataLoading}
          />
        </Grid>
        <Grid item xs={isSingleMode ? 12 : 6}>
          <Rank
            tabDatas={clubCompetitiveRankProps}
            loading={playerDataLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PlayerData;
