import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import ClubCompetitiveGameRecord from "../../../report-management/game-record/club-competitive-game-record";
import ClubElectronicGameRecord from "../../../report-management/game-record/club-electronic-game-record";
import PointsDetail from "../../points-detail";
import ClubAccessRecord from "./club-access-record";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`MemberList.${name}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "PointsDetail":
        return <PointsDetail popup={true} />;
      case "ClubElectronicGameRecord":
        return <ClubElectronicGameRecord popup={true} />;
      case "ClubCompetitiveGameRecord":
        return <ClubCompetitiveGameRecord popup={true} />;
      case "AccessRecord":
        return <ClubAccessRecord />;
    }
  };

  return (
    <CustomDialog
      title={`${T(`${props.action}`)} uid: ${props.data?.playerID}`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
