import moment from "moment";
import { IBasicFilter } from "../../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../../utils/helper";

export enum AccessPlaceEnum {
  lobby = "大廳",
  club = "俱樂部",
}
export interface IAccess {
  ip: string;
  place: string;
  clubID: string;
  action: string;
  time: string;
}

export interface IFilter extends IBasicFilter {
  clubID: string[];
  place: string[];
  ip: string;
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  downloadData: IAccess[];
}

export const sortableColumns = ["enterTime", "exitTime"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    clubID: filter.clubID.join(),
    ip: filter.ip,
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };

  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  clubID: [],
  place: [],
  ip: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
});
