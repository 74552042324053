import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { mapEnumToList } from "../../../../../../utils/helper";
import { StoredValueUnitEnum } from "../../../../customer-service/stored-value-record/type";
import { generateFormItem, getTreeData } from "../../../../../../utils/helper";
import { StoredValueActionEnum } from "./type";

const StoredValue = () => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`StoredValueRecord.${name}`);
  };
  return (
    <Formik
      //TODO: add initialValues
      initialValues={{
        playerID: "",
        action: StoredValueActionEnum.store,
        unit: StoredValueUnitEnum.gold,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {(form) => (
        <Form style={{ width: "100%" }}>
          {generateFormItem(
            form,
            [
              {
                type: "text",
                name: "playerID",
                label: "playerID",
                size: { xs: 12 },
              },
              {
                type: "singleSelect",
                name: "action",
                label: "action",
                size: { xs: 12 },
                treeData: getTreeData(
                  mapEnumToList(StoredValueActionEnum),
                  false
                ),
              },
              {
                type: "number",
                name: "storedValue",
                label: "storedValue",
                size: { xs: 12 },
              },
              {
                type: "singleSelect",
                name: "unit",
                label: "unit",
                size: { xs: 12 },
                treeData: getTreeData(
                  mapEnumToList(StoredValueUnitEnum),
                  false
                ),
              },
            ],
            {
              playerID: "",
              action: StoredValueActionEnum.store,
              unit: StoredValueUnitEnum.gold,
            },
            T
          )}
        </Form>
      )}
    </Formik>
  );
};
export default StoredValue;
