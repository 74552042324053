import { makeStyles } from "@material-ui/core";
import { Spin } from "antd";
import React from "react";

const useStyles = makeStyles(() => ({
    spinContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 1000,
    },
    spinBlur: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 999,
        background: "white",
        opacity: 0.5,
    },
    spin: {
        position: "absolute",
        left: "50%",
        top: "50%",
        zIndex: 10000,
        margin: -10
    },
}))

interface Props {
    loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const LoadingSpin: React.FC<Props> = ({ loading = true }) => {
    const classes = useStyles();
    return (
        <div>
            <div className={`${classes.spinContainer}`} hidden={!loading}>
                <Spin className={classes.spin} />
            </div>
            <div className={classes.spinBlur} hidden={!loading} />
        </div>
    );
};
export default LoadingSpin;
