import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export enum ItemTypeEnum {
  type1 = "type1",
  type2 = "type2",
}

export enum ItemStatusEnum {
  status1 = "status1",
  status2 = "status2",
}

export interface IItem {
  name: string;
  imageURL: string;
  type: ItemTypeEnum;
  description: string;
  price: number;
  unit: string;
  startTime: string;
  endTime: string;
  createTime: string;
  updateTime: string;
  status: ItemStatusEnum;
}

export interface IItemForm {
  name: string;
  description: string;
  imageURL: string;
  type: ItemTypeEnum;
  price: number;
  startTime: moment.Moment;
  endTime: moment.Moment;
  status: ItemStatusEnum;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  name: string;
  type: ItemTypeEnum[];
  status: ItemStatusEnum[];
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  downloadData: IItem[];
}

export interface IFormProps {
  tempData: IItemForm;
  dialogType: string | null;
  setDialogType: React.Dispatch<React.SetStateAction<string | null>>;
  onCreate: (payload: IItemForm) => void;
  onEdit: (payload: IItemForm) => void;
}

export const sortableColumns = [
  "name",
  "type",
  "price",
  "unit",
  "startTime",
  "endTime",
  "createTime",
  "updateTime",
  "status",
];

export const formInitialValues: IItemForm = {
  name: "item name",
  description: "item description",
  imageURL: "https://google.com",
  type: ItemTypeEnum.type1,
  status: ItemStatusEnum.status1,
  price: 100,
  startTime: moment(),
  endTime: moment(),
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  name: "",
  status: [],
  type: [],
});
