import { Button, InputLabel, useMediaQuery } from "@material-ui/core";
import { DatePicker } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRangeEnum } from "../../business-report/type";

interface Props {
  datePickerOnChange: (e: any) => void;
  selectedDateRange?: DateRangeEnum;
  setSelectedDateRange?: React.Dispatch<React.SetStateAction<DateRangeEnum>>;
  dateRange: any;
  setDateRange: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const DateRangeSelector: React.FC<Props> = ({
  datePickerOnChange,
  selectedDateRange,
  setSelectedDateRange,
  dateRange,
  setDateRange,
}) => {
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  const quickSelectPlayerDataTime = (range: DateRangeEnum) => {
    if (selectedDateRange && setSelectedDateRange) {
      setSelectedDateRange(range);
      switch (range) {
        case DateRangeEnum.今日: {
          setDateRange([moment().startOf("days"), moment().endOf("days")]);
          break;
        }
        case DateRangeEnum.昨日: {
          setDateRange([
            moment().subtract(1, "days").startOf("days"),
            moment().subtract(1, "days").endOf("days"),
          ]);
          break;
        }
        case DateRangeEnum.當月: {
          setDateRange([moment().startOf("month"), moment().endOf("month")]);
          break;
        }
      }
    }
  };

  return (
    <>
      {selectedDateRange && setSelectedDateRange ? (
        <>
          <Button
            style={{
              marginTop: isSingleMode ? 5 : 0,
              marginLeft: isSingleMode ? 0 : 10,
            }}
            variant="contained"
            color={
              selectedDateRange === DateRangeEnum.今日 ? "primary" : "default"
            }
            onClick={() => {
              quickSelectPlayerDataTime(DateRangeEnum.今日);
            }}
          >
            {T(DateRangeEnum.今日)}
          </Button>
          <Button
            style={{ marginTop: isSingleMode ? 5 : 0, marginLeft: 10 }}
            variant="contained"
            color={
              selectedDateRange === DateRangeEnum.昨日 ? "primary" : "default"
            }
            onClick={() => {
              quickSelectPlayerDataTime(DateRangeEnum.昨日);
            }}
          >
            {T(DateRangeEnum.昨日)}
          </Button>
          <Button
            style={{ marginTop: isSingleMode ? 5 : 0, marginLeft: 10 }}
            variant="contained"
            color={
              selectedDateRange === DateRangeEnum.當月 ? "primary" : "default"
            }
            onClick={() => {
              quickSelectPlayerDataTime(DateRangeEnum.當月);
            }}
          >
            {T(DateRangeEnum.當月)}
          </Button>
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          marginTop: isSingleMode ? 10 : 0,
          marginLeft: isSingleMode ? 0 : 10,
          width: isSingleMode ? "100%" : "initial",
        }}
      >
        <InputLabel shrink={true} style={{ color: "white", fontSize: 16 }}>
          {t("common.time")}
        </InputLabel>
        <DatePicker.RangePicker
          allowClear={false}
          value={dateRange}
          onChange={datePickerOnChange}
        />
      </div>
    </>
  );
};

export default DateRangeSelector;
