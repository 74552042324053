import { Grid, useMediaQuery } from "@material-ui/core";
import { TreeSelect } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "recharts";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import {
  chartColors,
  getTreeData,
  mapEnumToList,
} from "../../../../../utils/helper";
import { fakeGameBarChartData } from "../../../../fake-data/business-statistics";
import {
  ClubCompetitiveGameDataEnum,
  ClubElectronicGameDataEnum,
  LobbyCompetitiveGameDataEnum,
  LobbyElectronicGameDataEnum,
} from "../../../business-statistics/business-report/type";
import { CustomBarChart } from "../../../business-statistics/common-component";
import DateRangeSelector from "../../../business-statistics/common-component/date-range-selector";

interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const GameData: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [gameDataTime, setGameDataTime] = useState<any>([
    moment().startOf("days"),
    moment().endOf("days"),
  ]);
  const [gameDataLoading, setGameDataLoading] = useState(false);
  const [selectedLobbyElectronicGameData, setSelectedLobbyElectronicGameData] =
    useState<ClubElectronicGameDataEnum[]>([ClubElectronicGameDataEnum.rtp]);
  const [
    selectedLobbyCompetitiveGameData,
    setSelectedLobbyCompetitiveGameData,
  ] = useState<ClubCompetitiveGameDataEnum[]>([
    ClubCompetitiveGameDataEnum.開房量,
  ]);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  const lobbyElectronicDataBar = useMemo(() => {
    return selectedLobbyElectronicGameData.map((data, index) => {
      return (
        <Bar
          name={data}
          barSize={20}
          key={`lobbyElectronic${data}${index}`}
          dataKey={data}
          fill={
            chartColors[
              mapEnumToList(LobbyElectronicGameDataEnum).findIndex(
                (d) => d === data
              )
            ]
          }
        />
      );
    });
  }, [selectedLobbyElectronicGameData]);

  const lobbyCompetitiveDataBar = useMemo(() => {
    return selectedLobbyCompetitiveGameData.map((data, index) => {
      return (
        <Bar
          name={data}
          barSize={20}
          key={`lobbyCompetitive${data}${index}`}
          dataKey={data}
          fill={
            chartColors[
              mapEnumToList(LobbyCompetitiveGameDataEnum).findIndex(
                (d) => d === data
              )
            ]
          }
        />
      );
    });
  }, [selectedLobbyCompetitiveGameData]);

  // fake loading
  useEffect(() => {
    setGameDataLoading(true);
    setTimeout(() => {
      setGameDataLoading(false);
    }, 3000);
  }, [gameDataTime]);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <div className={`${classes.card} ${classes.greenOpacity}`}>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems="center">
          <div
            className={`${classes.whiteTitle} ${
              isSingleMode && classes.singleModeFullWidth
            }`}
          >{`${T("gameData")}`}</div>
          <DateRangeSelector
            dateRange={gameDataTime}
            setDateRange={setGameDataTime}
            datePickerOnChange={(e: any) => {
              setGameDataTime([e[0], moment(e[1]).endOf("days")]);
            }}
          />
        </Grid>
        {
          // 大廳電子遊戲數據
        }
        <Grid item xs={isSingleMode ? 12 : 6}>
          <CustomBarChart
            title={`${T("lobbyElectronicData")} ${gameDataTime[0].format(
              "YYYY/MM/DD HH:mm:ss（Z）"
            )} ~ ${gameDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
            subTitle={
              <TreeSelect
                style={{ minWidth: 200 }}
                value={selectedLobbyElectronicGameData}
                multiple={true}
                treeData={getTreeData(
                  mapEnumToList(LobbyElectronicGameDataEnum),
                  false,
                  T
                )}
                onChange={(v) => {
                  setSelectedLobbyElectronicGameData(v);
                }}
                className={`${isSingleMode && classes.singleModeTreeSelect}`}
              />
            }
            loading={gameDataLoading}
            chartData={fakeGameBarChartData}
            chartBar={lobbyElectronicDataBar}
          />
        </Grid>
        {
          // 大廳競技遊戲數據
        }
        <Grid item xs={isSingleMode ? 12 : 6}>
          <CustomBarChart
            title={`${T("lobbyCompetitiveData")} ${gameDataTime[0].format(
              "YYYY/MM/DD HH:mm:ss（Z）"
            )} ~ ${gameDataTime[1].format("YYYY/MM/DD HH:mm:ss（Z）")}`}
            subTitle={
              <TreeSelect
                style={{ minWidth: 200 }}
                value={selectedLobbyCompetitiveGameData}
                multiple={true}
                treeData={getTreeData(
                  mapEnumToList(LobbyCompetitiveGameDataEnum),
                  false,
                  T
                )}
                onChange={(v) => {
                  setSelectedLobbyCompetitiveGameData(v);
                }}
                className={`${isSingleMode && classes.singleModeTreeSelect}`}
              />
            }
            loading={gameDataLoading}
            chartData={fakeGameBarChartData}
            chartBar={lobbyCompetitiveDataBar}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GameData;
