import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import MemberInfo from "./member-info";
import PointsDetail from "./points-detail";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`OfflinePlayers.${name}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "pointsDetail":
        return <PointsDetail />;
      case "memberInfo":
        return <MemberInfo />;
    }
  };

  return (
    <CustomDialog
      title={`${T(props.action)} uid: ${props.data?.playerID}`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
