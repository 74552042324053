import { Grid, IconButton, useMediaQuery } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Card } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  colorStyles,
  useBusinessStatisticStyle,
} from "../../../../../assets/styles/business-statistic";
import { dataTypeCheck } from "../../../../../utils/helper";
import DataColorInfo from "../../common-component/data-color-info";
import ClubDetail from "./club-detail";
interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlatformInfo: React.FC<Props> = () => {
  const [fakeClubData] = useState({
    storedValue: 105412,
    electronicBet: 10512,
    electronicRevenue: 1005412,
    electronicDiamonds: 1051213,
    competitiveRake: 1051213,
    roomDiamonds: 1005412,
    loginPlayers: 1005,
  });
  const intFileds = ["loginPlayers"];
  const [clubDetailOpen, setClubDetailOpen] = useState(false);
  const classes = useBusinessStatisticStyle();
  const [loading, setLoading] = useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  // fake loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <Grid container className={`${isSingleMode && classes.singleModeGridRow}`}>
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("todayClubData")}
            titleStyle={colorStyles.blue}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFileds.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("lastDayClubData")}
            titleStyle={colorStyles.green}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFileds.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid item className={classes.gridItem}>
          <DataColorInfo
            title={T("thisMonthClubData")}
            titleStyle={colorStyles.purple}
            data={Object.entries(fakeClubData).map(([key, value], index) => {
              return (
                <div
                  className={classes.dataInfo}
                  style={index === 0 ? { marginTop: 0 } : {}}
                  key={key + index}
                >{`${T(key)}: ${
                  intFileds.includes(key) ? value : dataTypeCheck(value)
                }\n`}</div>
              );
            })}
            loading={loading}
          />
        </Grid>
      </Grid>

      <Grid container item xs={isSingleMode ? 12 : 3} direction="column">
        <Grid
          xs={12}
          item
          className={`${classes.gridItem}`}
          style={{ position: "relative" }}
        >
          <Card
            loading={loading}
            className={classes.brown}
            bodyStyle={{ height: "100%" }}
            style={{ height: "100%" }}
          >
            <div
              className={classes.center}
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <IconButton
                style={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                  color: "white",
                }}
                onClick={() => {
                  setClubDetailOpen(true);
                }}
              >
                <OpenInNewIcon />
              </IconButton>
              <div
                className={`${classes.center} ${classes.clubInfo}`}
                style={{ fontSize: 18 }}
              >
                好好玩俱樂部
              </div>
              <div className={`${classes.center} ${classes.clubInfo}`}>
                會長：Player 1
              </div>
              <div className={`${classes.center} ${classes.clubInfo}`}>
                俱樂部等級：1等
              </div>
              <div className={`${classes.center} ${classes.clubInfo}`}>
                俱樂部人數：300人
              </div>
              <div className={`${classes.center} ${classes.clubInfo}`}>
                昨日活躍人數：156人
              </div>
              <div className={`${classes.center} ${classes.clubInfo}`}>
                打烊時段：星期一 23:00 ~ 00:00
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      <ClubDetail
        open={clubDetailOpen}
        setOpen={setClubDetailOpen}
        data={[]}
        widthSize="md"
      />
    </Grid>
  );
};

export default PlatformInfo;
