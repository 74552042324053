import { IOnlineAnalysis } from "../../pages/report-management/member-report/online-analysis/type";

export const fakeOnlineAnalysis: IOnlineAnalysis[] = [
  {
    date: "2021-11-25",
    highestTime: "11~12",
    highestTimeTotal: 336,
    total: 3360,
  },
  {
    date: "2021-11-25",
    highestTime: "11~12",
    highestTimeTotal: 336,
    total: 3360,
  },
  {
    date: "2021-11-25",
    highestTime: "11~12",
    highestTimeTotal: 336,
    total: 3360,
  },
  {
    date: "2021-11-25",
    highestTime: "11~12",
    highestTimeTotal: 336,
    total: 3360,
  },
  {
    date: "2021-11-25",
    highestTime: "11~12",
    highestTimeTotal: 336,
    total: 3360,
  },
];

export const fakeOnlineChartData: any = [
  {
    name: "0",
    玩家人數: 4000,
    amt: 2400,
  },
  {
    name: "1",
    玩家人數: 3000,
    amt: 2210,
  },
  {
    name: "2",
    玩家人數: 2000,
    amt: 2290,
  },
  {
    name: "3",
    玩家人數: 2780,
    amt: 2000,
  },
  {
    name: "4",
    玩家人數: 1890,
    amt: 2181,
  },
  {
    name: "5",
    玩家人數: 2390,
    amt: 2500,
  },
  {
    name: "6",
    玩家人數: 4000,
    amt: 2400,
  },
  {
    name: "7",
    玩家人數: 3000,
    amt: 2210,
  },
  {
    name: "10",
    玩家人數: 2000,
    amt: 2290,
  },
  {
    name: "11",
    玩家人數: 2780,
    amt: 2000,
  },
  {
    name: "12",
    玩家人數: 5000,
    amt: 2181,
  },
  {
    name: "13",
    玩家人數: 2390,
    amt: 2500,
  },
  {
    name: "14",
    玩家人數: 4000,
    amt: 2400,
  },
  {
    name: "15",
    玩家人數: 3000,
    amt: 2210,
  },
  {
    name: "16",
    玩家人數: 2000,
    amt: 2290,
  },
  {
    name: "17",
    玩家人數: 2780,
    amt: 2000,
  },
  {
    name: "18",
    玩家人數: 1890,
    amt: 2181,
  },
  {
    name: "19",
    玩家人數: 2390,
    amt: 2500,
  },
  {
    name: "20",
    玩家人數: 4000,
    amt: 2400,
  },
  {
    name: "21",
    玩家人數: 3000,
    amt: 2210,
  },
  {
    name: "22",
    玩家人數: 2000,
    amt: 2290,
  },
  {
    name: "23",
    玩家人數: 2780,
    amt: 2000,
  },
  {
    name: "24",
    玩家人數: 1890,
    amt: 2181,
  },
];
