import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export interface IEvent {
  title: string;
  content: string;
  reward: string;
  startTime: string;
  endTime: string;
  createTime: string;
}

export interface IEventForm {
  title: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  title: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  downloadData: IEvent[];
}

export interface IFormProps {
  tempData: IEventForm;
  dialogType: string | null;
  setDialogType: React.Dispatch<React.SetStateAction<string | null>>;
  onCreate: (payload: IEventForm) => void;
  onEdit: (payload: IEventForm) => void;
}

export const sortableColumns = ["title", "startTime", "endTime", "createTime"];

export const formInitialValues: IEventForm = {
  title: "event name",
  startTime: moment(),
  endTime: moment(),
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  title: "",
});
