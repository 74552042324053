import moment from "moment";
import { IBasicFilter, ISelect } from "../../../../../../utils/common-type";
import {
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../../utils/helper";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
} from "../../../../../../utils/helper";

export interface IPointDetail {
  uid: string;
  agent: string;
  round: number;
  winLose: number;
  totalPromotionPoints: number;
  totalTransferPoints: number;
  totalContributionPoints: number;
  total: number;
}
export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  uid: string;
  agent: string;
}
export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IPointDetail[];
  filter: IFilter;
  selectionList: Record<string, string[]>;
  popup: boolean;
  // 格式特別所以獨立分開
  channelList?: ISelect[];
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    uid: filter.uid,
    agent: filter.agent,
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };

  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  zone: getCurrentTimeZone(),
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  uid: "",
  agent: "",
});
