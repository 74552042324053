import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { fakeMarquee } from "../../../fake-data/platform-management/marquee";
import { IAction, TemplateTable } from "../../../layout";
import Create from "./create";
import Edit from "./edit";
import Search from "./search";
import { defaultFilter, IFilter, IMarquee, sortableColumns } from "./type";
const Marquee = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IMarquee[]>(fakeMarquee);
  const [downloadData] = useState<any>(fakeMarquee);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IMarquee>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Marquee.${name}`);
  };

  const header: IDetailKey<IMarquee>[] = [
    { key: "content", name: T("content") },
    { key: "frequency", name: T("frequency") },
    { key: "frequencyDetail", name: T("frequencyDetail") },
    { key: "createTime", name: T("createTime") },
  ];

  const action: IAction<IMarquee> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IMarquee) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
  };

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await instance.request<CGReconciliationOptions>({
  //       url: api.CGReconciliationOptions.url(),
  //       method: api.CGReconciliationOptions.method,
  //     });
  //     if (!data) return;

  //     if (data.gameIDs && Object.keys(data.gameIDs).length) {
  //       setGameList(data.gameIDs);
  //     }

  //     if (data.gameInfos && Object.keys(data.gameInfos).length) {
  //       setGameInfos(data.gameInfos);
  //     }
  //   })();
  // }, []);

  return auth.login ? (
    <>
      {/* <Link
        to={{
          pathname: "/",
          search: JSON.stringify({
            test: "test",
          }),
        }}
      >
        test
      </Link> */}
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="sm"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="sm"
      ></Create>
      <TemplateTable<IMarquee, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default Marquee;
