export const getCSV = (json: any[]) => {
  console.log(json);
  let str = "";
  str = OBJtoCSV(json);
  const header = Object.keys(json[0]).reduce(
    (accumulate: string, current: string) => {
      return `${accumulate + current},`;
    },
    ""
  );
  return `${`${header}\r\n`}${str}`;
};

export const getJson = (json: any[]) => {
  return JSON.stringify(json);
};

export const getXML = (json: any[]) => {
  console.log(json);
  return `<?xml version="1.0" ?>\n<root>\n${OBJtoXML(json)}</root>\n`;
};

export const OBJtoCSV = (json: any[]) => {
  let str = "";
  Object.values(json).forEach((total: any) => {
    let line = "";
    Object.keys(json[0]).forEach((key: string) => {
      line += `${total[key]},`;
    });
    if (total.children) {
      line += "\r\n";
      line += OBJtoCSV(total.children);
    }
    line += "\r\n";
    str += line;
  });
  return str;
};

export const OBJtoXML = (obj: any, tab = 1) => {
  let xml = obj instanceof Array ? "" : "<reconciliation>\n";
  Object.keys(obj).forEach((prop) => {
    console.log(prop);
    // console.log(prop, obj[prop], typeof obj[prop])
    xml +=
      obj[prop] instanceof Array
        ? ""
        : `\n${"\t".repeat(tab)}<${prop.replace(/ /g, "")}>\n`;
    if (obj[prop] instanceof Array) {
      xml += `\n${"\t".repeat(tab)}<detail>\n`;
      obj[prop].forEach((array: any) => {
        xml += `${"\t".repeat(tab + 1)}<detail>\n`;
        xml += OBJtoXML(array, tab + 2);
        xml += `\n${"\t".repeat(tab + 1)}</detail>\n`;
      });

      xml += `\n${"\t".repeat(tab)}</detail>\n`;
    } else if (typeof obj[prop] === "object") {
      xml += "\t".repeat(tab + 1) + OBJtoXML(obj[prop], tab + 1);
    } else {
      xml += "\t".repeat(tab + 1) + obj[prop];
    }
    xml +=
      obj[prop] instanceof Array
        ? ""
        : `\n${"\t".repeat(tab)}</${prop.replace(/ /g, "")}>\n`;
  });
  xml += obj instanceof Array ? "" : "</reconciliation>\n";

  return xml.replace(/<\/?[0-9]{1,}>/g, "");
};

export const download = (
  data: any,
  startTime: string,
  endTime: string,
  page: string,
  mode: string
) => {
  let file: any;
  switch (mode) {
    case "json":
      file = getJson(data);
      break;
    case "csv":
      file = getCSV(data);
      break;
    case "xml":
      file = getXML(data);
      break;
    default:
      break;
  }
  const url = window.URL.createObjectURL(new Blob([`\ufeff${file}`]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${startTime} - ${endTime} - ${page}.${mode}`);
  document.body.appendChild(link);
  link.click();
};

export const download2 = (
  data: any,
  filetype: "csv" | "json" | "xml",
  filename: string
) => {
  const file = ((data: any, filetype: string) => {
    switch (filetype) {
      case "json":
        return getJson(data);
      case "csv":
        return getCSV(data);
      case "xml":
        return getXML(data);
      default:
        return "";
    }
  })(data, filetype);
  const url = window.URL.createObjectURL(new Blob([`\ufeff${file}`]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};
