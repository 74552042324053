import moment from "moment";
import React from "react";
import {
  filterEmptyFiled,
  formatTimeFilter,
  getCurrentTimeZone,
} from "../../../../../utils/helper";

export interface ILevelAnalysis {
  level: number;
  playerCount: number;
}

export interface IFilter {
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ILevelAnalysis[];
}

export const sortableColumns = [];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
});
