import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { useBusinessStatisticStyle } from "../../../../assets/styles/business-statistic";
import GameData from "./game-data";
import PlatformInfo from "./platform-info";
import PlayerData from "./player-data";

const BusinessReport = () => {
  const classes = useBusinessStatisticStyle();
  const auth = useContext(IsAuthorization);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className={classes.root}>
        <Grid container className={classes.gridRow}>
          {
            // 上方整體資訊
          }
          <PlatformInfo />
          {
            // 遊戲數據
          }
          <Grid item container xs={12} className={classes.gridRow}>
            <GameData />
          </Grid>

          {
            // 玩家數據以及排行
          }
          <Grid item container xs={12} className={classes.gridRow}>
            <PlayerData />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BusinessReport;
