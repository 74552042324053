import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../utils/helper";

export interface IClub {
  id: string;
  name: string;
  ownerUID: string;
  ownerPlayerUID: string;
  ownerName: string;
  totalPoints: number;
  level: number;
  members: number;
  memberLimit: number;
  avatar: string;
  ownerEmail: string;
  ownerPhone: string;
  openGames: string[]; // TODO
  rules: any; // TODO
  diamonds: number;
  accDiamonds: number;
}

export const formInitialValues: IClub = {
  id: "club id",
  name: "club name",
  ownerUID: "owner uid",
  ownerPlayerUID: "owner player uid",
  ownerName: "owner name",
  totalPoints: 1000,
  level: 1,
  members: 95,
  memberLimit: 100,
  avatar: "",
  ownerEmail: "abc123@gmail.com",
  ownerPhone: "0912345678",
  openGames: [],
  rules: [],
  diamonds: 100,
  accDiamonds: 1000,
};

export const sortableColumns = [
  "id",
  "totalPoints",
  "level",
  "credit",
  "members",
  "memberLimit",
];

export interface IFilter extends IBasicFilter {
  id: string;
  time: moment.Moment[];
  zone: string;
  club: string[];
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IClub[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    id: filter.id,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  id: "",
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  club: [],
});
