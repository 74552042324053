import React from "react";
import { IBasicFilter, IGameInfo } from "../../../../../../utils/common-type";
import { filterEmptyFiled, pageSizeList } from "../../../../../../utils/helper";
import { ITransaction } from "../../lobby-electronic-game-record/type";

export interface IGameDetail {
  list: number[];
  rake: number;
  roomFee: number;
}

export interface IFilter extends IBasicFilter {
  gameDetailsIDs?: string;
}

export interface ISearchProps {
  filter: IFilter;
  fixedFilter?: Partial<IFilter>;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: ITransaction[];
  gameInfos: IGameInfo[];
}

export const sortableColumns = ["time"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    clubsID: 0,
    pageSize: filter.pageSize,
    page: filter.pages,
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
