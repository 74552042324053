import { IPointDetail } from "../../pages/club-service/points-detail/type";

export const fakePointDetail: IPointDetail[] = [
  {
    uid: "UID1",
    agent: "UID1",
    round: 50,
    winLose: -51.75,
    totalPromotionPoints: 100,
    totalTransferPoints: 0,
    totalContributionPoints: 0,
    total: 48.25,
  },
  {
    uid: "UID2",
    agent: "UID1",
    round: 50,
    winLose: -51.75,
    totalPromotionPoints: 100,
    totalTransferPoints: 0,
    totalContributionPoints: 0,
    total: 48.25,
  },
  {
    uid: "UID3",
    agent: "UID2",
    round: 50,
    winLose: -51.75,
    totalPromotionPoints: 100,
    totalTransferPoints: 0,
    totalContributionPoints: 0,
    total: 48.25,
  },
];
