import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
interface IProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  widthSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  onClose?: () => void;
}
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  content: {
    backgroundColor: "white",
  },
}));

const CustomDialog = ({
  children,
  open,
  setOpen,
  title,
  widthSize = "sm",
  onClose,
}: IProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose ? onClose : () => setOpen(false)}
      fullWidth
      maxWidth={widthSize}
    >
      <DialogTitle>
        {title}
        <IconButton
          className={classes.closeButton}
          onClick={onClose ? onClose : () => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
