import {
  IconButton,
  Tooltip as IconToolTip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Table, TableColumnsType, TreeSelect } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { useBusinessStatisticStyle } from "../../../../../../assets/styles/business-statistic";
import { getTreeData, mapEnumToList } from "../../../../../../utils/helper";
import LoadingSpin from "../../../../../common/loading-spin";
import { fakeDashboardWarningMessage } from "../../../../../fake-data/risk-control/dashboard";
import {
  IWarningMessage,
  WarningMessageStatusEnum,
} from "../../../warning-message/type";
import WarningMessageEdit from "./edit";
interface Props {}

// eslint-disable-next-line @typescript-eslint/naming-convention
const WarningMessage: React.FC<Props> = () => {
  const classes = useBusinessStatisticStyle();
  const [loading, setLoading] = useState(false);
  const [isWarningMessageEditOpen, setIsWarningMessageEditOpen] =
    useState(false);
  const [detail, setDetail] = useState<IWarningMessage>();
  const [selectedWarningMessageStatus, setSelectedWarningMessageStatus] =
    useState(WarningMessageStatusEnum.未解決);
  const [redirectToWarningMessage, setRedirectToWarningMessage] =
    useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const { t } = useTranslation();
  const T = useCallback((name: string) => t(`BusinessReport.${name}`), [t]);

  const warningMessageColumn: TableColumnsType<IWarningMessage> = [
    {
      dataIndex: "riskConfig",
      title: t("WarningMessage.riskConfig"),
      align: "center",
    },
    {
      dataIndex: "message",
      title: t("WarningMessage.message"),
      align: "center",
    },
    {
      dataIndex: "time",
      title: t("common.time"),
      align: "center",
    },
    {
      dataIndex: "status",
      title: t("WarningMessage.status"),
      align: "center",
    },
    {
      dataIndex: "edit",
      title: t("common.edit"),
      align: "center",
      width: "10%",
      render: (data, record) => {
        return (
          <IconToolTip title={t("common.edit") as string}>
            <IconButton
              style={{ color: "inherit" }}
              onClick={() => {
                setDetail(record);
                setIsWarningMessageEditOpen(true);
              }}
            >
              <EditIcon style={{ color: "inherit" }} />
            </IconButton>
          </IconToolTip>
        );
      },
    },
  ];

  // fake loading
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <>
      <WarningMessageEdit
        open={isWarningMessageEditOpen}
        setOpen={setIsWarningMessageEditOpen}
        data={detail}
        widthSize="xs"
      ></WarningMessageEdit>
      <div
        className={classes.card}
        style={{
          width: isSingleMode ? "100%" : "66%",
          height: 440,
          position: "relative",
          backgroundColor: "#313131",
        }}
      >
        <LoadingSpin loading={loading} />
        {redirectToWarningMessage && <Redirect to="/Risk/WarningMessage" />}
        <IconToolTip title={T("moreWarningMessageDetail")}>
          <IconButton
            style={{ position: "absolute", right: 10, top: 10 }}
            onClick={() => {
              setRedirectToWarningMessage(true);
            }}
          >
            <OpenInNewIcon style={{ color: "white" }} />
          </IconButton>
        </IconToolTip>

        <div style={{ height: 50, fontSize: 24, margin: 10, display: "flex" }}>
          <div className={classes.center} style={{ color: "white" }}>
            警示訊息
          </div>
          <div style={{ marginLeft: 10 }}>
            <TreeSelect
              // style={{ minWidth: 200 }}
              value={selectedWarningMessageStatus}
              multiple={false}
              treeData={getTreeData(
                mapEnumToList(WarningMessageStatusEnum),
                false,
                T
              )}
              onChange={(v) => {
                setSelectedWarningMessageStatus(v);
              }}
            />
          </div>
        </div>
        <Table
          dataSource={fakeDashboardWarningMessage}
          loading={false}
          key="warning-message-table"
          pagination={false}
          columns={warningMessageColumn}
          scroll={{ y: 290 }}
          style={{ height: 290 }}
          size="middle"
          className="dashboard-warning-message-table"
          rowClassName={(record) =>
            record.status === WarningMessageStatusEnum.未解決
              ? classes.red
              : classes.green
          }
          rowKey={(record) => record.id}
        />
      </div>
    </>
  );
};

export default WarningMessage;
