import { CheckCircleOutlineOutlined, CloseOutlined } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { IAction, TemplateTable } from "../../../layout";
import { defaultFilter, IFilter, IJoinRequest, sortableColumns } from "./type";

const JoinRequest = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IJoinRequest[]>([
    {
      UID: "UID",
      createTime: "2021-10-22T00:00:00+08:00",
      message: "please let me in",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => t(`joinRequest.${name}`);

  const header: IDetailKey<IJoinRequest>[] = [
    { key: "UID", name: T("UID") },
    { key: "createTime", name: T("createTime") },
    { key: "message", name: T("message") },
  ];
  const action: IAction<IJoinRequest> = {
    create: false,
    del: false,
    edit: true,
    custom: [
      {
        onEvent: (selectedData: IJoinRequest) => {
          console.log(selectedData);
        },
        name: "Accept",
        actionIcon: <CheckCircleOutlineOutlined />,
        position: "IN_ROW",
      },
      {
        onEvent: (selectedData: IJoinRequest) => {
          console.log(selectedData);
        },
        name: "Reject",
        actionIcon: <CloseOutlined />,
        position: "IN_ROW",
      },
    ],
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <br />
      <TemplateTable<IJoinRequest, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default JoinRequest;
