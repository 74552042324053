import ViewListIcon from "@material-ui/icons/ViewList";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { api } from "../../../../environment";
import { instance } from "../../../../environment/axios";
import {
  IDetailKey,
  IGameInfo,
  IPagedResponse,
} from "../../../../utils/common-type";
import {
  mapGameLang,
  pageSizeList,
  unAuthorizationHandle,
} from "../../../../utils/helper";
import { fakeGame } from "../../../fake-data/platform-management/game";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Create from "./create";
import Edit from "./edit";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  IGame,
  sortableColumns,
} from "./type";

const GameManagement = () => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<IGame[]>(fakeGame);
  const [downloadData] = useState<any>(fakeGame);
  const [gameInfos] = useState<IGameInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IGame>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const initialFlag = useRef(true);
  const { t, i18n } = useTranslation();
  const T = (name: string) => {
    return t(`GameManagement.${name}`);
  };

  const header: IDetailKey<IGame>[] = [
    {
      key: "gameID",
      name: T("gameID"),
      custom: (x: string) => mapGameLang(x, gameInfos, i18n.language),
    },
    {
      key: "name",
      name: T("name"),
      custom: (name: IGame["name"]) => {
        return name["zh_cht"];
      },
    },
    { key: "icon", name: T("icon") },
    { key: "tags", name: T("tags") },
    { key: "lobbyStatus", name: T("lobbyStatus") },
    { key: "clubStatus", name: T("clubStatus") },
    { key: "priority", name: T("priority"), isInt: true },
  ];

  const action: IAction<IGame> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IGame) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
    custom: [
      {
        actionIcon: <ViewListIcon />,
        name: "大廳遊戲排序",
        onEvent: (data: IGame) => {
          setDetail(data);
          setActionName("lobbyPriority");
          setIsActionOpen(true);
        },
        position: "TOP",
      },
      {
        actionIcon: <ViewListIcon />,
        name: "俱樂部遊戲排序",
        onEvent: (data: IGame) => {
          setDetail(data);
          setActionName("clubPriority");
          setIsActionOpen(true);
        },
        position: "TOP",
      },
    ],
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<IGame[]>>({
        method: api.getGame.method,
        url: api.getGame.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(data);
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onSearch();
  }, [onSearch]);

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="md"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="md"
      ></Create>
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize="md"
        action={actionName}
        allGames={data}
      />
      <TemplateTable<IGame, IFilter>
        action={action}
        data={data}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default GameManagement;
