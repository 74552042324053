import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";

export enum DateRangeEnum {
  "昨日" = "lastDay",
  "今日" = "today",
  "當月" = "month",
  "無" = "none",
}

export enum LobbyElectronicGameDataEnum {
  "rtp" = "rtp",
  "中獎率" = "winRate",
  "淨利" = "revenue",
  "中獎金額" = "winGold",
  "下注次數" = "betTimes",
  "中JP次數" = "jackpot",
  "玩家人數" = "playerCount",
}

export enum LobbyCompetitiveGameDataEnum {
  "開房量" = "rooms",
  "鑽石消耗" = "diamonds",
  "金幣消耗" = "golds",
  "押注輸贏量" = "winLose",
  "抽水總回收量" = "goldRake",
  "玩家人數" = "playerCount",
}

export enum ClubElectronicGameDataEnum {
  "rtp" = "rtp",
  "中獎率" = "winRate",
  "淨利" = "revenue",
  "中獎金額" = "winGold",
  "下注次數" = "betTimes",
  "中JP次數" = "jackpot",
}

export enum ClubCompetitiveGameDataEnum {
  "開房量" = "rooms",
  "鑽石消耗" = "diamonds",
  "押注輸贏量" = "winLose",
  "抽水總回收量" = "pointRake",
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
}
