import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";

export enum MemberStatusEnum {
  online = "online",
  offline = "offline",
  locked = "locked",
}

export interface IMember {
  playerID: string;
  nickname: string;
  agent: string;
  point: number;
  credit: number;
  clubID: string;
  joinTime: string;
  lastLoginTime: string;
  status: MemberStatusEnum;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  playerID: string;
  status: string[];
  club: string[];
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  selectionList: Record<string, string[]>;
  downloadData: IMember[];
}

export const sortableColumns = [
  "playerID",
  "agent",
  "point",
  "credit",
  "joinTime",
  "lastLoginTime",
  "status",
];

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  playerID: "",
  status: [],
  club: [],
});

export const getMemberStatusColor = (status: MemberStatusEnum) => {
  switch (status) {
    case MemberStatusEnum.locked: {
      return "blue";
    }
    case MemberStatusEnum.offline: {
      return "red";
    }
    case MemberStatusEnum.online: {
      return "green";
    }
  }
};
