import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export enum SuggestionStatusEnum {
  "new" = "新案件",
  "assigned" = "已指派",
  "in progress" = "進行中",
  "finished" = "已結案",
  "closed" = "不處理",
}

export enum SuggestionTypeEnum {
  "login" = "登入",
  "storedValue" = "儲值",
  "game" = "遊戲",
  "other" = "其他",
}

export interface ISuggestion {
  suggestionID: string;
  type: string;
  playerID: string;
  status: SuggestionStatusEnum;
  priority: number;
  createdAt: string;
  assignedUser: string;
  content: string;
  replay: string;
  mark: string;
}

export const formInitialValues: ISuggestion = {
  suggestionID: "",
  type: "",
  playerID: "",
  status: SuggestionStatusEnum.new,
  priority: 0,
  createdAt: "2021-11-29 16:45:52",
  assignedUser: "無",
  content: "",
  replay: "",
  mark: "",
};

export const sortableColumns = [
  "type",
  "playerID",
  "status",
  "priority",
  "createdAt",
  "assignedUser",
];

export interface IFilter extends IBasicFilter {
  playerID: string[];
  type: string[];
  suggestionID: string;
  status: SuggestionStatusEnum[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: ISuggestion[];
  selectionList: Record<string, string[]>;
}

export const getPriorityColor = (priority: string | number) => {
  switch (Number(priority)) {
    case 5: {
      return "red";
    }
    case 4: {
      return "orange";
    }
    case 3: {
      return "yellow";
    }
    case 2: {
      return "blue";
    }
    case 1: {
      return "green";
    }
  }
};

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    playerID: filter.playerID.join(),
    status: filter.status.join(),
    suggestionID: filter.suggestionID,
    type: filter.type.join(),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  playerID: [],
  suggestionID: "",
  type: [],
  status: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
