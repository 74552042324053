import { Button, Grid, InputLabel } from "@material-ui/core";
import { FormikHelpers, FormikProps } from "formik";
import { TFunction } from "i18next";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { Translation } from "react-i18next";
import {
  download,
  FormButton,
  OneInput,
  TimePicker,
  ToggleButtons,
  TreeSelect,
} from "../component/common";
import { IRouterSetting } from "../routers";
import {
  IDetailKey,
  IFormItem,
  IGameInfo,
  IGameList,
  ITreeNodes,
} from "./common-type";

// 圖表顏色陣列
export const chartColors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#8884D8",
  "#99AF02",
  "#AFFFDC",
  "#EC487B",
];

// 時區陣列
export const timeZoneList = [
  "-11:00",
  "-10:00",
  "-09:00",
  "-08:00",
  "-07:00",
  "-06:00",
  "-05:00",
  "-04:00",
  "-03:00",
  "-02:00",
  "-01:00",
  "+00:00",
  "+01:00",
  "+02:00",
  "+03:00",
  "+04:00",
  "+05:00",
  "+06:00",
  "+07:00",
  "+08:00",
  "+09:00",
  "+10:00",
  "+11:00",
  "+12:00",
];

// 時間快捷鍵轉換 function
export const changeTimePicker = (
  type: string,
  setFieldValue: FormikHelpers<any>["setFieldValue"],
  name = "time"
) => {
  let min: moment.Moment = moment();
  let max: moment.Moment = moment();

  switch (type) {
    case "lastMonth": {
      min = moment().subtract(1, "month").startOf("month");
      max = moment().subtract(1, "month").endOf("month");
      break;
    }
    case "today": {
      min = moment().startOf("day");
      max = moment().endOf("days");
      break;
    }
    case "yesterday": {
      min = moment().subtract(1, "day").startOf("day");
      max = moment().subtract(1, "day").endOf("day");
      break;
    }
    case "thisWeek": {
      min = moment().startOf("week");
      max = moment().endOf("week");
      break;
    }
    case "lastWeek": {
      min = moment().subtract(1, "week").startOf("week");
      max = moment().subtract(1, "week").endOf("week");
      break;
    }
    case "crtMonth": {
      min = moment().startOf("month");
      max = moment().endOf("month");
      break;
    }
  }
  setFieldValue(name, [min, max]);
};

// 獲取現在時區
export const getCurrentTimeZone = () => {
  return moment.parseZone(moment().format()).format("Z");
};

// 撲克牌點數 mapping
export function cardsMapping(id: number): string {
  if (id < 1 || id > 54) {
    return "";
  }

  if (id < 14) {
    return "BU_Spade" + ("0" + id.toString()).slice(-2);
  } else if (id < 27) {
    id -= 13;
    return "BU_Heart" + ("0" + id.toString()).slice(-2);
  } else if (id < 40) {
    id -= 26;
    return "BU_Diamond" + ("0" + id.toString()).slice(-2);
  } else if (id < 53) {
    id -= 39;
    return "BU_Club" + ("0" + id.toString()).slice(-2);
  } else if (id === 53) {
    return "BU_JokerA";
  }

  return "BU_JokerB";
}

// 排型名稱
export const mapWinTypeName: any = {
  twopair: "兩對",
  threeofkind: "三條",
  straight: "順子",
  flush: "同花",
  fullhouse: "葫蘆",
  fourofkind: "鐵支",
  straightflush: "同花順",
  fiveofkind: "五條",
  royalflush: "同花大順",
  doubleup: "比倍",
  nowin: "輸",
  win: "贏",
};

// 檢查 data type
export function dataTypeCheck(data: any) {
  switch (typeof data) {
    case "number":
      return new Intl.NumberFormat("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
        .format(data)
        .toString();
    case "object":
    case "boolean":
      return JSON.stringify(data);
    case "function":
      return "function is error type";
    default:
      return data;
  }
}

// 將資料 map 成 download 格式
export const mapDownloadData = (data: any[], header: IDetailKey<any>[]) => {
  return data.map((d: any) => {
    const mappedData = {} as any;
    header.forEach((h) => {
      mappedData[h.name] = d[h.key];
    });
    return mappedData;
  });
};

// pageSize 陣列
export const pageSizeList = [10, 50, 100, 150];

//unAuth use
export const unAuthorizationHandle = (setLogin: any) => {
  setLogin(false);
  localStorage.removeItem("token");
  localStorage.removeItem("permission");
  // window.alert("連線逾時 請重新登入");
};

// 獲取頁面初始位置
export const getHomePath = (
  router: IRouterSetting[],
  pagePermission: string[] = []
) => {
  let home = getFirstPermissionPagePath(router, pagePermission);
  return home;
};

const getFirstPermissionPagePath = (
  nodes: IRouterSetting[],
  pagePermission: string[]
) => {
  let path = "";
  nodes.forEach((node) => {
    if (path === "") {
      if (
        node.children &&
        node.children.length > 0 &&
        pagePermission.find((p) => p.indexOf(node.path) !== -1)
      ) {
        path = `${getFirstPermissionPagePath(node.children, pagePermission)}`;
      } else if (pagePermission.find((p) => p === node.path)) {
        path = `/${node.path}`;
      }
    }
  });
  return path;
};

// 獲取 antd tree data 格式
export const getTreeData = (
  data: string[],
  multiple = true,
  t?: TFunction
): ITreeNodes[] => {
  const tree: ITreeNodes[] = data.map((d) => {
    return {
      title: t ? t(d) : d,
      value: d,
      key: d,
    };
  });
  if (multiple && data.length !== 0) {
    return [
      {
        title: <Translation>{(globalT) => globalT("common.all")}</Translation>,
        value: "all",
        key: "all",
        children: [...tree],
      },
    ];
  }
  return tree;
};

// // map game lang
export const mapGameLang = (
  gameID: string,
  gameInfos: IGameInfo[],
  currentLang: string
) => {
  if (gameInfos && gameInfos.find((g) => g.gameID === gameID)) {
    const index = gameInfos.findIndex((g) => g.gameID === gameID);
    return (
      gameInfos[index].name[currentLang] ||
      gameInfos[index].name["en"] ||
      gameID
    );
  }
  return gameID;
};

// format order 指定格式
export const formatOrder = (order?: string, orderBy?: string) => {
  return order && orderBy ? `${camelToSnakeCase(order)} ${orderBy}` : undefined;
};

// formatTimeFilter
export const formatTimeFilter = (time: moment.Moment, timezone: string) => {
  return time
    ? moment(time).format("YYYY-MM-DD[T]HH:mm:ss") + timezone
    : undefined;
};

// formatTimeForDisplay
export const formatTimeForDisplay = (time: string, timezone: number) => {
  let t = moment(time).utc();
  t =
    timezone >= 0
      ? t.add(timezone, "hours")
      : t.subtract(-1 * timezone, "hours");
  return t.format("YYYY-MM-DD HH:mm:ss");
};

// format amount
export const formatAmount = (
  x: number,
  precision: number,
  useDashForZero = false
) => (useDashForZero && x === 0 ? "-" : x.toFixed(precision));

// denom
export const denom = (
  x: number,
  denominator: number,
  precision: number,
  useDashForZero = false
) =>
  useDashForZero && x === 0 ? "-" : formatAmount(x / denominator, precision);

// format rtp
export const formatRtp = (x: number) => (x * 100).toFixed(2) + "%";

// format rtp for display
export const formatRtpForDisplay = (data: number) => (
  <Fragment>{(data * 100).toFixed(2)}%</Fragment>
);

// format wallet for display
export const formatWalletAmountForDisplay = (data: number) => {
  data = data / 1e4;
  return <span>{data}</span>;
};

// 表格顏色依照 P/L 變化
export const rowStyleByProfitLoss = (
  row: Required<{ profitLoss: number }>
) => ({
  backgroundColor: row.profitLoss > 0 ? "#D4FFD4" : "#FFD4D4",
});

// 表格顏色依照 Status 變化
export const rowStyleByStatus = (row: Required<{ status: number }>) => ({
  backgroundColor: row.status === 1 ? "#D4FFD4" : "#FFD4D4",
});

// 表格顏色依照 wallet 變化
export const rowStyleByWallet = (
  row: Required<{ action: number | string }>
) => ({
  backgroundColor:
    (typeof row.action === "string" ? parseInt(row.action) : row.action) === 1
      ? "#D4FFD4"
      : "#FFD4D4",
});

export const getGameType = (gameID: string, gameList: IGameList) => {
  let gameType = "unknown";
  if (gameID && gameList) {
    Object.entries(gameList).forEach(([type, values]) => {
      if (values.find((v) => v === gameID)) {
        gameType = type;
      }
    });
  }
  return gameType;
};

// 不同平台不同的翻譯
export function getWinType(
  winType: string,
  gameId: string,
  isDoubleUp = false,
  doubleUpResult = 0
) {
  if (winType === "royalflush") {
    switch (gameId) {
      case "jokerpk":
      case "multijokerpk": {
        return "royalflush-multijokerpk";
      }
      case "deucepk": {
        return "royalflush-deucepk";
      }
      case "jackorbetter": {
        return "royalflush-jackorbetter";
      }
      default: {
        return winType;
      }
    }
  } else if (isDoubleUp) {
    let resultString = "";
    if (doubleUpResult > 0) {
      resultString = `doubleupWin`;
    } else if (doubleUpResult === 0) {
      resultString = `doubleupTie`;
    } else if (doubleUpResult < 0) {
      resultString = `doubleupLose`;
    }
    return resultString;
  }
  return winType;
}

// detail title
export const getDetailTitle = (
  transactionID: string,
  roundID: string | number,
  T: (name: string) => string
) => {
  return (
    <span>
      <span style={{ marginRight: "10em" }}>{`${T(
        "Transaction ID"
      )}: ${transactionID}`}</span>
      <span>{`${T("Round ID")}: ${roundID}`}</span>
    </span>
  );
};

// filterEmptyFiled
export const filterEmptyFiled = (params: any) => {
  Object.entries(params).forEach(([key, value]) => {
    if (
      (value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        value === undefined) &&
      key !== "pages" &&
      key !== "page"
    ) {
      params[key] = undefined;
    }
  });
  return params;
};

// get antd tree data
export const getGameTreeData = (
  gameInfos: IGameInfo[],
  currentLang: string,
  multiple = true
) => {
  const tree: ITreeNodes[] = gameInfos.map((g) => {
    return {
      title: g.name[currentLang] || g.name["en"] || g.gameID,
      value: g.gameID,
      key: g.gameID,
    };
  });
  if (tree.length && multiple) {
    return [
      {
        title: <Translation>{(globalT) => globalT("common.all")}</Translation>,
        value: "all",
        key: "all",
        children: [...tree],
      },
    ];
  }

  return tree;
};

/**
 *
 * @param form 表單控制物件
 * @param formItems 表單內容
 * @param defaultFilter 初始表單狀態
 * @param t 翻譯 function
 * @param downloadData 下載資料
 * @param downloadName 下載檔案名稱
 * @param downloadable 可下載
 * @returns
 */
export const generateSearchFormItem = (
  form: FormikProps<any>,
  formItems: IFormItem[],
  defaultFilter: any,
  t: TFunction,
  downloadData?: any,
  downloadName = "",
  downloadable = false
) => {
  let formElements: JSX.Element[];
  const { values, isSubmitting, resetForm, submitForm } = form;
  formElements = getFormElement(form, formItems, defaultFilter, t);
  return (
    <>
      <Grid container spacing={1}>
        {formElements}
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          {downloadable && (
            <>
              <InputLabel style={{ paddingTop: 10 }} shrink={true}>
                {t("common.Download")}
              </InputLabel>
              <Button
                color="secondary"
                disabled={!downloadData.length}
                onClick={() =>
                  download(
                    downloadData,
                    values.time[0].toISOString(),
                    values.time[1].toISOString(),
                    downloadName,
                    "csv"
                  )
                }
                variant="outlined"
              >
                csv
              </Button>
            </>
          )}
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            paddingTop: 10,
          }}
        >
          <FormButton
            disable={isSubmitting}
            cancelEvent={() => {
              resetForm({ values: cloneDeep(defaultFilter) });
            }}
            okEvent={submitForm}
          />
        </Grid>
      </Grid>
    </>
  );
};

/**
 *
 * @param form 表單控制物件
 * @param formItems 表單內容
 * @param defaultFilter 初始表單狀態
 * @param t 翻譯 function
 * @returns
 */
export const generateFormItem = (
  form: FormikProps<any>,
  formItems: IFormItem[],
  defaultFilter: any,
  t: TFunction
) => {
  let formElements: JSX.Element[];
  const { isSubmitting, resetForm, submitForm } = form;
  formElements = getFormElement(form, formItems, defaultFilter, t);
  return (
    <>
      <Grid container spacing={1} justify="flex-start">
        {formElements}
      </Grid>
      <Grid container justify="center">
        <Grid
          item
          container
          xs={12}
          justify="flex-end"
          style={{
            paddingTop: 10,
          }}
        >
          <FormButton
            disable={isSubmitting}
            cancelEvent={() => {
              resetForm({ values: cloneDeep(defaultFilter) });
            }}
            okEvent={submitForm}
          />
        </Grid>
      </Grid>
    </>
  );
};

const getFormElement = (
  form: FormikProps<any>,
  formItems: IFormItem[],
  defaultFilter: any,
  t: TFunction
) => {
  let formElements: JSX.Element[];
  const { values, setFieldValue } = form;
  formElements = formItems
    .filter((item) => !item.hidden)
    .map((item, index) => {
      const { quickTimeSelection = true, ...oneInputProps } = item;
      switch (item.type) {
        case "content":
          oneInputProps.style = { marginTop: 10, ...oneInputProps.style };
        case "number":
        case "text":
        case "password":
          return (
            <OneInput
              key={item.label}
              {...oneInputProps}
              label={t(item.label)}
            />
          );
        case "singleTime":
          return (
            <Fragment key={`SingleTime${index}`}>
              <TimePicker
                {...oneInputProps}
                label={t(item.label)}
                type="time"
                value={moment(values[item.name])}
                onChange={(e) => {
                  setFieldValue(item.name, e);
                }}
                format={item.format}
              />
            </Fragment>
          );
        case "time":
          return (
            <Fragment key={`formTime${index}`}>
              {quickTimeSelection && (
                <ToggleButtons
                  exclusive
                  onChange={(event, newSelectedBtn) => {
                    if (newSelectedBtn) {
                      changeTimePicker(
                        newSelectedBtn,
                        setFieldValue,
                        item.name
                      );
                    }
                  }}
                  buttons={[
                    "today",
                    "yesterday",
                    "thisWeek",
                    "lastWeek",
                    "crtMonth",
                    "lastMonth",
                  ]}
                  ariaLabel="text alignment"
                />
              )}
              <TimePicker
                {...oneInputProps}
                type="time"
                label={t(item.label)}
                value={values[item.name]}
                range
                {...(item.onChange
                  ? {
                      onChange: item.onChange,
                    }
                  : {
                      onChange: (e) => {
                        setFieldValue(item.name, e);
                      },
                    })}
              />
              <TreeSelect
                label={"時區"}
                name="zone"
                value={values.zone}
                multiple={false}
                treeData={getTreeData(timeZoneList, false)}
                onChange={(data) => {
                  setFieldValue("zone", data);
                }}
                size={{ xs: 12, sm: 3 }}
              />
            </Fragment>
          );
        case "singleSelect":
          return (
            <Fragment key={`singleSelect${index}`}>
              <TreeSelect
                {...item}
                {...(item.onChange
                  ? {
                      onChange: item.onChange,
                    }
                  : {
                      onChange: (e) => {
                        setFieldValue(item.name, e);
                      },
                    })}
                value={values[item.name]}
                label={t(item.label)}
              />
            </Fragment>
          );
        case "multiSelect":
          return (
            <Fragment key={`multiSelect${index}`}>
              <TreeSelect
                {...item}
                {...(item.onChange
                  ? {
                      onChange: item.onChange,
                    }
                  : {
                      onChange: (e) => {
                        setFieldValue(item.name, e);
                      },
                    })}
                value={values[item.name]}
                label={t(item.label)}
                multiple
              />
            </Fragment>
          );
        case "custom":
          return item.render ?? <></>;
        default:
          return <></>;
      }
    });
  return formElements;
};

export function mapEnumToList(enumList: { [s: number]: string }) {
  return Object.values(enumList);
}

export const getPagePathList = (routers: IRouterSetting[]) => {
  return routers.reduce((pre: string[], current: IRouterSetting) => {
    if (current.children) {
      pre = [...pre, ...getPagePathList(current.children)];
    } else {
      pre = [...pre, current.path];
    }
    return pre;
  }, []);
};

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]D?/g, (letter) => `_${letter.toLowerCase()}`);
