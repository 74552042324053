import { Card } from "antd";
import React from "react";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import CountUp from "react-countup";
interface Props {
  title: string | JSX.Element;
  number: number;
  loading: boolean;
  style?: React.CSSProperties;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const DataInfo: React.FC<Props> = ({
  title,
  number,
  loading = false,
  style = {},
}) => {
  const classes = useBusinessStatisticStyle();
  return (
    <Card
      loading={loading}
      className={`${classes.gridItem}`}
      style={{ flex: 1, ...style }}
      bodyStyle={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
      }}
    >
      <CountUp
        end={number}
        duration={3}
        useEasing={true}
        className={classes.center}
        style={{ fontSize: "2.5em", fontWeight: "bold" }}
      />
      <div
        style={{ fontSize: 16, fontWeight: "bold" }}
        className={classes.center}
      >
        {title}
      </div>
    </Card>
  );
};

export default DataInfo;
