import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import StoredValue from "./stored-value";
import StoredValueRecord from "./stored-value-record";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`ClubManagement.${props.action}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "storedValue":
        return <StoredValue />;
      case "storedValueRecord":
        return <StoredValueRecord />;
      default:
        return "Action Not Found";
    }
  };

  return (
    <CustomDialog
      title={`${T(props.action)} ${
        props.data?.id ? `id: ${props.data?.id}` : ""
      }`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
