import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { LinkText } from "../../../common";
import { fakeMail } from "../../../fake-data/platform-management/mail";
import { IAction, TemplateTable } from "../../../layout";
import Create from "./create";
import Detail from "./detail";
import Edit from "./edit";
import Search from "./search";
import { defaultFilter, IFilter, IMail, sortableColumns } from "./type";
const Mail = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IMail[]>(fakeMail);
  const [downloadData] = useState<any>(fakeMail);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IMail>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Mail.${name}`);
  };

  const header: IDetailKey<IMail>[] = [
    { key: "title", name: T("title") },
    { key: "content", name: T("content") },
    { key: "gold", name: T("gold") },
    { key: "diamond", name: T("diamond") },
    { key: "mailAction", name: T("mailAction") },
    { key: "startTime", name: T("startTime") },
    { key: "endTime", name: T("endTime") },
    { key: "status", name: T("status") },
    {
      key: "readAccounts",
      name: T("readStatus"),
      custom: (playerID: string, source: IMail) => {
        if (source.readAccounts + source.unreadAccounts > 20) {
          return `${source.readAccounts}/${
            source.readAccounts + source.unreadAccounts
          }`;
        }
        return (
          <LinkText
            onClick={() => {
              setIsDetailOpen(true);
              setDetail(source);
            }}
          >
            {`${source.readAccounts}/${
              source.readAccounts + source.unreadAccounts
            }`}
          </LinkText>
        );
      },
    },
  ];

  const action: IAction<IMail> = {
    create: true,
    del: false,
    edit: true,
    onEdit: (data: IMail) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onCreate: () => {
      setIsCreateOpen(true);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
    conditions: {
      read: (source) => source.readAccounts + source.unreadAccounts < 20,
    },
  };

  return auth.login ? (
    <>
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <Detail
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        widthSize="md"
      ></Detail>
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="md"
      ></Edit>
      <Create
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        widthSize="md"
      ></Create>
      <TemplateTable<IMail, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default Mail;
