import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { IsAuthorization } from "../../app";
import { IRouterSetting } from "../../routers";
import Bread from "./bread";
const PageRouter = () => {
  const { routerWithPermission } = useContext(IsAuthorization);

  const getPages = (nodes: IRouterSetting[]) => {
    const pages: any[] = [];
    nodes.forEach((node: IRouterSetting) => {
      if (node.visible) {
        if (node.children && node.children.length !== 0) {
          const children = getPages(node.children);
          if (children.length !== 0) {
            pages.push(children);
          }
        } else {
          pages.push(
            <Route
              hidden={
                !node.visible
              }
              key={`/${node.path}}`}
              path={`/${node.path}`}
              render={() => {
                return (
                  <>
                    <Bread route={`${node.path}`} />
                    {node.component && node.component}
                  </>
                );
              }}
            />
          );
        }
      }
    });
    return pages;
  };

  return <>{getPages(routerWithPermission)}</>;
};

export default PageRouter;
