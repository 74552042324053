import { IBasicFilter } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";

export interface IGameRiskConfig {
  gameID: string;
  type: string;
  repeatFreeSlotCount: number;
  bigWin: number;
  bigWinRate: number;
  jackpot: number;
  rtp: number;
  netProfit: number;
}

export const formInitialValues: IGameRiskConfig = {
  gameID: "",
  type: "game type",
  repeatFreeSlotCount: 10,
  bigWin: 1000000,
  bigWinRate: 10,
  jackpot: 1000000,
  rtp: 92,
  netProfit: 100000000,
};

export interface IFilter extends IBasicFilter {}

export const defaultFilter: () => IBasicFilter = () => ({
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});

export const sortableColumns = [];

export interface IRiskConfigDescription {
  id: string;
  config: string;
  default: number;
  description: string;
}

export interface IRiskOtherConfigDescription {
  id: string;
  config: string;
  current: number;
  default: number;
  description: string;
}
