import { Grid, useMediaQuery } from "@material-ui/core";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useBusinessStatisticStyle } from "../../../../../assets/styles/business-statistic";
import { chartColors, dataTypeCheck } from "../../../../../utils/helper";

interface Props {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  chartData: { [key: string]: any }[];
  loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const CustomPieChart: React.FC<Props> = ({
  title,
  subTitle,
  chartData,
  loading = false,
}) => {
  const classes = useBusinessStatisticStyle();
  const { t } = useTranslation();
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );

  const total = chartData
    .map<number>((data) => data.value)
    .reduce((preValue, curValue) => preValue + curValue);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const valueLabel = `${chartData[index].value}人`;
    const percentLabel = `（${(percent * 100).toFixed(0)}%）`;

    return (
      <text
        x={x}
        y={y}
        fill="white"
        // textAnchor={x > cx ? "start" : "end"}
        textAnchor={"middle"}
        dominantBaseline="central"
      >
        <tspan x={x} dy="-0.5em">
          {valueLabel}
        </tspan>
        <tspan x={x} dy="1.2em">
          {percentLabel}
        </tspan>
      </text>
    );
  };

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  return (
    <Grid
      container
      xs={12}
      item
      className={`${classes.gridItem} ${
        isSingleMode && classes.singleModeResetWidth
      }`}
      style={{ position: "relative" }}
    >
      <Card
        loading={loading}
        style={{ flex: 1 }}
        bodyStyle={{ height: "100%" }}
      >
        <Grid>
          <div style={{ fontSize: 16, margin: 15, fontWeight: "bold" }}>
            {title}
          </div>
          <div style={{ fontSize: 14, margin: 15 }}>{subTitle}</div>
        </Grid>
        <Grid item xs={12} style={{ position: "relative", minHeight: 250 }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              bottom: 0,
            }}
          >
            <ResponsiveContainer width="100%">
              <PieChart>
                <Legend
                  formatter={(name: string) => `${t(`BusinessReport.${name}`)}`}
                />
                <Tooltip
                  formatter={(value: any, name: string) => [
                    `${value}${t(`Unit.${name}`)}（${dataTypeCheck(
                      (value / total) * 100
                    )}%）`,
                    t(`BusinessReport.${name}`),
                  ]}
                />
                <Pie
                  labelLine={false}
                  label={renderCustomizedLabel}
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  outerRadius={"70%"}
                  fill="#8884d8"
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={index + entry.name}
                      fill={chartColors[index % chartColors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Card>
    </Grid>
  );
};

export default CustomPieChart;
