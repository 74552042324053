import {
  IGameRiskConfig,
  IRiskConfigDescription,
  IRiskOtherConfigDescription,
} from "../../pages/risk-control/risk-config/type";

export const fakeRiskConfig: IGameRiskConfig[] = [
  {
    gameID: "Default",
    type: "electronic",
    repeatFreeSlotCount: 10,
    bigWin: 1000000,
    bigWinRate: 10,
    jackpot: 1000000,
    rtp: 92,
    netProfit: 100000000,
  },
  {
    gameID: "Game1",
    type: "electronic",
    repeatFreeSlotCount: 10,
    bigWin: 1000000,
    bigWinRate: 10,
    jackpot: 1000000,
    rtp: 92,
    netProfit: 100000000,
  },
  {
    gameID: "Game2",
    type: "electronic",
    repeatFreeSlotCount: 10,
    bigWin: 1000000,
    bigWinRate: 10,
    jackpot: 1000000,
    rtp: 92,
    netProfit: 100000000,
  },
  {
    gameID: "Game3",
    type: "electronic",
    repeatFreeSlotCount: 10,
    bigWin: 1000000,
    bigWinRate: 10,
    jackpot: 1000000,
    rtp: 92,
    netProfit: 100000000,
  },
  {
    gameID: "Game4",
    type: "electronic",
    repeatFreeSlotCount: 10,
    bigWin: 1000000,
    bigWinRate: 10,
    jackpot: 1000000,
    rtp: 92,
    netProfit: 100000000,
  },
];

export const fakeRiskConfigDescription: IRiskConfigDescription[] = [
  {
    id: "AW658416584",
    config: "開大獎金額提醒",
    default: 10,
    description:
      "進了 FreeSlot 後，又贏 {value} 次 RepeatFreeSlot 時會發會警告",
  },
  {
    id: "AW648416584",
    config: "開大獎倍率提醒",
    default: 1000000,
    description: "贏 > {value} 時會發會警告",
  },
  {
    id: "AW655576584",
    config: "開獎金額提醒",
    default: 1000000,
    description: "有進彩金且贏 > {value} 時會發會警告",
  },
  {
    id: "AW651236584",
    config: "RTP數值提醒",
    default: 92,
    description:
      "在任意時段、任意遊戲的 rtp > {各階段數字} 時會發會警告，每階段會提醒三次",
  },
  {
    id: "AW658786584",
    config: "總營利提醒",
    default: 100000000,
    description:
      "在任意時段、任意遊戲的總營利 < {各階段數字} 時會發會警告，每階段會提醒三次",
  },
  {
    id: "AW658414324",
    config: "玩家單日中獎金額提醒",
    default: 10000,
    description: "當日玩家中獎金額 > {value} 時會發警告",
  },
];

export const fakeOtherRiskConfigDescription: IRiskOtherConfigDescription[] = [
  {
    id: "AW655316584",
    config: "玩家單日中獎金額提醒",
    current: 8900,
    default: 10000,
    description: "當日玩家中獎金額 > {value} 時會發警告",
  },
];
