import { InputLabel, makeStyles } from "@material-ui/core";
import { Tag, TreeSelect } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../../app";
import { getTreeData } from "../../../../../../utils/helper";
import { FormButton } from "../../../../../common";
import { IGame } from "../../type";

interface IClubPriorityProps {
  allGames: IGame[];
}

const useStyle = makeStyles(() => ({
  allGameWrapper: {
    overflowX: "auto",
    display: "flex",
    // justifyContent: "center"
  },
  gameWrapper: {
    minHeight: "10vh",
    minWidth: "20%",
    margin: "2.5%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    fontSize: 22,
    padding: 5,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ClubPriority = ({ allGames = [] }: IClubPriorityProps) => {
  const auth = useContext(IsAuthorization);
  const { t } = useTranslation();
  const classes = useStyle();

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className={classes.allGameWrapper}>
        {allGames.map((game, index) => {
          return (
            <div className={classes.gameWrapper} key={`${game}${index}`}>
              <div className={classes.center}>
                <Tag color="#3C7082" className={classes.title}>
                  {index}
                </Tag>
              </div>

              <InputLabel
                shrink={true}
                // style={{ color: error ? "red" : "initial" }}
              >
                {t("GameManagement.gameID")}
              </InputLabel>
              <TreeSelect
                value={game.gameID}
                treeData={getTreeData(
                  allGames.map((g) => g.gameID),
                  false
                )}
              />
            </div>
          );
        })}
      </div>
      <div style={{ float: "right", marginTop: 10 }}>
        <FormButton cancelEvent={() => {}} disable={false} okEvent={() => {}} />
      </div>
    </>
  );
};

export default ClubPriority;
