import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps, IFormItem } from "../../../../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
} from "../../../../../../../utils/helper";
import { CustomDialog } from "../../../../../../common";
import { formInitialValues, Games, IActiveGames, Rules } from "./type";

const Edit = (props: IEditProps<IActiveGames>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`ActiveGames.${name}`);
  };

  const getRepeatSelection = (): IFormItem[] => {
    return [
      {
        type: "singleSelect",
        name: "gameID",
        label: "gameID",
        treeData: getTreeData(Games, false),
        size: { xs: 12 },
      },
      {
        type: "singleSelect",
        name: "ruleID",
        label: "ruleID",
        treeData: getTreeData(Rules, false),
        size: { xs: 12 },
      },
      {
        type: "text",
        name: "rake",
        label: "rake",
      },
    ];
  };

  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(form, getRepeatSelection(), formInitialValues, T)}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Edit;
