import { ContentState, convertFromHTML } from "draft-js";
import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export enum MailAccountType {
  all = "全服",
  twenty = "等級20以下",
  fifty = "等級50以上",
  personal = "個人",
}

export interface IMail {
  playerID: string;
  accountType: string;
  title: string;
  content: string;
  gold: number;
  diamond: number;
  mailAction: string;
  status: string;
  readAccounts: number;
  unreadAccounts: number;
  startTime: string;
  endTime: string;
}

export interface IReadStatus {
  playerID: string;
  readStatus: string;
}

export const formInitialValues = {
  playerID: "",
  accountType: MailAccountType.all,
  title: "",
  content: "",
  status: "",
  gold: 0,
  diamond: 0,
  mailAction: "",
  startTime: moment().startOf("days"),
  endTime: moment().endOf("days"),
};

export const sortableColumns = [
  "playerID",
  "accountType",
  "title",
  "content",
  "status",
  "gold",
  "diamond",
  "mailAction",
  "startTime",
  "endTime",
];

export interface IFilter extends IBasicFilter {
  playerID: string;
  title: string;
  status: string[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IMail[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    playerID: filter.playerID,
    title: filter.title,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  playerID: "",
  title: "",
  status: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});

export const editorStateFromHTML = (content: string) => {
  const blocks = convertFromHTML(content);
  return ContentState.createFromBlockArray(
    blocks.contentBlocks,
    blocks.entityMap
  );
};
