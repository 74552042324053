import moment from "moment";
import React from "react";
import { IBasicFilter, IGameInfo } from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";
import { ISummary } from "../../../../layout";
import { IElectronicGameReportSummary } from "../lobby-electronic-game-report/type";

export interface IClubElectronicGameReport {
  gameID: string;
  clubsID: number;
  totalBet: number;
  totalWin: number;
  rtp: number;
  hitRate: number;
  betCount: number;
  playerCount: number;
  jackpot: {
    name: string;
    count: number;
  }[];
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  gameIDs: string[];
  clubsID?: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  gameInfos: IGameInfo[];
  searchSummary: Function;
  data: IClubElectronicGameReport[];
}

export const sortableColumns = [
  "gameID",
  "clubsID",
  "totalBet",
  "totalWin",
  "betCounts",
  "playerCount",
];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    gameIDs: filter.gameIDs.join(","),
    clubsID: filter.clubsID,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  gameIDs: [],
  clubsID: undefined,
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});

export const initialSummary: ISummary<IElectronicGameReportSummary>[] = [
  {
    key: "clubDAU",
    value: "-",
  },
  {
    key: "totalRevenue",
    value: "-",
  },
  {
    key: "avgRevenue",
    value: "-",
  },
  {
    key: "totalBetCount",
    value: "-",
    isInt: true,
  },
  {
    key: "avgBetCount",
    value: "-",
    isInt: true,
  },
  {
    key: "totalPlayerCount",
    value: "-",
    isInt: true,
  },
  {
    key: "avgPlayerCount",
    value: "-",
    isInt: true,
  },
];
