import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { fakeShopping } from "../../../fake-data/consume/consume-record";
import { IAction, TemplateTable } from "../../../layout";
import Detail from "./detail";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  IConsumeRecord,
  sortableColumns,
} from "./type";

const ConsumeRecord = () => {
  const auth = useContext(IsAuthorization);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [loading] = useState<boolean>(false);
  const [originData] = useState<IConsumeRecord[]>(fakeShopping);
  const [nextPageAvailable] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IConsumeRecord>();
  const [selectedProductName, setSelectedProductName] = useState("");
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`ConsumeRecord.${name}`);
  };

  const header: IDetailKey<IConsumeRecord>[] = [
    { key: "time", name: T("timestamp") },
    { key: "id", name: T("id") },
    { key: "playerID", name: T("playerID") },
    { key: "product", name: T("product") },
    { key: "type", name: T("type") },
    { key: "cost", name: T("cost") },
    { key: "unit", name: T("unit") },
  ];

  const action: IAction<IConsumeRecord> = {
    create: false,
    del: false,
    edit: false,
    read: true,
    onRead: (data) => {
      setIsDetailOpen(true);
      setSelectedProductName(`${data.id} ${T("detailTitle")}`);
      setSelectedProduct(data);
    },
  };

  return auth.login ? (
    <>
      <Search filter={filter} setFilter={setFilter} selectionList={{}} />
      <Detail
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        widthSize="md"
        data={selectedProduct}
        title={selectedProductName}
      ></Detail>
      <TemplateTable<IConsumeRecord, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={true}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to={"/login"}></Redirect>
  );
};

export default ConsumeRecord;
