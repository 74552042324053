import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { pageSizeList } from "../../../../utils/helper";
import { fakeStoredValueRecord } from "../../../fake-data/custom-service/stored-value";
import { IAction, TemplateTable } from "../../../layout";
import Edit from "./edit";
import Search from "./search";
import {
  defaultFilter,
  IFilter,
  IStoredValueRecord,
  sortableColumns,
  StoredValueSourceEnum,
} from "./type";
const StoredValueRecord = () => {
  const auth = useContext(IsAuthorization);
  const [originData] = useState<IStoredValueRecord[]>(fakeStoredValueRecord);
  const [downloadData] = useState<any>(fakeStoredValueRecord);
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const [summary] = useState<{ key: string; value: number | string }[]>();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [detail, setDetail] = useState<IStoredValueRecord>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [, setIsDeleteOpen] = useState(false);
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`StoredValueRecord.${name}`);
  };

  const header: IDetailKey<IStoredValueRecord>[] = [
    { key: "id", name: T("ID") },
    { key: "time", name: T("time") },
    { key: "type", name: T("type") },
    { key: "playerID", name: T("playerID") },
    { key: "clubID", name: T("clubID") },
    { key: "spendMoney", name: T("spendMoney") },
    { key: "storedValue", name: T("storedValue") },
    { key: "unit", name: T("unit") },
    { key: "goldBalance", name: T("goldBalance") },
    { key: "diamondBalance", name: T("diamondBalance") },
    { key: "source", name: T("source") },
    { key: "operator", name: T("operator") },
  ];

  const action: IAction<IStoredValueRecord> = {
    create: false,
    del: false,
    edit: true,
    onEdit: (data: IStoredValueRecord) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    onDel: () => {
      setIsDeleteOpen(true);
    },
    conditions: {
      edit: (row: IStoredValueRecord) => {
        return row.source === StoredValueSourceEnum.custom;
      },
    },
  };

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await instance.request<CGReconciliationOptions>({
  //       url: api.CGReconciliationOptions.url(),
  //       method: api.CGReconciliationOptions.method,
  //     });
  //     if (!data) return;

  //     if (data.gameIDs && Object.keys(data.gameIDs).length) {
  //       setGameList(data.gameIDs);
  //     }

  //     if (data.gameInfos && Object.keys(data.gameInfos).length) {
  //       setGameInfos(data.gameInfos);
  //     }
  //   })();
  // }, []);

  return auth.login ? (
    <>
      {/* <Link
        to={{
          pathname: "/",
          search: JSON.stringify({
            test: "test",
          }),
        }}
      >
        test
      </Link> */}
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="xs"
      ></Edit>
      {/* <Create widthSize="md" open={isCreateOpen} setOpen={setIsCreateOpen} /> */}
      <Search
        filter={filter}
        setFilter={setFilter}
        data={downloadData}
        selectionList={{}}
      />
      <TemplateTable<IStoredValueRecord, IFilter>
        action={action}
        data={originData}
        header={header}
        rowsPerPage={pageSizeList}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        summary={summary}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  ) : (
    <Redirect to="/login" />
  );
};
export default StoredValueRecord;
