import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../../../utils/common-type";
import { generateFormItem, getTreeData } from "../../../../../../utils/helper";
import { CustomDialog } from "../../../../../common";
import { IStoredValueRecord } from "./type";

const Edit = (props: IEditProps<IStoredValueRecord>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`StoredValueRecord.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "playerID",
                  label: "playerID",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "spendMoney",
                  label: "spendMoney",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "storedValue",
                  label: "storedValue",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "unit",
                  label: "unit",
                  size: { xs: 12 },
                  treeData: getTreeData(["diamond", "gold"], false),
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default Edit;
