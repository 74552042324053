import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { IOneValues } from "../../utils/common-type";

const OneInput = ({
  onChange,
  onClick,
  disable = false,
  fullWidth = true,
  label,
  multiline = false,
  name,
  placeholder,
  require = false,
  rowsMax = 1,
  size = { xs: 12, sm: 3 },
  type,
  style,
}: IOneValues) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Grid item key={`title${name}`} {...size}>
      <Field
        component={TextField}
        disabled={disable}
        fullWidth={fullWidth}
        label={label ? label : name}
        multiline={multiline}
        name={name}
        required={require}
        rowsMax={rowsMax}
        style={style}
        {...(type === "password" &&
        {
          type: showPassword ? 'text' : 'password',
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }
        }
        )}
        //當型別是數字時
        //限制只能輸入數字且最小值為0
        {...(type === "number" && {
          InputProps: { inputProps: { min: 0 } },
          type: "number",
        })}
        {...(placeholder && { placeholder: placeholder })}
        {...(onChange && {
          onChange,
        })}
        {...(onClick && {
          onClick,
        })}
        {...(type === "content" && {
          multiline: true,
          rowsMax: 4,
          variant: "outlined",
        })}
      ></Field>
    </Grid>
  );
};

export default OneInput;
