import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IDetailKey, IEditProps } from "../../../../../utils/common-type";
import { CustomDialog } from "../../../../common";
import { fakeShopItemRecord } from "../../../../fake-data/report-management/shop-report";
import { TemplateTable } from "../../../../layout";
import { IShopReport } from "../type";
import {
  IShopItemRecord,
  IFilter,
  defaultFilter,
  sortableColumns,
} from "./type";

const Detail = (props: IEditProps<IShopReport>) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [nextPageAvailable] = useState(false);
  const [loading] = useState<boolean>(false);
  const T = (name: string) => {
    return t(`ShopReport.${name}`);
  };
  const header: IDetailKey<IShopItemRecord>[] = [
    { key: "time", name: T("time") },
    { key: "playerID", name: T("playerID") },
    // { key: "nickname", name: T("nickname") },
    { key: "amount", name: T("amount") },
    { key: "price", name: T("price") },
  ];
  return (
    <CustomDialog title={`${props.title}`} {...props}>
      <TemplateTable<IShopItemRecord, any>
        data={fakeShopItemRecord}
        header={header}
        filter={filter}
        action={{}}
        setFilter={setFilter}
        reloading={loading}
        toolbar={false}
        sort={sortableColumns}
        isNextPageHaveData={nextPageAvailable}
        pagination={true}
        // visibleFieldSelect={false}
      />
    </CustomDialog>
  );
};
export default Detail;
