import React from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "../../../../common";
import PlayerInfo from "./player-info";
import { IActionProps } from "./type";

const Action = (props: IActionProps) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`OfflinePlayers.${props.action}`) : "";
  };

  const getAction = (action: string) => {
    switch (action) {
      case "playerInfo":
        return <PlayerInfo />;
      default: {
        return "Action not found.";
      }
    }
  };

  return (
    <CustomDialog
      title={`${T(props.action)} uid: ${props.data?.playerID}`}
      {...props}
    >
      {getAction(props.action)}
    </CustomDialog>
  );
};

export default Action;
