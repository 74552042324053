import { makeStyles } from "@material-ui/core";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import { ICreateProps } from "../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { formInitialValues, IMail, MailAccountType } from "./type";

const Create = (props: ICreateProps<IMail>) => {
  const { t } = useTranslation();
  const [content, setContent] = useState(EditorState.createEmpty());
  const T = (name: string) => {
    return t(`Mail.${name}`);
  };

  const styles = makeStyles(() => ({
    demoEditor: {
      height: "275px !important",
      border: "1px solid #F1F1F1 !important",
      padding: "5px !important",
      borderRadius: "2px !important",
    },
    demoToolbar: {},
    demoWrapper: {},
  }))();

  return (
    <CustomDialog title={t("common.create")} {...props}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          console.log("on submit", values);
          setSubmitting(false);
          props.setOpen(false);
        }}
        onReset={() => {
          setContent(
            EditorState.createEmpty()
          ); /* TODO bundle inside generateFormItem */
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "title",
                  label: "title",
                  size: { xs: 12 },
                },
                {
                  type: "custom",
                  name: "content",
                  label: "content",
                  render: (
                    <div
                      className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"
                      key={"editor"}
                    >
                      <Editor
                        editorState={content}
                        wrapperClassName={styles.demoWrapper}
                        toolbarClassName={styles.demoToolbar}
                        placeholder={T("content")}
                        editorClassName={styles.demoEditor}
                        onEditorStateChange={(x: EditorState) => {
                          setContent(x);
                          form.setFieldValue(
                            "content",
                            draftToHtml(convertToRaw(x.getCurrentContent()))
                          );
                        }}
                      />
                    </div>
                  ),
                },
                {
                  type: "number",
                  name: "gold",
                  label: "gold",
                  size: { xs: 6 },
                },
                {
                  type: "number",
                  name: "diamond",
                  label: "diamond",
                  size: { xs: 6 },
                },
                {
                  type: "text",
                  name: "mailAction",
                  label: "mailAction",
                  size: { xs: 12 },
                },
                {
                  type: "singleTime",
                  name: "startTime",
                  label: "startTime",
                  size: { xs: 12 },
                },
                {
                  type: "singleTime",
                  name: "endTime",
                  label: "endTime",
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "accountType",
                  label: "accountType",
                  treeData: getTreeData(mapEnumToList(MailAccountType), false),
                  size: { xs: 4 },
                },
                {
                  type: "text",
                  name: "playerID",
                  label: "playerID",
                  hidden: form.values.accountType !== MailAccountType.personal,
                  size: { xs: 8 },
                },
              ],
              formInitialValues,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default Create;
