import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../../app";
import { IDetailKey } from "../../../../../../utils/common-type";
import { IAction, TemplateTable } from "../../../../../layout";
import Search from "./search";
import { defaultFilter, IAccess, IFilter, sortableColumns } from "./type";

const ClubAccessRecord = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IAccess[]>([
    {
      ip: "string",
      clubID: "string",
      enterTime: "2021-10-22T00:00:00+08:00",
      exitTime: "2021-10-22T00:00:00+08:00",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`AccessRecord.${name}`);

  const header: IDetailKey<IAccess>[] = [
    { key: "ip", name: T("ip") },
    { key: "clubID", name: T("clubID") },
    { key: "enterTime", name: T("enterTime") },
    { key: "exitTime", name: T("exitTime") },
  ];
  const action: IAction<IAccess> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        downloadData={downloadData}
        filter={filter}
        setFilter={setFilter}
      />
      <TemplateTable<IAccess, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default ClubAccessRecord;
