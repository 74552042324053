import { Grid, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { generateFormItem, getTreeData } from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { IUser } from "./type";
import RefreshIcon from "@material-ui/icons/Refresh";
import ChangePassword from "./change-password";

const Edit = (props: IEditProps<IUser>) => {
  const { t } = useTranslation();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const T = (name: string) => {
    return t(`UserManagement.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={props.data || {}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "username",
                  label: "username",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "nickname",
                  label: "nickname",
                  size: { xs: 12 },
                },
                {
                  type: "password",
                  name: "password",
                  label: "password",
                  size: { xs: 11 },
                },
                {
                  type: "custom",
                  name: "changePassword",
                  label: "changePassword",
                  render: (
                    <Grid
                      container
                      item
                      xs={1}
                      justify="center"
                      key={"changePassword"}
                    >
                      <Tooltip title={T("changePassword")}>
                        <IconButton
                          aria-label="risk alert"
                          color="inherit"
                          onClick={() => {
                            setIsChangePasswordOpen(true);
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ),
                },
                {
                  type: "singleSelect",
                  name: "roleID",
                  label: "roleID",
                  treeData: getTreeData(
                    ["Owner", "PM", "GM", "Club Owner"],
                    false
                  ),
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "status",
                  label: "status",
                  treeData: getTreeData(["activated", "locked"], false),
                  size: { xs: 12 },
                },
              ],
              props.data,
              T
            )}
          </Form>
        )}
      </Formik>
      <ChangePassword
        open={isChangePasswordOpen}
        setOpen={setIsChangePasswordOpen}
      />
    </CustomDialog>
  );
};

export default Edit;
