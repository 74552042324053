import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export enum PointsRecordEnum {
  agent = "代理出入積分",
  game = "遊戲輸贏積分",
}

export interface IPointsRecord {
  playerID: string;
  type: PointsRecordEnum;
  gameID: string;
  agent: string;
  pointsInOut: number;
  remainPoints: number;
  time: string;
}

export interface IFilter extends IBasicFilter {
  playerID: string;
  agent: string;
  type: PointsRecordEnum[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IPointsRecord[];
}

export const sortableColumns = [
  "playerID",
  "type",
  "gameID",
  "agent",
  "pointsInOut",
  "remainPoints",
  "time",
];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    playerID: filter.playerID,
    agent: filter.agent,
    type: filter.type.join(),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  playerID: "",
  agent: "",
  type: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
