import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../../../utils/helper";
import { CustomDialog } from "../../../../../common";
import {
  IWarningMessage,
  WarningMessageStatusEnum,
} from "../../../warning-message/type";

const WarningMessageEdit = (props: IEditProps<IWarningMessage>) => {
  const { t } = useTranslation();
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={
          props.data || {
            id: "FG85416315",
            message: "玩家A於Game贏得了1000000分",
            riskConfig: "開獎金額提醒",
            time: "2020-10-10T08:08:08+08:00",
            status: "未解決",
            mark: "註記",
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "singleSelect",
                  name: "status",
                  label: "WarningMessage.status",
                  treeData: getTreeData(
                    mapEnumToList(WarningMessageStatusEnum),
                    false
                  ),
                  size: { xs: 12 },
                },
              ],
              props.data,
              t
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default WarningMessageEdit;
