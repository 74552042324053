import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export enum ShopItemSourceEnum {
  ios = "IOS",
  android = "Android",
  custom = "客服",
}

export interface IShopReport {
  item: string;
  salesCount: number;
  salesAmount: number;
  totalSalesAmount: number;
  source: ShopItemSourceEnum;
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  source: string[];
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  data: IShopReport[];
}

export const sortableColumns = [
  "item",
  "salesCount",
  "salesAmount",
  "totalSalesAmount",
];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  source: [],
});
