import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateSearchFormItem, getTreeData } from "../../../../utils/helper";
import { SearchAccordion } from "../../../common";
import { defaultFilter, ISearchProps } from "./type";

const Search = ({ filter, setFilter, roles, permissions }: ISearchProps) => {
  const { t } = useTranslation();

  return (
    <SearchAccordion>
      <Formik
        initialValues={filter}
        onSubmit={(values, { setSubmitting }) => {
          setFilter({ ...values, pages: 0 });
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateSearchFormItem(
              form,
              [
                {
                  type: "multiSelect",
                  name: "name",
                  label: "roleManagement.roleName",
                  treeData: getTreeData(roles),
                },
                {
                  type: "multiSelect",
                  name: "permissions",
                  label: "roleManagement.permissions",
                  treeData: [{ title: t("common.all"), value: "all", key: "all", children: permissions }],
                  size: { xs: 5 }
                },
              ],
              defaultFilter(),
              t,
            )}
          </Form>
        )}
      </Formik>
    </SearchAccordion>
  );
};

export default Search;
