import { makeStyles } from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IsAuthorization } from "../../../../../app";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import {
  chartColors,
  unAuthorizationHandle,
} from "../../../../../utils/helper";
import LoadingSpin from "../../../../common/loading-spin";
import Search from "./search";
import { buildFilter, defaultFilter, IFilter, ILevelAnalysis } from "./type";
const useStyles = makeStyles((theme) => ({
  chartDiv: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: 5,
    padding: 20,
    marginTop: 10,
    marginBottom: 20,
    boxShadow: theme.shadows[1],
    position: "relative",
  },
  title: {
    fontSize: 25,
    marginBottom: 10,
  },
}));
const LevelAnalysis = () => {
  const style = useStyles();
  const auth = useContext(IsAuthorization);
  const [levelData, setLevelData] = useState<ILevelAnalysis[]>([]);
  const [storeData, setStoreData] = useState<ILevelAnalysis[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [levelLoading, setLevelLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const downloadData = useMemo(() => levelData, [levelData]);
  const { t } = useTranslation();
  const T = (name: string) => t(`LevelAnalysis.${name}`);
  const initialFlag = useRef(true);

  const chartToolTipFormatter = (value: any, name: string) => [
    `${value}${t(`Unit.${name}`)}`,
    T(name),
  ];
  const chartLegendFormatter = (value: any) => T(value);

  const onLevelSearch = useCallback(() => {
    setLevelLoading(true);
    instance
      .request<ILevelAnalysis[]>({
        method: api.getLevelAnalysis.method,
        url: api.getLevelAnalysis.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        setLevelData(res.data);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLevelLoading(false);
      });
  }, [auth.setLogin, filter]);

  const onStoreSearch = useCallback(() => {
    setStoreLoading(true);
    instance
      .request<ILevelAnalysis[]>({
        method: api.getStoreDetailsAnalysis.method,
        url: api.getStoreDetailsAnalysis.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        setStoreData(res.data);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setStoreLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onLevelSearch();
    onStoreSearch();
  }, [onLevelSearch, onStoreSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <div className={style.chartDiv}>
        <LoadingSpin loading={levelLoading} />
        <div className={style.title}>等級分佈</div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={levelData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="level" />
            <YAxis type="number" />
            <Tooltip formatter={chartToolTipFormatter} />
            <Legend formatter={chartLegendFormatter} />
            <Brush dataKey="level" height={30} stroke={chartColors[0]} />
            <Bar
              name={"playerCount"}
              barSize={20}
              dataKey={"playerCount"}
              fill={chartColors[0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className={style.chartDiv}>
        <LoadingSpin loading={storeLoading} />
        <div className={style.title}>儲值分佈</div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={storeData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="level" />
            <YAxis type="number" />
            <Tooltip formatter={chartToolTipFormatter} />
            <Legend formatter={chartLegendFormatter} />
            <Brush dataKey="level" height={30} stroke={chartColors[1]} />
            <Bar
              name={"playerCount"}
              barSize={20}
              dataKey={"playerCount"}
              fill={chartColors[1]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default LevelAnalysis;
