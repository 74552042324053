import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../../app";
import { IDetailKey } from "../../../../../../utils/common-type";
import { IAction, TemplateTable } from "../../../../../layout";
import Search from "./search";
import {
  AccessPlaceEnum,
  defaultFilter,
  IAccess,
  IFilter,
  sortableColumns,
} from "./type";

const AccessRecord = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IAccess[]>([
    {
      ip: "0.0.0.0",
      place: AccessPlaceEnum.lobby,
      clubID: "",
      action: "進入",
      time: "2021-10-22T00:00:00+08:00",
    },
    {
      ip: "0.0.0.0",
      place: AccessPlaceEnum.club,
      clubID: "Club 1",
      action: "進入",
      time: "2021-10-22T00:00:00+08:00",
    },
  ]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`AccessRecord.${name}`);

  const header: IDetailKey<IAccess>[] = [
    { key: "ip", name: T("ip") },
    { key: "place", name: T("place") },
    { key: "clubID", name: T("clubID") },
    { key: "action", name: T("action") },
    { key: "time", name: T("time") },
  ];
  const action: IAction<IAccess> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        downloadData={downloadData}
        filter={filter}
        setFilter={setFilter}
      />
      <TemplateTable<IAccess, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default AccessRecord;
