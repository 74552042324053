import {
  IMarquee,
  MarqueeModeEnum,
} from "../../pages/platform-management/marquee/type";

export const fakeMarquee: IMarquee[] = [
  {
    content: "string",
    frequency: MarqueeModeEnum.once,
    frequencyDetail: "2021-10-25 23:59:59",
    createTime: "2021-10-25 23:59:59",
  },
];
