import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DetailDialog, FormButton, OneInput } from "../../../common";
interface IChangePasswordProps {
  open: boolean;
  setOpen: any;
}

const ChangePassword = ({ open, setOpen }: IChangePasswordProps) => {
  const [loading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`UserManagement.${name}`);
  };

  return (
    <DetailDialog
      open={open && !loading}
      setOpen={() => {
        setOpen(false);
      }}
      fullWidth={false}
      title={T("changePassword")}
    >
      <Formik
        initialValues={{
          newPassword: "",
          confirmNewPassword: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (values.confirmNewPassword && values.confirmNewPassword !== "") {
            if (values.confirmNewPassword !== values.newPassword)
              errors.confirmNewPassword = "與新密碼不相符";
          }
          return errors;
        }}
        // onSubmit={(values, { setSubmitting }) => {
        onSubmit={() => {
          // instance
          //     .request<any>({
          //         method: api.changePassword.method,
          //         url: api.changePassword.url(),
          //         data: {
          //             ...values,
          //         },
          //     })
          //     .then((res) => {
          //         localStorage.setItem("token", res.data.token);
          //         instance.defaults.headers = {
          //             Authorization: "Bearer " + res.data.token,
          //         };
          //         setOpen(false);
          //     })
          //     .catch((err: any) => {
          //         if (err && err.response && err.response.status === 401) {
          //             setMessage(T("Change password failed"));
          //         } else if (err && err.response && err.response.status === 400) {
          //             setMessage(T("Change password failed"));
          //         }
          //         // throw err;
          //     })
          //     .finally(() => {
          //         setSubmitting(false);
          //     });
        }}
      >
        {({ submitForm, isSubmitting, resetForm, setFieldValue }) => {
          return (
            <Form style={{ width: "50vw", padding: 10 }}>
              <Grid container spacing={3} justify="center">
                {message && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setMessage("");
                    }}
                  >
                    {message}
                  </Alert>
                )}
              </Grid>
              <Grid container spacing={3} justify="center">
                <OneInput
                  type="password"
                  name="newPassword"
                  label={T("newPassword")}
                  size={{ xs: 8 }}
                  onChange={(e) => {
                    setFieldValue("newPassword", e.target.value);
                  }}
                />
                <OneInput
                  type="password"
                  name="confirmNewPassword"
                  label={T("confirmNewPassword")}
                  size={{ xs: 8 }}
                  onChange={(e) => {
                    setFieldValue("confirmNewPassword", e.target.value);
                  }}
                />
              </Grid>
              <Grid container justify="flex-end">
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <FormButton
                    disable={isSubmitting}
                    okEvent={submitForm}
                    cancelEvent={() => {
                      resetForm();
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </DetailDialog>
  );
};

export default ChangePassword;
