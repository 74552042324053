import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { IEditProps } from "../../../../utils/common-type";
import { CustomDialog } from "../../../common";
import { IMail, IReadStatus } from "./type";
import { ColumnsType } from "antd/lib/table";
import { fakeReadStatus } from "../../../fake-data/platform-management/mail";

const Detail = (props: IEditProps<IMail>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`Mail.${name}`);
  };

  const header: ColumnsType<IReadStatus> = [
    {
      dataIndex: "playerID",
      align: "left",
      title: T("playerID"),
    },
    {
      dataIndex: "readStatus",
      align: "left",
      title: T("readStatus"),
    },
  ];

  return (
    <CustomDialog title="讀取狀態列表" {...props}>
      <Table
        style={{ margin: 30, boxShadow: "0px 0px 5px #a6a6a6" }}
        dataSource={fakeReadStatus}
        // loading={loading}
        key="detail-table"
        pagination={false}
        columns={header}
        size="small"
        rowKey={(record) => record.playerID}
      />
    </CustomDialog>
  );
};
export default Detail;
