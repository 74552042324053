import moment from "moment";
import React from "react";
import { IBasicFilter, IGameInfo } from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";
import { ISummary } from "../../../../layout";
import {
  ICompetitiveGameReport,
  ICompetitiveGameReportSummary,
} from "../lobby-competitive-game-report/type";

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  gameIDs: string[];
  clubsID?: string;
  type: number[];
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  searchSummary: Function;
  gameInfos: IGameInfo[];
  data: ICompetitiveGameReport[];
}

export const sortableColumns = [
  "gameID",
  "clubID",
  "totalRoomFee",
  "totalRooms",
  "totalRake",
  "playerCount",
];
`
`;
export const buildFilter = (filter: IFilter) => {
  const params: any = {
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    gameIDs: filter.gameIDs.join(","),
    clubsID: filter.clubsID,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  gameIDs: [],
  clubsID: undefined,
  type: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});

export const initialSummary: ISummary<ICompetitiveGameReportSummary>[] = [
  {
    key: "clubDAU",
    value: "-",
  },
  {
    key: "totalRoomFee",
    value: "-",
  },
  {
    key: "avgRoomFee",
    value: "-",
  },
  {
    key: "totalWinLose",
    value: "-",
  },
  {
    key: "totalRake",
    value: "-",
  },
  {
    key: "avgRake",
    value: "-",
  },
  {
    key: "totalPlayerCount",
    value: "-",
    isInt: true,
  },
  {
    key: "avgPlayerCount",
    value: "-",
    isInt: true,
  },
];
