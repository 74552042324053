import moment from "moment";
import { IBasicFilter } from "../../../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../../../utils/helper";
import { ISummary } from "../../../../../../layout";
import { ICompetitiveGameReportSummary } from "../../../../game-report/lobby-competitive-game-report/type";
export const sortableColumns = ["gameID", "totalBet", "totalWin", "betCount"];

export const initialSummary: ISummary<ICompetitiveGameReportSummary>[] = [
  {
    key: "totalRake",
    value: "-",
  },
  {
    key: "totalWinLose",
    value: "-",
  },
];

export const sortableCompetitiveColumns = [
  "gameID",
  "totalRoomFee",
  "totalRake",
  "totalRooms",
];

export interface IFilter extends IBasicFilter {
  playersID?: number;
  time: moment.Moment[];
  zone: string;
}
export const buildFilter = (filter: IFilter | any) => {
  const params: any = {
    startTime: formatTimeFilter(filter.time[0], filter.zone),
    endTime: formatTimeFilter(filter.time[1], filter.zone),
    playersID: filter.playersID,
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  console.log(params);
  return filterEmptyFiled(params);
};
export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
