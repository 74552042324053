import { Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { TFunction } from "i18next";
import React, { ErrorInfo } from "react";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

interface IState {
  error: Error | null;
  info: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<{ t: TFunction }, IState> {
  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    // console.log("[Error Boundary] promise rejection");
    const { reason } = event;
    Sentry.captureException(new Error(reason));

    const errorCode =
      reason && reason.response && reason.response.status
        ? reason.response.status
        : undefined;
    const errorMessage =
      reason && reason.response && reason.response.data
        ? reason.response.data
        : undefined;
    if (errorCode && errorCode === 401) {
      this.setState({
        error: new Error(
          `${this.props.t("Login.AuthenticationFailed")}
          ${this.props.t("common.ErrorCode")}: [${401}]`
        ),
      });
      return;
    }
    this.setState({
      error: new Error(
        `${this.props.t("Login.DefaultErrorMessage")}
        ${
          errorCode ? `${this.props.t("common.ErrorCode")}: [${errorCode}]` : ""
        }
        ${
          errorMessage
            ? `${this.props.t("common.ErrorMessage")}: ${errorMessage}`
            : ""
        }`
      ),
    });
  };

  constructor(props: any) {
    super(props);
    this.state = { error: null, info: null } as IState;
  }

  static getDerivedStateFromError(error: string) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
    // console.log("[Error Boundary] component did catch");
    Sentry.captureException(error);

    this.setState({
      error,
      info,
    });
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  render() {
    // TODO: 改善 error
    if (this.state.error && 0) {
      setTimeout(() => {
        this.setState({ error: null, info: null });
      }, 2000);
      return (
        <div>
          <Snackbar
            //錯誤提示
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={Boolean(this.state.error)}
            onClose={() => {
              this.setState({ error: null, info: null });
            }}
            autoHideDuration={2000}
          >
            <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
              {this.state.error.message}
            </Alert>
          </Snackbar>
          <Grid
            container
            justify="center"
            style={{ marginTop: "80px", textAlign: "center" }}
          >
            <Grid item xs={6}>
              <h2>{this.props.t("common.Redirect")}</h2>
            </Grid>
          </Grid>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
