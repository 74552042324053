import {
  IWarningMessage,
  WarningMessageStatusEnum,
} from "../../pages/risk-control/warning-message/type";

export const fakeDashboardWarningMessage: IWarningMessage[] = [
  {
    id: "DG568546351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG56851246351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG568523351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG568346351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG568456351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG561423351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
  {
    id: "DG56326351",
    riskConfig: "fake-config",
    message: "fake-message",
    time: "2022-01-04 10:15:10",
    status: WarningMessageStatusEnum.未解決,
    mark: "",
  },
];
