import {
  Accordion, AccordionDetails, AccordionSummary, Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";

const SearchAccordion = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          component="div"
          style={{
            fontSize: 16,
          }}
        >
          {t(`common.Condition Search`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding: 15,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
export default SearchAccordion;
