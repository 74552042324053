import { IUser } from "../../pages/backstage-management/user-management/type";

export const fakeUser: IUser[] = [
  {
    username: "mikey",
    nickname: "Mikey",
    password: "string",
    roleID: "admin",
    permissions: "string",
    clubID: "Club A",
    status: "string",
    createdAt: "2021-10-25 23:59:59",
    updatedAt: "2021-10-25 23:59:59",
  },
];
