import moment from "moment";
import React from "react";
import { TFunction } from "i18next";
import { IRouterSetting } from "../../../../routers";
import { IBasicFilter, ITreeNodes } from "../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../utils/helper";

export const getPageNameWithTreeData = (
  routers: IRouterSetting[],
  t: TFunction
): ITreeNodes[] => {
  return routers.reduce((pre: ITreeNodes[], current: IRouterSetting) => {
    if (current.children) {
      pre = [
        ...pre,
        {
          title: t(`Page.${current.path}`),
          value: current.path,
          key: current.path,
          children: getPageNameWithTreeData(current.children, t),
        },
      ];
    } else {
      pre = [
        ...pre,
        {
          title: t(`Page.${current.path}`),
          value: current.path,
          key: current.path,
        },
      ];
    }
    return pre;
  }, []);
};

export interface IRole {
  id: number;
  name: string;
  permissions: string[];
  createdAt: string;
  updatedAt: string;
}

export interface IRoleForm {
  name: string;
  permissions: string[]; // TODO check schema
}

export interface IFilter extends IBasicFilter {
  time: moment.Moment[];
  zone: string;
  name: string[];
  permissions: string[];
}

export interface ISearchProps {
  filter: IFilter;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  roles: string[];
  permissions: ITreeNodes[];
}

export interface IFormProps {
  tempData: IRoleForm;
  permissions: ITreeNodes[];
  dialogType: string | null;
  setDialogType: React.Dispatch<React.SetStateAction<string | null>>;
  onCreate: (payload: IRoleForm) => void;
  onEdit: (payload: IRoleForm) => void;
}

export const sortableColumns = ["role", "permission"];

export const formInitialValues: IRoleForm = {
  name: "",
  permissions: [], // TODO check schema
};

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    permissions: filter.permissions.join(),
    name: filter.name.join(),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  time: [moment().startOf("days"), moment().endOf("days")],
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
  order: undefined,
  orderBy: undefined,
  zone: getCurrentTimeZone(),
  name: [],
  permissions: [],
});
