import moment from "moment";
import React from "react";
import { IBasicFilter } from "../../../../../utils/common-type";
import {
  formatTimeFilter,
  getCurrentTimeZone,
  pageSizeList,
} from "../../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../../utils/helper";
export interface IFilter extends IBasicFilter {
  transactionID?: string;
  roundID?: string;
  fakeID?: string;
  gameIDs?: string;
  time?: moment.Moment[];
  zone?: string;
  gameDetail?: string;
}

export interface ISearchProps {
  filter: IFilter;
  fixedFilter?: Partial<IFilter>;
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
}

export const detailSortableColumns = ["time"];

export const sortableColumns = ["fakeID", "gameID", "roomID", "time"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    isElectronic: false, // 遊戲類型
    transactionID: filter.transactionID,
    roundID: filter.roundID,
    fakeID: filter.fakeID,
    gameIDs: filter.gameIDs,
    clubsID: 0,
    startTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[0], filter.zone)
        : undefined,
    endTime:
      filter.time && filter.zone
        ? formatTimeFilter(filter.time[1], filter.zone)
        : undefined,
    pageSize: filter.pageSize,
    page: filter.pages,
    order: formatOrder(filter.order, filter.orderBy),
    gameDetailsIDs: filter.gameDetail,
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  transactionID: "",
  fakeID: "",
  gameID: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
