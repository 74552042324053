import {
  Badge,
  Button,
  LinearProgress,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { Button as AntdButton, Col, Descriptions, Popover, Row } from "antd";
import clsx from "clsx";
import { random } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../app";
import { api } from "../../environment";
import { instance } from "../../environment/axios";
import { getHomePath, unAuthorizationHandle } from "../../utils/helper";
import { CustomDialog } from "../common";
import { fakeUser } from "../fake-data/backstage-management/user";
import { IUser } from "../pages/backstage-management/user-management/type";
import ChangePassword from "./change-password";

interface IPropType {
  drawerWidth: number;
  mode: boolean;
  open: boolean;
  singleMode: boolean;
  setMode: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuAppBar = ({ open, setOpen, drawerWidth, singleMode }: IPropType) => {
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation();
  const [fakePlayer, setFakePlayers] = useState(1500);
  const [progress, setProgress] = useState(0);
  const [redirectWarningMessage, setRedirectWarningMessage] = useState(false);
  const [redirectSuggestion, setRedirectSuggestion] = useState(false);
  const [redirectHomePage, setRedirectHomePage] = useState(false);
  const [userInfo] = useState<IUser>(fakeUser[0]);
  const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  const auth = useContext(IsAuthorization);
  const T = (name: string) => {
    return t(`common.${name}`);
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles((theme) => ({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
      }),
    },
    appBarShift: (drawerWidth: number) => ({
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuButtonHide: {
      display: "none",
    },
    appTitle: {
      flexGrow: 1,
      width: "auto",
    },
    navBarInfo: {
      marginRight: 15,
      marginLeft: 15,
    },
  }));
  const classes = useStyles(drawerWidth);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  useEffect(() => {
    if (redirectWarningMessage) {
      setRedirectWarningMessage(false);
    }
  }, [redirectWarningMessage]);

  useEffect(() => {
    if (redirectSuggestion) {
      setRedirectSuggestion(false);
    }
  }, [redirectSuggestion]);

  useEffect(() => {
    if (redirectHomePage) {
      setRedirectHomePage(false);
    }
  }, [redirectHomePage]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          setFakePlayers((oldFakePlayers) => {
            if (oldFakePlayers < 0) {
              return 0;
            }
            const randNum = random(1, 10);
            const randNum2 = random(1, 10);

            return fakePlayer + (randNum2 > 5 ? 1 : -1) * randNum;
          });

          return 0;
        }

        return oldProgress + 1;
      });
    }, 30000);

    return () => {
      clearInterval(timer);
    };
  }, [fakePlayer]);

  return (
    <AppBar
      position="sticky"
      color="primary"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      style={{ overflow: "hidden" }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={open ? classes.menuButtonHide : classes.menuButton}
          color="inherit"
          aria-label="menu"
          // disabled={window.location.href.search('login') !== -1}
          onClick={() => {
            setOpen(true);
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* 在簡易模式下不顯示標題 */}
        {!singleMode && (
          <Typography variant="h6" component="div" className={classes.appTitle}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRedirectHomePage(true);
              }}
            >
              {T("HaoHaoFunManagementSystem")}
            </span>
            {redirectHomePage && (
              <Redirect
                to={getHomePath(auth.routerWithPermission, auth.pagePermission)}
              />
            )}
          </Typography>
        )}

        {
          // TODO: 增加語系後改回來
          /*
        <IconButton
          aria-controls={openLangMenu ? "menu-lang-list-grow" : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={handleLangMenu}
        >
          <LanguageIcon />
        </IconButton>
    
         <Menu
          id="menu-appbar"
          anchorEl={anchorLangEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openLangMenu}
          onClose={handleLangClose}
        >
          <MenuItem
            onClick={() => {
              localStorage.setItem("lang", "en");
              i18n.changeLanguage("en");
              handleLangClose();
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("lang", "zh-cht");
              i18n.changeLanguage("zh-cht");
              handleLangClose();
            }}
          >
            繁體中文
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.setItem("lang", "zh-chs");
              i18n.changeLanguage("zh-chs");
              handleLangClose();
            }}
          >
            简体中文
          </MenuItem>
        </Menu> */
        }
        {/* // ! diabled dark mode temporary
        <IconButton
          onClick={() => {
            // setMode(!Mode);
          }}
          color="inherit"
        >
          {Mode ? <BrightnessLowIcon /> : <Brightness6Icon />}
        </IconButton> */}
        {!singleMode && (
          <div className={classes.navBarInfo}>
            <LinearProgress variant="determinate" value={progress} />
            在線玩家：{fakePlayer}
          </div>
        )}
        {redirectWarningMessage && <Redirect to="/Risk/WarningMessage" />}
        {redirectSuggestion && <Redirect to="/Customer/Case" />}
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            position: isSingleMode ? "absolute" : "inherit",
            right: isSingleMode ? 10 : 0,
          }}
        >
          <Tooltip title="警示訊息">
            <IconButton
              aria-label="risk alert"
              color="inherit"
              onClick={() => {
                setRedirectWarningMessage(true);
              }}
            >
              <Badge color="secondary" badgeContent={2}>
                <ReportProblemIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Popover
            trigger="hover"
            placement="bottom"
            zIndex={1500}
            content={
              <div>
                <Row justify="space-around">
                  <Col>新案件:</Col>
                  <Col>1</Col>
                </Row>
                <Row justify="space-around">
                  <Col>已指派:</Col>
                  <Col>2</Col>
                </Row>
                <Row justify="space-around">
                  <Col>進行中:</Col>
                  <Col>1</Col>
                </Row>
                <Row>
                  <AntdButton
                    size="small"
                    type="link"
                    onClick={() => setRedirectSuggestion(true)}
                  >
                    <u>前往客服案件</u>
                  </AntdButton>
                </Row>
              </div>
            }
          >
            <IconButton color="inherit">
              <Badge color="secondary" badgeContent={4}>
                <MailIcon />
              </Badge>
            </IconButton>
          </Popover>

          <div style={{ marginLeft: 15, wordBreak: "keep-all" }}>
            <Button
              color="primary"
              style={{
                background:
                  "transparent linear-gradient(262deg, #F38270 0%, #D22B30DB 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={handleMenu}
              variant="contained"
            >
              <AccountCircle />
              <div hidden={isSingleMode}>Mikey(客服)</div>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setUserInfoModalVisible(true);
                  handleClose();
                }}
              >
                使用者資訊
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPasswordOpen(true);
                  handleClose();
                }}
              >
                {T("Change Password")}
              </MenuItem>
              <ChangePassword open={passwordOpen} setOpen={setPasswordOpen} />
              <MenuItem
                onClick={() => {
                  instance
                    .request({
                      method: api.logout.method,
                      url: api.logout.url(),
                    })
                    .then((res) => {
                      console.log(res.data);
                    })
                    .catch((err) => {
                      console.log(err);
                      throw err;
                    })
                    .finally(() => {
                      unAuthorizationHandle(auth.setLogin);
                      location.href = process.env.PUBLIC_URL;
                    });
                }}
              >
                {T("Logout")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
      <CustomDialog
        open={isUserInfoModalVisible}
        setOpen={() => {
          setUserInfoModalVisible(false);
        }}
        title={T("userInfo")}
        widthSize="sm"
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="使用者帳號">
            {userInfo?.username}
          </Descriptions.Item>
          <Descriptions.Item label="暱稱">
            {userInfo?.nickname}
          </Descriptions.Item>
          <Descriptions.Item label="角色">{userInfo?.roleID}</Descriptions.Item>
          <Descriptions.Item label="俱樂部ID">
            {userInfo?.clubID.length ? userInfo?.clubID : "-"}
          </Descriptions.Item>
        </Descriptions>
      </CustomDialog>
    </AppBar>
  );
};
export default MenuAppBar;
