import { IFlowAnalysis } from "../../pages/report-management/member-report/flow-analysis/type";

export const fakeFlowAnalysis: IFlowAnalysis[] = [
  {
    members: 0,
    registrations: 0,
    androidCounts: 0,
    iosCounts: 0,
    lobbyDAU: 0,
    lobbyWAU: 0,
    lobbyMAU: 0,
    clubDAU: 0,
    clubWAU: 0,
    clubMAU: 0,
  },
];
