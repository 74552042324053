import {
  createMuiTheme,
  createStyles,
  Paper,
  responsiveFontSizes,
  useMediaQuery,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { ThemeProvider } from "@material-ui/styles";
import { cloneDeep } from "lodash";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./common/i18n/i18n";
import { AlertServiceProvider, ErrorBoundary } from "./component/common";
import {
  AppVersion,
  LeftSideDrawer,
  MenuAppBar,
  PageRouter,
} from "./component/layout";
import LoginPage from "./component/pages/login";
import { IRouterSetting, router } from "./routers";
import { getHomePath } from "./utils/helper";
const CryptoJS = require("crypto-js");

interface IContext {
  login: boolean;
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
  pagePermission: string[];
  setPagePermission: React.Dispatch<React.SetStateAction<string[]>>;
  routerWithPermission: IRouterSetting[];
}
export const IsAuthorization = createContext({
  login: false,
  setLogin: () => {},
  pagePermission: localStorage.getItem("permission")?.split(",") || [],
  setPagePermission: () => {},
  routerWithPermission: [],
} as IContext);

const App = () => {
  const webMatch = useMediaQuery(`(max-width: 900px)`);
  const mobileMatch = useMediaQuery(`(max-device-width: 900px)`);
  const webSingleMode = useMediaQuery(`(max-width: 720px)`);
  const mobileSingleMode = useMediaQuery(`(max-device-width: 720px)`);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);
  const [isSingleMode, setIsSingleMode] = useState<boolean>(
    webSingleMode || mobileSingleMode
  );
  //側邊欄的寬度
  let drawerWidth = isSingleMode ? window.innerWidth : 250;

  const [login, setLogin] = useState<boolean>(
    localStorage.getItem("token") !== null
  );
  const [pagePermission, setPagePermission] = useState<string[]>([]);
  const { i18n } = useTranslation();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      "@global": {
        "*::-webkit-scrollbar": {
          display: isSingleMode && openDrawer ? "none" : "initial",
          width: "0.3rem",
          height: "0.5rem",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#888",
          "-webkit-border-radius": "4px",
        },
      },
      body: {
        boxShadow: theme.shadows[0],
        height: "100%",
        minHeight: "100vh",
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: theme.transitions.create("margin", {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        // margin: -drawerWidth,
      },
      contentShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create("margin", {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeOut,
        }),
        // width: `calc(100% - ${drawerWidth}px)`,
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    if (webMatch || mobileMatch) setOpenDrawer(false);
    else {
      setOpenDrawer(true);
    }
  }, [webMatch, mobileMatch]);

  useEffect(() => {
    if (webSingleMode || mobileSingleMode) setIsSingleMode(true);
    else {
      setIsSingleMode(false);
    }
  }, [webSingleMode, mobileSingleMode]);

  useEffect(() => {
    //    如果有token就設定為登入過
    if (localStorage.getItem("token")) {
      setLogin(true);
    } else {
      setLogin(false);
    }

    // 重新整理要重抓權限資料
    if (localStorage.getItem("permission")) {
      var bytes = CryptoJS.AES.decrypt(
        localStorage.getItem("permission"),
        process.env.REACT_APP_SECRET_KEY || "my-secret-key@123"
      );
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      setPagePermission(decryptedData.split(","));
    }

    // TODO: 有語系要求後在更改回來，目前預設繁中
    i18n.changeLanguage("zh_cht");
  }, [i18n]);
  const theme = createMuiTheme({
    //統一設定字型顏色和文字大小
    palette: {
      action: {
        selected: darkMode ? "#303f9f" : "#97CBFF",
      },
      primary: {
        main: darkMode ? "#303f9f" : "#3c7082",
      },
      type: darkMode ? "dark" : "light",
    },
    typography: {
      body2: {
        fontSize: "0.85rem",
      },
      fontSize: 12,
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: "#FFFFFF",
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          backgroundColor: "#1d1f2e",
        },
      },
      MuiListItemText: {
        root: {
          color: "white",
        },
      },
      MuiListItem: {
        button: {},
      },
      //統一改寫Accordion
      MuiAccordion: {
        root: {
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          // "&$expanded": {
          //   margin: "auto",
          // },
          // border: "1px solid rgba(0, 0, 0, .125)",
          // padding: "12px",
          marginTop: 16,
          marginBottom: 12,
          backgroundColor: "#FFFFFF",
          borderRadius: 5,
        },
        rounded: {
          borderRadius: "auto",
          "&:first-child": {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          // borderBottom: "1px solid rgba(0, 0, 0, .125)",
        },
      },
      MuiAccordionSummary: {
        root: {
          backgroundColor: darkMode ? "#303f9f" : "#3c7082",
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
          color: "white",
          marginBottom: -1,
          margin: 0,
          minHeight: 0,
          "&$expanded": {
            minHeight: 0,
            margin: 0,
            transitionProperty: "border-radius",
            transitionDuration: "0.2s",
            borderRadius: "5px 5px 0px 0px",
          },
          borderRadius: 5,
          transitionProperty: "border-radius",
          transitionDuration: "0.7s",
        },
        content: {
          "&$expanded": {
            margin: 0,
          },
          margin: 0,
        },
        expandIcon: {
          color: "white",
          padding: 6,
        },
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: darkMode ? "#303f9f" : "#3c7082",
          color: "white",
        },
      },
      MuiTableSortLabel: {
        icon: {
          color: "white !important",
        },
        root: {
          color: "white !important",
        },
      },
      MuiTableCell: {
        stickyHeader: {
          backgroundColor: darkMode ? "#303f9f" : "#3c7082",
        },
      },
    },
  });

  // 更改主題陰影
  theme.shadows[1] = "rgba(0, 0, 0, 0.24) 0px 3px 8px";

  // 拿到permission後篩選掉不可看到的page
  const filterRouterWithPermission = useCallback(
    (router: IRouterSetting[]): IRouterSetting[] => {
      return router
        .map((nodes) => {
          // 如果他是父節點且權限有他的子字串
          if (
            nodes.children &&
            pagePermission.find((p) => p.indexOf(nodes.path) !== -1)
          ) {
            const children = filterRouterWithPermission(nodes.children);
            if (children.length === 0) {
              return { ...nodes, name: "deleted" };
            }
            return { ...nodes, children };
          } else if (pagePermission.find((p) => p === nodes.path)) {
            return nodes;
          } else {
            return { ...nodes, name: "deleted" };
          }
        })
        .filter((n) => n.name !== "deleted");
    },
    [pagePermission]
  );

  const routerWithPermission = useMemo(() => {
    return filterRouterWithPermission(cloneDeep(router));
  }, [filterRouterWithPermission]);

  const app = (
    <Switch>
      <IsAuthorization.Provider
        value={{
          login,
          setLogin,
          pagePermission,
          setPagePermission,
          routerWithPermission,
        }}
      >
        <AlertServiceProvider>
          <ErrorBoundary>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
              <AppVersion isVisible={!login} />
              <Paper className={classes.body} square>
                {login && (
                  //只有登入過且有成功取得token
                  //才會顯示側邊欄和bar
                  <>
                    <MenuAppBar
                      drawerWidth={drawerWidth}
                      mode={darkMode}
                      open={openDrawer}
                      singleMode={isSingleMode}
                      setMode={setDarkMode}
                      setOpen={setOpenDrawer}
                    />
                    <LeftSideDrawer
                      open={openDrawer}
                      singleMode={isSingleMode}
                      drawerWidth={drawerWidth}
                      setOpen={setOpenDrawer}
                    />
                  </>
                )}
                <Route
                  path="/login"
                  render={(history: any) => {
                    return <LoginPage login={setLogin} history={history} />;
                  }}
                />
                {/* {localStorage.getItem('token') && <Route path='*' component={() => <Redirect to='/login' />} />} */}
                <div
                  className={
                    isSingleMode
                      ? classes.content
                      : clsx(classes.content, {
                          [classes.contentShift]: openDrawer,
                        })
                  }
                >
                  <Route
                    exact
                    path="/"
                    // component={() => <></>}
                    component={() => {
                      return localStorage.getItem("token") ? (
                        <Redirect
                          to={getHomePath(routerWithPermission, pagePermission)}
                        />
                      ) : (
                        <Redirect to="/login" />
                      );
                    }}
                  />
                  {<PageRouter />}
                </div>
                {/* <Redirect to={login ? "/Statistic/Turnover" : "/login"} /> */}
                {/* <Redirect to={"/login"} /> */}
                {/* <Route path='*' render={NotFound} /> */}
              </Paper>
            </ThemeProvider>
          </ErrorBoundary>
        </AlertServiceProvider>
      </IsAuthorization.Provider>
    </Switch>
  );

  return process.env.REACT_APP_USE_HASH_ROUTER === "true" ? (
    <HashRouter>{app}</HashRouter>
  ) : (
    <BrowserRouter basename={process.env.PUBLIC_URL}>{app}</BrowserRouter>
  );
};
export default App;
