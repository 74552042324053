import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { fakePointsRecord } from "../../../fake-data/club-service/points-record";
import { IAction, TemplateTable } from "../../../layout";
import Search from "./search";
import { defaultFilter, IFilter, IPointsRecord, sortableColumns } from "./type";

const PointsRecord = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IPointsRecord[]>(fakePointsRecord);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`PointsRecord.${name}`);

  const header: IDetailKey<IPointsRecord>[] = [
    { key: "playerID", name: T("playerID") },
    { key: "type", name: T("type") },
    { key: "gameID", name: T("gameID") },
    { key: "agent", name: T("agent") },
    {
      key: "pointsInOut",
      name: T("pointsInOut"),
      custom: (data) => (
        <span style={{ color: data >= 0 ? "green" : "red" }}>{data}</span>
      ),
    },

    { key: "remainPoints", name: T("remainPoints") },
    { key: "time", name: T("time") },
  ];
  const action: IAction<IPointsRecord> = {
    create: false,
    del: false,
    edit: false,
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search data={downloadData} filter={filter} setFilter={setFilter} />
      <TemplateTable<IPointsRecord, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default PointsRecord;
