import moment from "moment";
import { IBasicFilter } from "../../../../utils/common-type";
import { getCurrentTimeZone, pageSizeList } from "../../../../utils/helper";
import { filterEmptyFiled, formatOrder } from "../../../../utils/helper";

export enum StoredValueTypeEnum {
  player = "玩家儲值",
  club = "俱樂部儲值",
}

export enum StoredValueUnitEnum {
  gold = "金幣",
  diamond = "鑽石",
  point = "積分",
}

export enum StoredValueSourceEnum {
  ios = "IOS",
  android = "Android",
  custom = "客服",
}

export interface IStoredValueRecord {
  id: string;
  time: string;
  type: StoredValueTypeEnum;
  playerID: string;
  clubID: string;
  spendMoney: number;
  storedValue: number;
  unit: StoredValueUnitEnum;
  goldBalance: number;
  diamondBalance: number;
  source: StoredValueSourceEnum;
  operator: string;
}
export const formInitialValues: IStoredValueRecord = {
  id: "string",
  time: "string",
  type: StoredValueTypeEnum.club,
  playerID: "string",
  clubID: "string",
  spendMoney: 0,
  storedValue: 0,
  unit: StoredValueUnitEnum.gold,
  goldBalance: 0,
  diamondBalance: 0,
  source: StoredValueSourceEnum.custom,
  operator: "string",
};

export const sortableColumns = [
  "id",
  "time",
  "spendMoney",
  "storedValue",
  "unit",
  "source",
  "operator",
];

export interface IFilter extends IBasicFilter {
  playerID: string;
  id: string;
  type: StoredValueTypeEnum[];
  unit: StoredValueUnitEnum[];
  source: StoredValueSourceEnum[];
  time: moment.Moment[];
  zone: string;
}

export interface ISearchProps {
  setFilter: React.Dispatch<React.SetStateAction<IFilter>>;
  filter: IFilter;
  data: IStoredValueRecord[];
  selectionList: Record<string, string[]>;
}

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    playerID: filter.playerID,
    unit: filter.unit.join(),
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  playerID: "",
  id: "",
  type: [],
  unit: [],
  source: [],
  time: [moment().startOf("days"), moment().endOf("days")],
  zone: getCurrentTimeZone(),
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
