import { IBasicFilter } from "../../../../../utils/common-type";
import {
  filterEmptyFiled,
  formatOrder,
  pageSizeList,
} from "../../../../../utils/helper";

export interface IShopItemRecord {
  playerID: string;
  nickname: string;
  amount: string;
  price: number;
  time: string;
}

export interface IFilter extends IBasicFilter {}

export const sortableColumns = ["time"];

export const buildFilter = (filter: IFilter) => {
  const params: any = {
    page: filter.pages,
    pageSize: filter.pageSize,
    order: formatOrder(filter.order, filter.orderBy),
  };
  return filterEmptyFiled(params);
};

export const defaultFilter: () => IFilter = () => ({
  pages: 0,
  pageSize: Number(localStorage.getItem("pageSize")) || pageSizeList[0],
});
