import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IDetailKey,
  IEditProps,
  IPagedResponse,
} from "../../../../../../utils/common-type";
import { api } from "../../../../../../environment";
import { ITransaction } from "../../lobby-electronic-game-record/type";
import { instance } from "../../../../../../environment/axios";
import { CustomDialog } from "../../../../../common";
import { Grid } from "@material-ui/core";
import { TemplateTable } from "../../../../../layout";
import {
  dataTypeCheck,
  unAuthorizationHandle,
} from "../../../../../../utils/helper";
import { buildFilter, defaultFilter, IFilter, sortableColumns } from "./type";
import moment from "moment";
import { IsAuthorization } from "../../../../../../app";
import { ITransactionRound } from "../../lobby-competitive-game-record/type";

const Detail = (props: IEditProps<ITransactionRound>) => {
  const auth = useContext(IsAuthorization);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [data, setData] = useState<ITransaction[]>([]);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const initialFlag = useRef(true);
  const T = (name: string) => {
    return t(`LobbyCompetitiveGameRecord.${name}`);
  };

  const header: IDetailKey<ITransaction>[] = [
    { key: "createdAt", name: T("time") },
    { key: "roundID", name: T("roundID"), isInt: true },
    { key: "fakeID", name: T("fakeID"), isInt: true },
    { key: "winLose", name: T("winLose") },
  ];

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ITransaction[]>>({
        method: api.getTransaction.method,
        url: api.getTransaction.url(),
        params: {
          ...buildFilter(filter),
          gameDetailsIDs: props.data?.gameDetail.list.join(","),
        },
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => {
            return {
              ...d,
              bet: d.bet / 10000,
              win: d.win / 10000,
              gameBet: d.bet / 10000,
              gameWin: d.win / 10000,
              rake: (d.gameWin - d.win) / 10000,
              revenue: (d.win - d.bet) / 10000,
              winLose: (d.gameWin - d.bet) / 10000,
              time: moment(d.createdAt).format(),
            };
          })
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter, props.data]);

  useEffect(() => {
    if (initialFlag.current && !props.open) {
      initialFlag.current = false;
      return;
    }

    if (props.data) {
      onSearch();
    }
  }, [onSearch, props.open, props.data]);

  return (
    <CustomDialog
      title={`${props.title}`}
      {...props}
      onClose={() => {
        props.setOpen(false);
        initialFlag.current = true;
        setFilter({ ...filter, pages: 0 });
      }}
    >
      <Fragment>
        <Grid item container justify="space-around" alignItems="center" xs={12}>
          <Grid item style={{ color: "#3f51b5" }}>
            {T("gameID")}：{props.data?.gameID}
          </Grid>
          <Grid item style={{ color: "#3f51b5" }}>
            {T("roomID")}：{props.data?.roomID}
          </Grid>
          <Grid item style={{ color: "#3f51b5" }}>
            {T("totalWinLoss")}：
            {dataTypeCheck(
              data
                ?.map((data) => data.win - data.bet)
                .reduce(
                  (previousWin, currentWin) => previousWin + currentWin,
                  0
                )
            )}
          </Grid>
        </Grid>
        <TemplateTable<ITransaction, IFilter>
          data={data}
          header={header}
          filter={filter}
          action={{}}
          setFilter={setFilter}
          reloading={loading}
          toolbar={false}
          sort={sortableColumns}
          isNextPageHaveData={nextPageAvailable}
          topPagination={false}
          visibleFieldSelect={false}
        />
      </Fragment>
    </CustomDialog>
  );
};
export default Detail;
