import { Method } from "axios";
import { FormikHelpers } from "formik";
import moment from "moment";
import React, { ReactNode } from "react";

export type IGameList = { [key: string]: string[] };
export type IChannelList = { [key: string]: string };
export type IGameName = { [game: string]: { [language: string]: string } };
export interface IGameInfo {
  name: { [language: string]: string };
  type: string;
  gameID: string;
}
export interface IGameProps {
  gameInfos: IGameInfo;
}

export interface ISearchOptions {
  gameInfos: IGameInfo[];
}

export type IBaseFilterProps = {
  pages: number;
  pageSize: number;
  order?: string;
  orderBy?: string;
};
export interface IPagedResponse<T> {
  data: T;
  nextPage: boolean;
}

interface IBasic {
  onChange?: ((e: any) => void) | ((e: any) => void);
  onClick?: (e: any) => void;
  disable?: boolean;
  require?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  size?: ISize;
}
export interface IBasicFilter {
  pages: number;
  pageSize: number;
  order?: string;
  orderBy?: string;
}

type IGridSize =
  | boolean
  | "auto"
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | undefined;
export interface ISelect {
  value: number | string;
  name?: number | string;
  type?: string;
}
interface ISize {
  xs?: IGridSize;
  sm?: IGridSize;
  md?: IGridSize;
  lg?: IGridSize;
  xl?: IGridSize;
}
export interface IDetailDialogProps {
  children: React.ReactNode;
  customTitle?: JSX.Element;
  disableTypography?: boolean;
  open: boolean;
  setOpen: () => void;
  titleStyle?: React.CSSProperties;
  title?: string;
  fullWidth?: boolean;
  widthSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
}
export interface IEditProps<T> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  widthSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  data?: T;
  title?: string;
}

export interface ICreateProps<T> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  widthSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  title?: string;
}
export interface IDeleteData<T> extends ICreateProps<T> {
  deleteApi: {
    url: () => string;
    method: Method;
  };
  data: T[];
}

export interface IConfirmCard<T> extends ICreateProps<T> {
  api?: {
    url: () => string;
    method: Method;
  };
  content?: string;
  data?: T;
}

export interface IDetailEdit<T> extends ICreateProps<T> {
  data: T;
  title?: string;
}
export interface IDetailKey<T> {
  custom?: (data: any, source: T) => any;
  dataAlign?: "center" | "inherit" | "justify" | "left" | "right";
  headAlign?: "center" | "inherit" | "justify" | "left" | "right";
  key: keyof T | "expanded";
  name: string;
  size?: ISize;
  isInt?: boolean;
}
export interface IOneValues extends IBasic {
  error?: boolean;
  helperText?: string;
  label?: string;
  multiline?: boolean;
  name: string;
  rowsMax?: number;
  type?: IFormItem["type"];
  valueNameLookUp?: { [value: string]: string };
  style?: React.CSSProperties;

  // multiple select must have these two props
  setFieldValue?: FormikHelpers<any>["setFieldValue"];
  selectedValue?: string[];
  gameList?: IGameList;
}

export interface ITreeSelectProps extends IBasic {
  value?: string[] | string;
  treeData?: ITreeNodes[];
  multiple?: boolean;
  label?: string;
  name: string;
}

export interface ITreeNodes {
  children?: ITreeNodes[];
  value: string | number;
  title: ReactNode;
  key: string | number;
}

export interface IToggleButtonValues {
  exclusive: boolean;
  onChange?: (e: any, v: string) => void;
  ariaLabel: string;
  size?: ISize;
  buttons: string[];
}

export interface ITwoValues extends IBasic {
  label?: string[];
  name: string[];
  type?: "number" | "time";
  format?: string;
}

export interface ITimePicker extends IBasic {
  label?: string;
  name: string;
  type?: "number" | "time";
  format?: string;
  value: (moment.Moment | number)[] | moment.Moment | number;
  range?: boolean;
  withZone?: boolean;
}

export interface IFormItem {
  type?:
    | "text"
    | "content"
    | "singleSelect"
    | "multiSelect"
    | "time"
    | "singleTime"
    | "number"
    | "password"
    | "custom";
  name: string;
  label: string;
  placeholder?: string;
  size?: ISize;
  onChange?: ((e: any) => void) | ((e: any) => void);
  onClick?: (e: any) => void;
  disable?: boolean;
  hidden?: boolean;
  require?: boolean;
  format?: string;
  style?: React.CSSProperties;
  quickTimeSelection?: boolean;
  // tree select props
  treeData?: ITreeNodes[];

  // custom render component
  render?: JSX.Element;
}

export enum RequestErrorCodeEnum {
  PARSE_OR_UNMARSHAL_JSON_FAILED = 4000,
  PARAM_OR_BODY_INVALIDATE = 4001,
  UNAUTHORIZED_OR_LOGIN_FAILED = 4004,
  RECORD_NOT_FOUND = 4007,
  INTERNAL_SERVER_ERROR = 5002,
}

export enum ErrorCodeEnum {
  UNEXPECTED_ERROR = 600,
  PROPERTY_UNDEFINED = 601,
}

export interface IPopup {
  popup: boolean;
}
