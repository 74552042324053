import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../app";
import { IDetailKey } from "../../../../utils/common-type";
import { ConfirmCard } from "../../../common";
import { fakeMemberList } from "../../../fake-data/club-service/member.-list";
import { IAction, TemplateTable } from "../../../layout";
import Action from "./actions";
import Edit from "./edit";
import Search from "./search";
import {
  defaultFilter,
  getMemberStatusColor,
  IFilter,
  IMember,
  MemberStatusEnum,
  sortableColumns,
} from "./type";

const MemberList = () => {
  const auth = useContext(IsAuthorization);
  const [data] = useState<IMember[]>(fakeMemberList);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading] = useState<boolean>(false);
  const [actionName, setActionName] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [detail, setDetail] = useState<IMember>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [nextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const { t } = useTranslation();
  const T = (name: string) => t(`MemberList.${name}`);

  const header: IDetailKey<IMember>[] = [
    { key: "playerID", name: T("playerID") },
    { key: "nickname", name: T("nickname") },
    { key: "clubID", name: T("clubID") },
    { key: "agent", name: T("agent") },
    { key: "point", name: T("point") },
    { key: "credit", name: T("credit") },
    { key: "joinTime", name: T("joinTime") },
    { key: "lastLoginTime", name: T("lastLoginTime") },
    {
      key: "status",
      name: T("status"),
      custom: (status: MemberStatusEnum) => (
        <span
          style={{
            textTransform: "capitalize",
            color: getMemberStatusColor(status),
          }}
        >
          {status}
        </span>
      ),
    },
  ];
  const action: IAction<IMember> = {
    create: false,
    del: false,
    edit: true,
    onEdit: (data: IMember) => {
      setIsEditOpen(true);
      setDetail(data);
    },
    custom: [
      {
        name: "成員積分",
        actionIcon: <MonetizationOnIcon />,
        onEvent: (member: IMember) => {
          setDetail(member);
          setIsActionOpen(true);
          setActionName("PointsDetail");
        },
        position: "IN_ROW",
      },
      {
        name: "電子遊戲紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (member: IMember) => {
          setDetail(member);
          setIsActionOpen(true);
          setActionName("ClubElectronicGameRecord");
        },
        position: "IN_ROW",
      },
      {
        name: "競技遊戲紀錄",
        actionIcon: <SettingsBackupRestoreIcon />,
        onEvent: (member: IMember) => {
          setDetail(member);
          setIsActionOpen(true);
          setActionName("ClubCompetitiveGameRecord");
        },
        position: "IN_ROW",
      },
      {
        actionIcon: <ExitToAppIcon />,
        name: "進出紀錄",
        onEvent: (data: IMember) => {
          setIsActionOpen(true);
          setActionName("AccessRecord");
          setDetail(data);
        },
        position: "IN_ROW",
      },
      {
        actionIcon: <HighlightOffIcon />,
        name: "移除俱樂部",
        onEvent: (data: IMember) => {
          setIsRemoveOpen(true);
          setDetail(data);
        },
        position: "IN_ROW",
      },
    ],
  };

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        downloadData={downloadData}
        filter={filter}
        setFilter={setFilter}
        selectionList={{ club: ["ClubA", "ClubB", "ClubC"] }}
      />
      <Edit
        open={isEditOpen}
        setOpen={setIsEditOpen}
        data={detail}
        widthSize="xs"
      ></Edit>
      <ConfirmCard
        open={isRemoveOpen}
        setOpen={setIsRemoveOpen}
        data={detail}
        content="即將將此玩家踢除俱樂部"
      />
      <Action
        open={isActionOpen}
        setOpen={setIsActionOpen}
        data={detail}
        widthSize="xl"
        action={actionName}
      />
      <TemplateTable<IMember, IFilter>
        action={action}
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default MemberList;
