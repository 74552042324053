import { Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsAuthorization } from "../../../../../../../app";
import { api } from "../../../../../../../environment";
import { instance } from "../../../../../../../environment/axios";
import {
  IDetailKey,
  IPagedResponse,
} from "../../../../../../../utils/common-type";
import {
  dataTypeCheck,
  unAuthorizationHandle,
} from "../../../../../../../utils/helper";
import { TemplateTable } from "../../../../../../layout";
import {
  ICompetitiveGameReport,
  ICompetitiveGameReportSummary,
} from "../../../../game-report/lobby-competitive-game-report/type";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  initialSummary,
  sortableColumns,
} from "./type";

interface Props {
  fakeID?: number;
  time: moment.Moment[];
  zone: string;
}

const CompetitiveReport = ({ fakeID, time, zone }: Props) => {
  const auth = useContext(IsAuthorization);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [data, setData] = useState<ICompetitiveGameReport[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);
  const [summary, setSummary] = useState(initialSummary);
  const { t } = useTranslation();
  const T = (name: string) => {
    return name ? t(`GameReport.${name}`) : "";
  };

  const competitiveHeader: IDetailKey<
    ICompetitiveGameReport & { totalRevenue: number }
  >[] = [
    {
      key: "gameID",
      name: T("gameID"),
    },
    {
      key: "totalRoomFee",
      name: T("totalRoomFee"),
    },
    {
      key: "totalWinLose",
      name: T("totalWinLose"),
    },
    {
      key: "totalRake",
      name: T("totalRake"),
    },
    {
      key: "totalRevenue",
      name: T("totalRevenue"),
      custom: (_, record) =>
        `${dataTypeCheck(record.totalWinLose - record.totalRake)}`,
    },
    {
      key: "totalRooms",
      name: T("totalRooms"),
      isInt: true,
    },
  ];

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ICompetitiveGameReport[]>>({
        method: api.getCompetitiveGameReport.method,
        url: api.getCompetitiveGameReport.url(),
        params: buildFilter({ ...filter, fakeID, time, zone }),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => ({
            ...d,
            totalWinLose: d.totalWinLose / 10000,
            totalRake: d.totalRake / 10000,
          }))
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter, fakeID, time, zone]);

  const searchSummary = useCallback(() => {
    setSummary(initialSummary);
    instance
      .request<ICompetitiveGameReportSummary>({
        method: api.getCompetitiveGameReportSummary.method,
        url: api.getCompetitiveGameReportSummary.url(),
        params: buildFilter({ fakeID, time, zone }),
      })
      .then((res) => {
        const data: ICompetitiveGameReportSummary = {
          ...res.data,
          totalRake: res.data.totalRake / 10000,
          totalWinLose: res.data.totalWinLose / 10000,
        };
        setSummary((s) => s.map((s) => ({ ...s, value: data[s.key] })));
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {});
  }, [fakeID, time, zone, auth]);

  /* 只需搜尋第一次 */
  useEffect(() => {
    searchSummary();
  }, [searchSummary]);

  /* 換頁查詢 */
  useEffect(() => {
    onSearch();
  }, [onSearch]);

  return (
    <>
      {summary && (
        <Grid item container justify="space-around" alignItems="center" xs={12}>
          {summary.map((s) => {
            return (
              <Grid item style={{ color: "#3f51b5" }} key={Math.random()}>
                {`${T(s.key)}: ${s.isInt ? s.value : dataTypeCheck(s.value)}`}
              </Grid>
            );
          })}
        </Grid>
      )}
      <TemplateTable<ICompetitiveGameReport, any>
        data={data}
        header={competitiveHeader}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        topPagination={false}
        toolbar={false}
        visibleFieldSelect={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};
export default CompetitiveReport;
