import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICreateProps } from "../../../../utils/common-type";
import {
  generateFormItem,
  getTreeData,
  mapEnumToList,
} from "../../../../utils/helper";
import { CustomDialog } from "../../../common";
import { formInitialValues, GameStatusEnum, IGame } from "./type";

const Create = (props: ICreateProps<IGame>) => {
  const { t } = useTranslation();
  const T = (name: string) => {
    return t(`GameManagement.${name}`);
  };
  return (
    <CustomDialog title={t("common.edit")} {...props}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {(form) => (
          <Form style={{ width: "100%" }}>
            {generateFormItem(
              form,
              [
                {
                  type: "text",
                  name: "gameID",
                  label: "gameID",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "gameName",
                  label: "gameName",
                  size: { xs: 12 },
                },
                {
                  type: "content",
                  name: "description",
                  label: "description",
                  size: { xs: 12 },
                },
                {
                  type: "number",
                  name: "imageURL",
                  label: "imageURL",
                  size: { xs: 12 },
                },
                {
                  type: "text",
                  name: "url",
                  label: "url",
                  size: { xs: 12 },
                },
                // TODO: 遊戲參數待討論
                {
                  type: "number",
                  name: "gameParameter",
                  label: "gameParameter",
                  disable: true,
                  size: { xs: 12 },
                },
                {
                  type: "singleSelect",
                  name: "lobbyStatus",
                  label: "lobbyStatus",
                  size: { xs: 12 },
                  treeData: getTreeData(mapEnumToList(GameStatusEnum), false),
                },
                {
                  type: "singleSelect",
                  name: "clubStatus",
                  label: "clubStatus",
                  size: { xs: 12 },
                  treeData: getTreeData(mapEnumToList(GameStatusEnum), false),
                },
              ],
              formInitialValues,
              T
            )}
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};

export default Create;
