import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { IsAuthorization } from "../../../../../app";
import i18n from "../../../../../common/i18n/i18n";
import { api } from "../../../../../environment";
import { instance } from "../../../../../environment/axios";
import {
  IDetailKey,
  IGameInfo,
  IPagedResponse,
  ISearchOptions,
} from "../../../../../utils/common-type";
import {
  mapGameLang,
  unAuthorizationHandle,
} from "../../../../../utils/helper";
import { TemplateTable } from "../../../../layout";
import Search from "./search";
import {
  buildFilter,
  defaultFilter,
  IFilter,
  ITransaction,
  sortableColumns,
} from "./type";

interface Props {
  // 其他頁面會用到這個功能會需傳固定的參數，像是 playerID
  fixedFilter?: Partial<IFilter>;
}

interface ITransactionHeader extends ITransaction {
  revenue: number;
}

const LobbyElectronicGameRecord = ({ fixedFilter }: Props) => {
  const auth = useContext(IsAuthorization);
  const [data, setData] = useState<ITransaction[]>([]);
  const [gameInfos, setGameInfos] = useState<IGameInfo[]>([]);
  const [filter, setFilter] = useState<IFilter>(defaultFilter());
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const downloadData = useMemo(() => data, [data]);
  const initialFlag = useRef(true);
  const { t } = useTranslation();
  const T = (name: string) => t(`LobbyElectronicGameRecord.${name}`);

  const header: IDetailKey<ITransactionHeader>[] = [
    { key: "createdAt", name: T("time") },
    { key: "transactionID", name: T("transactionID") },
    { key: "roundID", name: T("roundID"), isInt: true },
    {
      key: "gameID",
      name: T("gameID"),
      custom: (gameID) => mapGameLang(gameID, gameInfos, i18n.language),
    },
    { key: "fakeID", name: T("fakeID"), isInt: true },
    { key: "bet", name: T("bet") },
    { key: "win", name: T("win") },
    { key: "revenue", name: T("revenue") },
  ];

  useEffect(() => {
    instance
      .request<ISearchOptions>({
        method: api.getOptions.method,
        url: api.getOptions.url(),
      })
      .then((res) => {
        setGameInfos(res.data.gameInfos || []);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin]);

  const onSearch = useCallback(() => {
    setLoading(true);
    instance
      .request<IPagedResponse<ITransaction[]>>({
        method: api.getTransaction.method,
        url: api.getTransaction.url(),
        params: buildFilter(filter),
      })
      .then((res) => {
        const { data, nextPage } = res.data;
        setData(
          data.map((d) => {
            return {
              ...d,
              bet: d.bet / 10000,
              win: d.win / 10000,
              gameBet: d.bet / 10000,
              gameWin: d.win / 10000,
              rake: (d.gameWin - d.win) / 10000,
              revenue: (d.win - d.bet) / 10000,
              winLose: (d.gameWin - d.bet) / 10000,
              time: moment(d.createdAt).format(),
            };
          })
        );
        setNextPageAvailable(nextPage);
      })
      .catch((err: any) => {
        if (err && err.response && err.response.status === 401) {
          unAuthorizationHandle(auth.setLogin);
        }
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth.setLogin, filter]);

  useEffect(() => {
    if (initialFlag.current) {
      initialFlag.current = false;
      return;
    }

    onSearch();
  }, [onSearch]);

  return !auth.login ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Search
        data={downloadData}
        filter={filter}
        fixedFilter={fixedFilter}
        setFilter={setFilter}
        gameInfos={gameInfos}
      />
      <TemplateTable<ITransaction, IFilter>
        data={data}
        header={header}
        filter={filter}
        setFilter={setFilter}
        reloading={loading}
        sort={sortableColumns}
        toolbar={false}
        isNextPageHaveData={nextPageAvailable}
      />
    </>
  );
};

export default LobbyElectronicGameRecord;
