import { IconButton, Tooltip as IconToolTip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Card, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RankDetail from "./rank-detail";

const defaultData = (): ITabData => ({
  tabName: "",
  title: "",
  subTitle: "",
  tableData: [],
  tableHeader: [],
});

interface ITabData {
  tabName?: string;
  title: string;
  subTitle: string;
  tableData: any[];
  tableHeader: TableColumnsType<any>;
}

interface IProps {
  tabDatas: ITabData[];
  loading: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const Rank: React.FC<IProps> = ({ tabDatas, loading = false }) => {
  const [gameRankTab, setGameRankTab] = useState<string>();
  const [data, setData] = useState<ITabData>(defaultData());
  const { t } = useTranslation();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const T = (name: string) => {
    return t(`BusinessReport.${name}`);
  };
  useEffect(() => {
    setGameRankTab(tabDatas[0].tabName);
    setData(tabDatas[0]);
  }, [tabDatas]);

  return (
    <Card loading={loading}>
      {tabDatas.length > 1 && (
        <ToggleButtonGroup
          value={gameRankTab}
          exclusive
          onChange={(props, value) => {
            if (!value) return;
            setGameRankTab(value);
            const indexOfData = tabDatas
              .slice()
              .findIndex((data) => data.tabName === value);
            const tabData = tabDatas[indexOfData];
            setData(tabData);
          }}
        >
          {tabDatas.map((data, index) => (
            <ToggleButton key={`${data.tabName}${index}`} value={data.tabName}>
              {data.tabName}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      <IconToolTip title={T("moreDetail")}>
        <IconButton
          style={{ position: "absolute", right: 10, top: 10 }}
          onClick={() => {
            setIsDetailOpen(true);
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </IconToolTip>
      <div
        style={{
          fontSize: 16,
          marginBottom: 15,
          marginTop: 15,
          fontWeight: "bold",
        }}
      >
        {data.title}
      </div>
      <div style={{ fontSize: 14, marginBottom: 15 }}>{data.subTitle}</div>
      <Table
        dataSource={[...data.tableData].slice(0, 3)} //TODO: 等API好了要改成真的資料
        loading={false}
        key="rank-table"
        pagination={false}
        columns={data.tableHeader}
        rowKey={(record) => record.playerID}
      />
      <RankDetail
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        data={data.tableData}
        tableHeader={data.tableHeader}
        loading={loading}
        title={T("fullRank")}
        widthSize="sm"
      />
    </Card>
  );
};
export default Rank;
